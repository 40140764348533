import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../checkErrorsFunction";
export const activeCourseFunc = createAsyncThunk(
    "active course function",
    async ({api , data}) => {
        try {
            const response = await axios.post(api , data , {withCredentials : true});
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            return error;
        }
        
    }
)

const activeCourseSlicer = createSlice({
    name : "active course slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(activeCourseFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(activeCourseFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(activeCourseFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default activeCourseSlicer.reducer;
