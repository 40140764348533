import Odometer from "react-odometerjs";
import { useEffect, useRef, useState } from "react";

export default function OdometerCustom({endValue,timeStart}) {
  const [value, setValue] = useState();

  useEffect(() => {
    const timeoutId = setTimeout(() => setValue(endValue), timeStart);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return  <Odometer value={value}/>;
}
