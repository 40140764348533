export default function CardFeatures({
  delay,
  duration,
  icon,
  title,
  description,
}) {

  return (
    <>
      <div
        className="relative flex col-sm-12 col-md-4 p-2 items-start"
        data-wow-delay={delay}
        data-wow-duration={duration}
      >
        <div className="icon-div ml-3">
          {icon}
        </div>
        <div className="div-text flex flex-col">
          <h4 className="mb-1">{title}</h4>
          <p>{description}</p>
        </div>
      </div>
    </>
  );
}
