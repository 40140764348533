import {configureStore} from "@reduxjs/toolkit"
import userReduser from "./slices/userSlicer"
import coursesReducer from "./slices/coursesSlicer";
import contactSlice from "./slices/contact/contactSlicer";
import categoriesSlicer from "./slices/categories/categoriesSlicer";
import coursesSlicer from "./slices/courses/coursesSlicer";
import singleCourseSlicer from "./slices/courses/singleCourseSlicer";
import profileSlicer from "./slices/users/profileSlicer";
import lessonsSlicers from "./slices/courses/lessonsSlicers";
import shwoLessonSlicer from "./slices/lessons/showLessonSlicer";
import downloadLessonFileSlicer from "./slices/lessons/DownloadLessonFileSlicer";
import showQuizesSlicers from "./slices/quizes/showQuizesSlicers";
import subscriptionsCoursesSlicer from "./slices/users/subscriptionsCoursesSlicer";
import postStudentAnswersSlicer from "./slices/quizes/PostStudentAnswersSlicer";
import getMainQuizeProgressSlicer from "./slices/quizes/GetMainQuizeProgress";
import getTeachersSlicer from "./slices/teachres/GetTeachresSlicer";
import changePasswordSlicer from "./slices/users/ChangePasswordSlicer";
import editUserImageSlicer from "./slices/users/ChangeImageSlicer"
import postMessageSlicer from "./slices/contact/PostMessageSlicer"
import getRankedStudentsSlicer from "./slices/students-actions/GetRankedStudentsSlicer"
import getLevelsSlicer from "./slices/levels/LevelsSlicer"
import GetAllCoursesSlicer from "./slices/courses/coursesSlicer"
import subscribeRequestSlicer from "./slices/courses/SubscribeRequestSlicer"
import readAllNotficationsSlicer from "./slices/notfications-slicers/ReadAllNotficationsSlicer";
import getNotficationSlicer from "./slices/notfications-slicers/GetNotficationsSlicer";
import getAttemptSlicer from "./slices/attempt-actions/GetAttemptSlicer";
import getUiSettingsSlicer from "./slices/ui-settings-actions/GetUiSettingsSlicer";
import postCompletingLessonSlicer from "./slices/lessons/PostCompletingLessonSlicer";

// catr slicers
import getCartSlicer from "./slices/cart-actions/GetCartSlicer";
import deleteFromCartSlicer from "./slices/cart-actions/DeleteFromCardSlicer";
import addToCartSlicer from "./slices/cart-actions/AddToCartSlicer";

// copone slicers
import applayCouponeSlicer from "./slices/copons-actions/ApplayCouponSlicer";
import checkoutSlicer from "./slices/checkout-actions/CheckoutSlicer";
import activeCourseSlicer from "./slices/courses/activation-actions/ActiveCourseSlicer";

import registerSlicer from "./slices/register-action/RegisterSlicer";
import resetPassSlicer from "./slices/users/ResetPasswordSlicer";
import forgotPassSlicer from "./slices/users/ForgotPassSlicer";
import postVeryfyEmailSlicer from "./slices/users/PostVerifyEmailSlicer";
import getVerifyEmailSlicer from "./slices/users/VerifyEmailSlicer";


import getAllAtemptsSlicer from "./slices/attempt-actions/GetFreeAttempsSlicer";
import getStudentCoursesSlicer from "./slices/courses/GetStudentCoursesSlicer";
const store = configureStore({
    reducer:{
        user : userReduser,
        course : coursesReducer,
        contactSlice,
        
        categoriesSlicer,
        
        // coursesSlicer,
        singleCourseSlicer,
        lessonsSlicers,
        shwoLessonSlicer,
        showQuizesSlicers,
        subscribeRequestSlicer,
        activeCourseSlicer,
        getStudentCoursesSlicer,
        
        profileSlicer,
        subscriptionsCoursesSlicer,

        postStudentAnswersSlicer,

        getTeachersSlicer,

        changePasswordSlicer,
        editUserImageSlicer,
        resetPassSlicer,
        forgotPassSlicer,
        getVerifyEmailSlicer,
        postVeryfyEmailSlicer,

        postMessageSlicer,
        getRankedStudentsSlicer,

        getLevelsSlicer,
        GetAllCoursesSlicer,
        getMainQuizeProgressSlicer,

        readAllNotficationsSlicer,
        getNotficationSlicer,

        getAttemptSlicer,
        getAllAtemptsSlicer,

        getUiSettingsSlicer,
        postCompletingLessonSlicer,

        getCartSlicer,
        deleteFromCartSlicer,
        addToCartSlicer,

        applayCouponeSlicer,
        checkoutSlicer,

        registerSlicer,
        downloadLessonFileSlicer,
    }
})

export default store ;