import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import Swal from "sweetalert2";
import { logout } from "../../../services/logoutAction";
export const addToCartFunc = createAsyncThunk(
    "add to cart function",
    async ({api , data}) => {
        try {
            const response = await axios.post(api , data , {withCredentials : true});
            // checkErrorsFunctions(response , response.data.errors , "الذهاب للعربة");
            const errors = response.data.errors
            const checkErrorsFunctions =  () => {
                if(errors) {
                    if(Object.keys(errors).length >= 1) {
                        const errorKey = Object.keys(errors)[0]; // Get the key of the first error
                        const errorMessage = errors[errorKey]; // Get the error message
                        Object.keys(errors).forEach((errorKey) => {
                            const errorMessage = errors[errorKey][0]; // Get the error message
                            Swal.fire({
                                // title: errorKey,
                                title: "خطا",
                                text: errorMessage,
                                icon: "error"
                            });
                        });
                    }else {
                        Swal.fire({
                            title: "عذرا",
                            text: response.data.message,
                            icon: "error"
                        });
                    }
                }
                else {
                    if(response.data.success !== true && response.data.message) {
                        if(response.data.status_code === 401){
                            Swal.fire({
                                title: "يلزم تسجيل الدخول!",
                                text: "سيتم تحويلك الي صفحة تسجيل الدخول",
                                icon: 'error',
                                confirmButtonText: 'تم'
                            });
                            setTimeout(() => logout() , 2000);
                        }else {
                            Swal.fire({
                                title: "عذرا!",
                                text: response.data.message,
                                icon: 'error',
                                confirmButtonText: 'تم'
                            });
                        }
                    }
                    else{
                        if(response.data.success === true && response.data.message){
                            Swal.fire({
                                title: "احسنت!",
                                text: response.data.message,
                                icon: "success"
                            });
                            Swal.fire({
                                title: "احسنت!",
                                text: response.data.message,
                                icon: "success",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "الذهاب للعربة"
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    window.location = "/cart"
                                }
                              });
                        }
                    }
                }
            }
            checkErrorsFunctions()
            return response.data;
        }
        catch (error) {
            return error;
        }
        
    }
)

const addToCartSlicer = createSlice({
    name : "add to cart slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(addToCartFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(addToCartFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(addToCartFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default addToCartSlicer.reducer;
