import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../store/slices/userSlicer";
import { Link, useNavigate } from "react-router-dom";
import googleIcon from "../../../images/google-icon.png";
import img from "../../../images/graphic3.svg";
import logo from "../../../images/academy-logo.png";
import SendEmailModal from "../../../component/global/global-modals/ForgotPassModal";
import { useForm } from "react-hook-form";
import "../styles/login-custome-style.css";
import { getProfileData } from "../../../store/slices/users/profileSlicer";


// google auth
// import { useGoogleOAuth } from "@react-oauth/google";
// import axios from "axios";
// import { GoogleLogin , GoogleOAuthProvider } from "@react-oauth/google";
export default function LoginFrom() {
  const [showSendingEmailModal, setShowSendingEmailModal] = useState();
  const navigator = useNavigate();
  const {register , formState : {errors} , reset , handleSubmit} = useForm()
  const [showPass , setShowPass] = useState(false);
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.user);
  const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;
  const [allExcutions , setAllExcutions] = useState([])
  const getProfile = async () => {
    try {
      dispatch(getProfileData(profileApi))
      .then(result => {
        if(result.payload && result.payload.invoices) {
          if (result.payload.invoices && result.payload.invoices.length >= 1) {
            const invoices = result.payload.invoices;
            window.localStorage.setItem("studentInvoices" , JSON.stringify(invoices))
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitData = (data) => {
    dispatch(loginUser(data)).then(result =>{
      if(result.payload.data) {
        if(result.payload.data.student) {
          if(!result.payload.data.student.email_verified_at) {
            navigator('verify-email');
          }else {
            if (result.payload.success === true) {
              navigator('/');
              window.location.reload();
              getProfile()
              console.log(result.payload)
              reset();
            }
          }
        }
      }
    });
  };

  return (
    <>
        <div className="page-content relative w-full h-[100vh] bg-[#387fb4]"
            
        >
            {/* <img src={img} alt="img" className="absolute top-0 left-0 w-full h-full" /> */}
            <div className="page-container row relative w-[90%] flex justify-center items-center h-full mx-auto py-4 ">
                <div className="form-div relative flex flex-col justify-center items-center w-full md:w-[400px] rounded-lg py-3 bg-[#f7f7f7] shadow-md shadow-[#00000045]">
                    <i className="fa-solid fa-arrow-left absolute top-[15px] left-[15px] text-blue-900 cursor-pointer transition-all duration-500 hover:translate-x-[-4px]"
                        onClick={() => window.history.back()}
                    ></i>
                    <Link to="/">
                        <img src={logo} alt="logo" className="w-[150px]" />
                    </Link>
                    <h3 className="font-bold text-slate-700 mb-2">سجل الدخول وابدا التعلم</h3>
                    {/* <button 
                      className="relative my-1 flex justify-center items-center"
                      onClick={() => {
                        console.log("login with google")
                      }}
                      >
                      <img src={googleIcon} alt="google" className="w-[20px]" />
                    </button> */}    
                    <div className="register-form-content col-sm-12">
                            {
                            error 
                            && 
                            (
                              <p className="mb-1 login-error text-red-700 text-sm text-center bg-[#ef444485] border border-red-700 rounded-md py-2 px-3">{error}</p>
                            )
                            }
                            <form onSubmit={handleSubmit((data) => {
                              handleSubmitData(data);
                            })}>
                              <div className="form-groub relative mb-1 w-full">
                                <label className="mb-2 text-sm">البريد الالكتروني</label>
                                {errors.email?.message &&
                                <p className="text-red-600 text-sm mb-1">{errors.email?.message}</p>
                                }
                                <div className="relative flex flex-col col-sm-12">
                                    <input
                                        {...register("email" , {required : "البريد الالكتروني مطلوب"})}
                                        className="w-full col-sm-12 indent-[1px] text-sm flex rounded-lg h-[40px] bg-transparent border placeholder:opacity-40"
                                        type="email"
                                    />
                                </div>
                              </div>
                              <div className="form-groub relative mb-1 w-full">
                                <label className="mb-2 text-sm">كلمة المرور</label>
                                {errors.password?.message &&
                                <p className="text-red-600 text-sm mb-1">{errors.password?.message}</p>
                                }
                                <div className="relative flex flex-col col-sm-12">
                                    <input
                                        {...register("password" , {required : "كلمه المرور مطلوبة"})}
                                        className="w-full col-sm-12 indent-[1px] text-sm flex h-[40px] bg-transparent border placeholder:opacity-40"
                                        type={`${showPass ? "text" : "password"}`}
                                    />
                                    <i class="fa-solid fa-eye cursor-pointer absolute top-[50%] translate-y-[-50%] left-[10px]"
                                    onClick={() => setShowPass(!showPass)}
                                    ></i>
                                </div>
                              </div>
                              <div className="relative mb-2">
                                <Link
                                  className="text-sm"
                                  onClick={() => setShowSendingEmailModal(true)}
                                >نسيت كلمة المرور</Link>
                              </div>
                              <div className="tutor-form-row">
                                <div className="tutor-form-col-12">
                                  <div className="tutor-form-group tutor-reg-form-btn-wrap">
                                    <button
                                      type="submit"
                                      name="tutor_register_student_btn"
                                      className="btn bg-[#387fb4] text-white transition-all duration-500 hover:translate-x-[-3px] hover:bg-slate-800"
                                    >
                                      {loading
                                        ? "جاري التحميل ..."
                                        : 
                                        <>
                                        دخول الان
                                        <i className="fa-solid fa-right-to-bracket transition-all duration-500 mr-1 rotate-[180deg] text-white"></i>
                                        </>
                                        }
                                    </button>
                                    <Link to="/register" className="mr-2">حساب جديد</Link>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                </div>
            </div>
        </div>
    <SendEmailModal
      show = {showSendingEmailModal}
      onHide = {() => setShowSendingEmailModal(false)}
    />
    </>
)







}

