import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userCredintianals) => {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json";
    return await axios.post(
      `${process.env.REACT_APP_PUBLIC_API}/api/login`,
      userCredintianals,
      { withCredentials: true }
    ).then(data=>data.data).catch(err=>err);
  }
);

const userSlicer = createSlice({
  name: "uesr",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.success == true) {
        state.user = action.payload;
        localStorage.setItem("userdata", JSON.stringify(action.payload));
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default userSlicer.reducer;
