import { useParams , Link } from "react-router-dom";
import { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuizeData } from "../../../../store/slices/quizes/showQuizesSlicers";
import "./lesson-quize-page.css";
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { postStudentAnswersFunc } from "../../../../store/slices/quizes/PostStudentAnswersSlicer";
import noQuestionImg from "../../../../images/question-mark (1).png";
import explainIcon from "../../../../images/vid-img.png";
import swal from "sweetalert";
import LoadingBook from "../loading-book/LoadingBook";
import PageHeader from "../../../../component/ui/PageHeader";
import Swal from "sweetalert2";
import QuizeTimer from "../quize-timer/QuizeTimer";
import { useRef } from "react";
import faildImg from "../../../../images/planet.png";
import ShowImageModal from "../../../../component/show-image-modal/ShowImageModal";
import { postCompletingLessonFunc } from "../../../../store/slices/lessons/PostCompletingLessonSlicer";
function LessonQuizePage () {
    const params = useParams();
    const lessonId = params.lessonId;
    const dispatch = useDispatch();
    const [quize , setQuize] = useState();
    const [quizeQuestions , setQuizeQuestions] = useState([]);
    const [thereIsArticleQ , setThereIsArticleQ] = useState([]);
    const [answersImages , setAnswersImages] = useState([]);
    const [submitionQuizeId , setSubmitionQuizeId] = useState([]);
    const [quizeTimer , setQuizeTimer] = useState();
    const [articleQuestionsLength , setArticleQuestionsLength] = useState();
    const [onSubmition , setOnSubmition] = useState();
    const [showArticaleQuestion , setShowArticaleQuestion] = useState();
    const [articaleQuestion , setArticaleQuestion] = useState();
    const {data , loading} = useSelector(state => state.shwoLessonSlicer)
    const {handleSubmit,  reset , register , formState : {errors} , setValue} = useForm();
    const quizeApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}/start-quiz`;
    const submitquizeAnswersApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}/submit-quiz`;
    const  {loading : postAnswersLoading} = useSelector(state => state.postStudentAnswersSlicer);
    const postCompletingLessonApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}/complete`

    let beforeunload = true;


    // useEffect(() => {
    //     const handleBeforeUnload = (event) => {
    //         // Customize the message for the confirmation dialog
    //         const confirmationMessage = 'Are you sure you want to leave this page?';

    //         // Set the custom message for the confirmation dialog
    //         event.returnValue = confirmationMessage;

    //         // Return the custom message to trigger the confirmation dialog
    //         return confirmationMessage;
    //     };

    //     // Add event listener for beforeunload event
    //     window.addEventListener('beforeunload', handleBeforeUnload);

    //     // Clean up: remove event listener when component unmounts
    //     return () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };
    // }, []);

    useEffect(() => {
        const handleNavigation = (event) => {
            // Check if the navigation was triggered by the browser's back or forward button
            if (event.type === 'popstate') {
                // Log a message or perform any action
                console.log('User navigated backward or forward');
            }
        };

        // Add event listener for popstate event
        window.addEventListener('popstate', handleNavigation);

        // Clean up: remove event listener when component unmounts
        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };
    }, []);

    const postCompletingLesson = () => {
        dispatch(postCompletingLessonFunc(postCompletingLessonApi))
    }
    const getQuizeDataFunction = async () => {
        dispatch(getQuizeData(quizeApi));
    }
    useEffect(() => {
        getQuizeDataFunction()
    },[]);
    useEffect(() => {
        data && setQuizeQuestions(data.questions);
        data && setQuize(data)
        data && setSubmitionQuizeId(data.id)
        data && data.pivot && setQuizeTimer(parseInt(data.pivot.duration))
    },[data , quizeQuestions])
    useEffect(() => {
        const articleQuestion = data &&  quizeQuestions && quizeQuestions.filter(question => {
            return question.type === 3 })
            articleQuestion && articleQuestion.length > 0 ? setThereIsArticleQ(true) : setThereIsArticleQ(false)
            setArticleQuestionsLength(articleQuestion && articleQuestion.length)
    },[quizeQuestions , data])

    const handleSubmittion = async (data) => {
            if(data && answersImages) {
                if(answersImages.length !== articleQuestionsLength){
                    swal({
                        title: "Invalid submission!",
                        text: "Your images count should equal articale questions count!",
                        icon: "warning",
                        button: true,
                    })
                }else {
                    setOnSubmition(true)
                    window.localStorage.removeItem("quizEndTime");
                    const mainForm = new FormData();
                    for(const img of answersImages){
                        mainForm.append("images[]" , img);
                    }
                    for (const [key, value] of Object.entries(data)) {
                        mainForm.append(`answers[${key}]`, +value);
                    }
                    if(thereIsArticleQ){
                        dispatch(postStudentAnswersFunc({url : submitquizeAnswersApi, answers : mainForm }))
                        .then(result => {
                            if(result.payload.success === true) {
                                postCompletingLesson();
                                // swal("احسنت", "تم رفع الاجابات بنجاح", "success");
                                setTimeout(()=>window.history.back(),2000);
                                window.localStorage.removeItem("quizEndTime");
                                window.localStorage.removeItem("quizeTimer");
                            }
                        })
                    }else {
                        dispatch(postStudentAnswersFunc({url : submitquizeAnswersApi, answers : {answers : data}}))
                        .then(result => {
                            if(result.payload.success === true) {
                                postCompletingLesson();
                                // swal("احسنت", "تم رفع الاجابات بنجاح", "success");
                                setTimeout(()=>window.history.back(),2000);
                                window.localStorage.removeItem("quizEndTime");
                                window.localStorage.removeItem("quizeTimer");
                            }
                        })
                    }
                }
        }
    }
    const submitionForm = useRef();
    const submitBTN = document.querySelector(".submit-answer-btn");
    const autoSubmition = () => {
        if(submitBTN) {
            submitBTN.click();
            setOnSubmition(true);
            postCompletingLesson()
        }
    }
    return (
        <>
            <PageHeader headerImg={"https://images.pexels.com/photos/6373308/pexels-photo-6373308.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} title={"Quize Content"}/>
            <div className="quize-page min-h-[100vh]">
                {
                    loading
                    ?
                        <>
                            <div className="row d-flex justify-content-center gap-2 my-5">
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                            </div>
                        </>
                    :
                    (
                        data
                        ?
                        (
                        <div className="container-quize-page mx-auto my-4 w-[80%] ">
                            <div className="relative flex justify-between items-center">
                                <h3 className="text-primary mb-2 my-2">{quize && quize.title}</h3>
                                <h3 className="text-primary mb-2 my-2">عدد الاسئله: ({quizeQuestions && quizeQuestions.length})</h3>
                            </div>
                            <form
                                className="submit-answer-form"
                                onSubmit={handleSubmit((data) => {
                                    handleSubmittion(data)
                                })}
                            >
                            {
                                quizeQuestions
                                &&
                                quizeQuestions.map((question , index) => {
                                    return (
                                        <div style={
                                            {"animationDelay" : `.${index}s`}
                                        } key={question.id} className={`question-card relative transition-all duration-500 p-3 bg-white shadow-md shadow-slate-300 rounded-lg my-2 ${!loading ? "active" : ""}`}>
                                            <div className="question-title d-flex mb-3">
                                                <p className="ml-3">{index+1}: </p>
                                                {
                                                    question.type !== 3
                                                    ?
                                                    <p className="text-primary">{question.title}</p>
                                                    :
                                                    <div
                                                    className={`question-image rounded-md overflow-hidden relative w-full h-[200px]`}
                                                    >
                                                        <img loading="lazy" src={question.image_url ? question.image_url : "no image" } alt="question-img" className="absolute top-0 cursor-pointer left-0 w-full h-full"
                                                            onClick={() => {
                                                                setShowArticaleQuestion(true)
                                                                setArticaleQuestion(question.image_url)
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    question.type !== 3
                                                    &&
                                                    <div className="relative flex flex-col">
                                                        {
                                                            question.options && 
                                                            question.options.map((option,index)=>{
                                                                return(
                                                                    <div className="relative flex items-center my-2" key={index}>
                                                                        <input 
                                                                        {...register(question.id.toString())}
                                                                        id={option.id} 
                                                                        className="mr-2"
                                                                        type="radio" 
                                                                        value={+index}
                                                                        name={question.id}/>
                                                                        <label 
                                                                        htmlFor={option.id}
                                                                        className="mrl-1 "
                                                                        >{option}</label>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                thereIsArticleQ
                                &&
                                <div className="answers-images relative flex flex-col my-3">
                                    <label className="mb-2" htmlFor="articales-answers">ارفع الاجابات المقالية</label>
                                    <input 
                                    id="articales-answers" 
                                    className="form-control" 
                                    type="file" 
                                    name="files" 
                                    multiple
                                    onChange={(e)=>{
                                        setAnswersImages(Array.from(e.target.files));
                                    }}
                                    />
                                    <p className="relative text-sm m-0 text-red-500 mt-3">ملحوظه : عند  رفع الاجابات للاسئلة المقاليه يجب كتابه رقم السوال قبل الاجابه مثال <span className="text-primary">(اجابه السؤال رقم 2)</span></p>
                                </div>
                            }
                            {
                                data && quizeQuestions && quizeQuestions.length < 1
                                &&
                                <div className="empty-questions relative my-5 flex flex-col justify-center items-center">
                                    <img loading="lazy" src={noQuestionImg} alt="no questions img" className="relative w-[100px] my-4" />
                                    <p>عذرا, لا يوجد اسئله علي هذا الامتحان حتي الان 
                                        <span className="cursor-pointer  text-primary mr-1 " 
                                        onClick={()=>window.history.back()}
                                        >رجوع</span>
                                    </p>
                                </div>
                            }
                            {
                                quizeQuestions && quizeQuestions.length >= 1
                                &&
                                <>
                                    <Button ref={submitionForm} type="submit" className={`bg-primary my-2 submit-answer-btn ${onSubmition ? "opacity-30 pointer-events-none" : ""}`}
                                    >اتمام الامتحان</Button>
                                </>
                            }
                            </form>
                        </div>
                        )
                        :
                        <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                            <img 
                            loading="lazy"
                            src={faildImg}
                            alt="faild img"
                            className="w-[150px] mb-3"
                            />
                            <p className="text-sm">
                            فشل تحميل الامتحان <span
                                className="mr-1 cursor-pointer text-blue-900" 
                                onClick={() => {
                                    getQuizeDataFunction();
                                }}
                            >اعادة المحاولة</span>
                            </p>
                        </div>
                    )

                }
            </div>
            <LoadingBook loadingText={"Don't Refresh.Please Wait For Sending Your Answers!"} loading={postAnswersLoading}/>
            <QuizeTimer 
                getQuizeLoading = {loading} 
                exitQuize = {autoSubmition} 
                timeWithMunites = {quizeTimer && quizeTimer}
                // manuallySubmition={setManuallySubmition}
            />
            <ShowImageModal
                title = {"السؤال المقالي"}
                show = {showArticaleQuestion}
                onHide = {() => setShowArticaleQuestion(false)}
                image={articaleQuestion}
            />
        </>
    )
}

export default LessonQuizePage;