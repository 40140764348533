import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useEffect, useState} from "react"
import ShowImageModal from '../../../../component/show-image-modal/ShowImageModal';
import Swal from 'sweetalert2';
import ShowMultibleImagesModal from '../../../../component/show-multible-images-modal/ShowMultibleImagesModal';



function SessionsDropDown(props) {
    const {sessions , coursename} = props;
    const [showExam , setShowExam] = useState();
    const [exam , setExam] = useState();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className='w-full relative flex items-center justify-end'>
        <Modal.Title id="contained-modal-title-vcenter">
        حصص حضور {coursename}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-0'>
      <table
            className="table student-sessions-course display mb-4 dataTablesCard order-table card-table text-black application "
            id="application-tbl1_next"
        >
            <thead>
                <tr>
                <th className="text-[#4d5fe3]">حاله الطالب</th>
                <th className="text-[#4d5fe3]">درجة الامتحان</th>
                <th className="text-[#4d5fe3]">تاريخ الحصة</th>
                <th className="text-[#4d5fe3]">الامتحان</th>
                </tr>
            </thead>
            <tbody>
            {
                sessions &&
                sessions.length > 0 ?
                sessions.map((session, index) => {
                return (
                    <tr key={index}>
                      <td className="font-bold text-[#4d5fe3]">
                        {
                          session.pivot
                          &&
                          session.pivot.status
                          &&
                          session.pivot.status === "present"
                          ?
                          <span className='relative text-sm flex items-center bg-green-500 rounded-md text-white p-2 w-fit'>
                            <i className="fa-regular fa-circle-check ml-1"></i>
                            حاضر
                          </span>
                          :
                          <span className='relative flex bg-red-500 items-center rounded-md text-white p-2 w-fit text-sm'>
                            <i class="fa-regular fa-circle-xmark ml-1"></i>
                            غياب
                          </span>
                          
                        }
                      </td>
                      <td>{session.offline_exam && session.offline_exam.max_grade ? session.offline_exam.max_grade : "لا يوجد امتحان"}</td>
                      <td className="">
                          {session.session_date}
                      </td>
                      <td className="">
                        <Button className={`btn btn-primary bg-primary ${!session.offline_exam ? "opacity-50 pointer-events-none" : ""}`} onClick={()=>{
                          if(session.offline_exam){
                            setExam(session.offline_exam && session.offline_exam.images_url && session.offline_exam.images_url);
                            setShowExam(true)
                          }else{
                            Swal.fire({
                              title: "عذرا!",
                              text: "لا يوجد امتحان لهذه الحصه",
                              icon: "warning"
                            });
                          }
                        }}>
                          <i className="fa-solid fa-image ml-1"></i>
                          {
                            !session.offline_exam
                            ?
                            "لا يوجد امتحان"
                            :
                            "عرض الامتحان"
                          }
                        </Button>
                      </td>
                    </tr>
                );
                })
                :
                <tr>
                  <td colSpan={4} rowSpan={4} className='empty-session-col'>
                    <h4>لا يوجد حصص اوفلاين لهذا الكورس</h4>
                  </td>
                </tr>
            }
            </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className="bg-primary">اغلاق</Button>
      </Modal.Footer>
      <ShowMultibleImagesModal
        show = {showExam}
        onHide = {() => setShowExam(false)}
        images = {exam}
        title = {"امتحان الحصة"}
        />
      {/* <ShowImageModal
        show = {showExam}
        onHide = {() => setShowExam(false)}
        image = {exam}
        title = {"امتحان الحصة"}
        /> */}
    </Modal>
  );
}
export default SessionsDropDown;