import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import pageHeaderImg from '../../images/backgrounds/page-header-bg.png' 

export default function PageHeader({ title  , headerImg , loadingPage}) {
  const [mounted , setMounted] = useState();
  useEffect(() => {
    !loadingPage ? setTimeout(() => setMounted(true) , 1000) : setMounted(false)
  },[loadingPage])
  return (
    <>
      <section
        className="page-header clearfix"
        style={{
          backgroundImage: `url(${headerImg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-header__wrapper clearfix">
                <div className="page-header__title">
                  <h2 className={`transition-all duration-500 ${mounted ? "translate-x-0 opacity-100" : "translate-x-5 opacity-0"}`}>{title}</h2>
                </div>
                <div className="page-header__menu">
                  <ul className="page-header__menu-list list-unstyled clearfix">
                    <li className={`transition-all duration-500 ${mounted ? "translate-y-0 opacity-100" : "translate-y-5 opacity-0"}`}>
                      <Link to={"/"}>الرئيسية</Link>
                    </li>
                    <li className={`active transition-all duration-500 ${mounted ? "translate-y-0 opacity-100" : "translate-y-[-20px] opacity-0"}`}>{title}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
