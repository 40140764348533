import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const getRankedStudentsFunc = createAsyncThunk('get Teachers func',
async (api)=> {
    try {
        const response = await axios.get(api , {withCredentials:true});
        checkErrorsFunctions(response , response.data.errors);
        return response.data.data
    }
    catch(error) {
        console.log(error)
    }
}
);


const getRankedStudentsSlicer =  createSlice({
    name:'get ranked students',
    initialState :{
        loading:false,
        data:null,
        error:null
    },
    extraReducers: (builder)=>{
    builder.addCase(getRankedStudentsFunc.pending,(state)=>{
        state.loading = true;
        state.data = null;
        state.error = null;
        
    });
    builder.addCase(getRankedStudentsFunc.fulfilled,(state,action)=>{
        state.loading = false;
        state.data = action.payload;
        state.error = null;
    });
    builder.addCase(getRankedStudentsFunc.rejected ,(state,action)=>{
        state.loading = false;
        state.data = null;
        state.error = action.error.message;
    })
    }

});


export default getRankedStudentsSlicer.reducer;
