import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const readAllNotficationsFunc = createAsyncThunk(
    "read/nots", 
    async (api) => {
        try {
            const response = await axios.post(api  , {} , {withCredentials:true});
            // if(response.data.status_code === 401 || response.data.status_code === 403){
            //     logout();
            // }          
            // checkErrorsFunctions(response , response.data.errors);
            return response.data
        }
        catch (error) {
            console.log(error)
        }
    }

);

const readAllNotficationsSlicer = createSlice({
  name: "notfications",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(readAllNotficationsFunc.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(readAllNotficationsFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload
    });
    builder.addCase(readAllNotficationsFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default readAllNotficationsSlicer.reducer;
