import { Link } from "react-router-dom";
import "../style/style.css";
import { useEffect, useState } from "react";
import defaultTeacherImg from "../../../images/presentation.png";

function StudentCourseCard ({index , loading , exName , courseId  , courseName , startDate , endDate , courseImg , teacherName , teacherImg , courseStatus , customClasses , courseDiscount , price , courseExId}){
    const [mounted , setMounted] = useState()
    useEffect(() => {
        !loading ? setTimeout(()=>setMounted(true) , 500) : setMounted(false)
    },[loading])
    return (
        <>
            <div style={{"animationDelay" : `.${index}s`}} className={`${mounted ? "active" : ""} student-course-card relative flex flex-col sm:w-[90%] md:w-[340px] m-3 overflow-hidden hover:mt-[-5px] shadow-md shadow-slate-300 hover:translate-y-[-5px] transition-all duration-500 ${customClasses} p-2`}>
                <div className={`z-[99] absolute top-[10px] left-[10px] text-sm p-2 text-white rounded-md ${courseStatus  === "active" ? "bg-[#153776]" : "bg-red-500"}`}>
                    {
                        courseStatus === "active"
                        ?
                        "نشط"
                        :
                        "انتهي"
                    }
                </div>
                <div className="course-img relative w-full h-[200px] overflow-hidden rounded-[2px] ">
                    <img src={courseImg !== null ? courseImg : "https://images.pexels.com/photos/5553933/pexels-photo-5553933.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} alt="course img" className="absolute top-0 left-0 w-full transition-all duration-500 hover:scale-105" />
                </div>
                <div className="course-info relative flex flex-col my-2 p-2">
                    {/* {
                        courseStatus === "publish"
                        &&
                        <Link className="absolute top-0 left-0 w-full h-full z-[9]"
                            to = {`/lessons/${courseId}`}
                        ></Link>
                    } */}
                    <Link to={`/lessons/${courseExId}`} className="my-2">{courseName}</Link>
                    {/* <div className="teacher-div flex items-center mb-1">
                        <h5 className="text-sm font-bold text-slate-600">معلم الكورس : </h5>
                        <div className="relative flex items-center">
                            <div className="relative w-[30px] h-[30px] mr-1 rounded-[50%] overflow-hidden">
                                <img src={teacherImg !== null ? teacherImg : defaultTeacherImg} alt="teacher img" className="absolute top-0 left-0 w-full h-full" />
                            </div>
                        </div>
                    </div> */}
                    <p className="text-sm mb-2">{exName}</p>
                    {
                        courseDiscount
                        ?
                        <div className="relative flex flex-col mb-1">
                            <p className="relative text-sm">
                                السعر : 
                                <span className="font-bold">{parseInt(price) - parseInt(courseDiscount)} جنيه</span>
                                <del className="text-red-500 mr-2">{price} جنيه</del>
                            </p>
                        </div>
                        :
                        <p className="courses-one__single-content-price mb-1 py-2 text-slate-600">
                        السعر : {price} جنيها
                        </p>
                    }
                    <div className="relative flex flex-col">
                        <p className="relative text-sm">مدة الاشتراك</p>
                        <h4 className="text-sm">من : {startDate}</h4>
                        <h4 className="text-sm">الي : {endDate}</h4>
                    </div>
                    <Link className="absolute top-0 left-0 w-full h-full z-[2]"
                        to = {`/lessons/${courseExId}`}
                    ></Link>
                </div>
            </div>
        
        </>
    )
}
export default StudentCourseCard;