import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PageHeader from "../../../../component/ui/PageHeader";
import headerImage from "../../../../images/quize-people.jpg"
import CustomLoaders from "../../../../component/ui/CustomLoaders";
import { useEffect, useState } from "react";
import { getAttmptFunc } from "../../../../store/slices/attempt-actions/GetAttemptSlicer";
import { Button } from "react-bootstrap";
import ShowVideoModal from "../../../../component/show-video-modal/ShowVideoModal";
import Swal from "sweetalert2";
import ShowAnswerModal from "./QuestionAnswerModal";
import AccordionOptions from "./AccordingOptions";
import ShowImageModal from "../../../../component/show-image-modal/ShowImageModal";
import "./attemp-page.css";
function AttemptPage() {
    const params = useParams();
    const attempId = params.attempId;
    const dispatch = useDispatch();
    const attempApi = `${process.env.REACT_APP_PUBLIC_API}/api/quiz-attempts/${attempId}`;
    const {loading , data} = useSelector(state => state.getAttemptSlicer);
    const [attempt , setAttempt] = useState({})
    const [attemptQuestions , setAttemptQuestions] = useState([]);
    const [explainVideo , setExplainVideo] = useState();
    const [showExplainVideo , setShowExplainVideo] = useState();
    const [showStudentAnswer , setShowStudentAnswer] = useState();
    const [studentAnswer , setStudentAnswer] = useState();
    const [correctAnswer , setCorrectAnswer] = useState();
    const [showCorrectAnswer , setShowCorrectAnswer] = useState();
    const [showArticaleQuestion , setShowArticaleQuestion] = useState();
    const [articaleQuestion , setArticaleQuestion] = useState();
    const [showArticaleAnswer , setShowArticaleAnswer] = useState();
    const [articaleAnswer , setArticaleAnswer] = useState();
    const [showNote , setShowNote] = useState();
    const [note , setNote] = useState();
    const [mounted , setMounted] = useState();
    const [finalStudentScore , setFinalStudentScore] = useState();
    const [quizScore , setQuizScore] = useState();
    const getAttemp = async () => {
        dispatch(getAttmptFunc(attempApi))
        .then(result => console.log(result))
    }
    useEffect(() => {
        getAttemp();
    },[])
    useEffect(() => {
        data && console.log(data)
        data && data.attempt && setAttempt(data.attempt)
        data && data.attempt && setAttemptQuestions(data.attempt.questions);
        const finalQuizScore = data && attemptQuestions.length >=1 && attemptQuestions.flatMap(Q => Q.grade).reduce((acc , current) => {
            return acc + current
        })
        const finalStudentGrades = data && attemptQuestions.length >=1 && attemptQuestions.flatMap(Q => Q.pivot).flatMap(P => P.grade).reduce((acc , current) => {
            return acc + current
        })
        setQuizScore(finalQuizScore)
        setFinalStudentScore(finalStudentGrades)
    },[data])

    const checkAttemptStatus = (status) => {
        let el;
        switch(status) {
            case "compleated":
                el = <p className="text-green-500">ناجح</p>
                break
            case "repetition":
                el = <p className="text-red-500">راسب</p>
                break
            default :
            el = <p className="text-blue-500">قيد التصحيح</p>
        }
        return el
    }
    const checkQuestionType = (type) => {
        let el;
        switch(type) {
            case 1:
                el = "سؤال صح و خطا";
                break;
            case 2:
                el = "سؤال اختر";
                break;
            case 3:
                el = "سؤال مقالي";
                break;
            default :
                el = "لا يوجد نوع"
            }
        return el
    }

    useEffect(() => {
        !loading ? setTimeout(() => setMounted(true) , 100) : setMounted(false)
    },[loading])
    return (
        <>
            <PageHeader headerImg={headerImage} title={"اجابات الامتحان"}/>
            <div className="attempt-contet relative">
                <div className="attempt-container relative w-[90%] mx-auto">
                    {
                        loading
                        ?
                        <>
                            <div className="row d-flex justify-content-center gap-2 my-5">
                                <CustomLoaders />
                                <CustomLoaders />
                                <CustomLoaders />
                                <CustomLoaders />
                            </div>
                        </>
                        :
                        (
                            data && attempt && attempt.answers && attempt.answers.length >=1
                            ?
                            <div className="relative flex flex-col">
                                {
                                    data && attempt
                                    &&
                                    <div className="relative attempt-data w-full md:w-[90%] mx-auto my-3">
                                        <div className="data py-2 flex flex-col md:flex-row justify-between">
                                            <div className={`relative flex flex-col box-data ${mounted ? "active" : ""} transition-all duration-500`}>
                                                <div className="data-box relative flex items-center mb-2">
                                                    <i className="fa-solid fa-flag ml-1 opacity-80 text-sm"></i>
                                                    <p>عدد اسئلة الامتحان</p>
                                                    <span className="mx-1">:</span>
                                                    <p>({attemptQuestions && attemptQuestions.length})</p>
                                                </div>
                                                <div className="relative flex items-center">
                                                    <i className="fa-solid fa-ranking-star ml-1 opacity-80 text-sm"></i>
                                                    <p>الدرجة النهائية</p>
                                                    <span className="mx-1">:</span>
                                                    <p className={""}>{quizScore}</p>
                                                </div>
                                                <div className="relative flex items-center">
                                                    <i className="fa-solid fa-graduation-cap ml-1 opacity-80 text-sm"></i>
                                                    <p>درجة الطالب</p>
                                                    <span className="mx-1">:</span>
                                                    <p className={`${finalStudentScore < (quizScore/2) ? "text-red-500" : "text-green-500"}`}>{finalStudentScore}</p>
                                                </div>
                                                {/* <div className="relative flex items-center">
                                                    <i className="fa-solid fa-temperature-three-quarters ml-1 opacity-80 text-sm"></i>
                                                    <p>حالة الطالب</p>
                                                    <span className="mx-1">:</span>
                                                    {checkAttemptStatus(attempt.status_passed)}
                                                </div> */}
                                                <button className="btn mt-3 bg-primary text-white transition-all duration-500 hover:translate-y-[-4px]
                                                hover:shadow-slate-400 shadow-md"
                                                    onClick={() => {
                                                        if(attempt.note){
                                                            setShowNote(true)
                                                            setNote(attempt.note)
                                                        }else {
                                                            Swal.fire({
                                                                title: "عذرا!",
                                                                text: "لا يوجد ملحوظات من المصحح",
                                                                icon: "warning"
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <i className="fa-solid fa-comments ml-1"></i>
                                                    ملحوظه من المصحح</button>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    data && attemptQuestions
                                    &&
                                    attemptQuestions.map((question , index) => {
                                        return (
                                            question.type !== 3
                                            ?
                                            <div key={index} style={{animationDelay : `.${index}s`}} className={`relative question-box flex flex-col mb-3 w-full md:w-[90%] shadow-md shadow-slate-200 p-3 bg-[#f2f2f2] mx-auto ${mounted ? "active" : "" } transition-all duration-500`}>
                                                <div className="relative question-header flex flex-col">
                                                    <div className="question-title relative flex items-center">
                                                        <i className="fa-solid fa-circle-question ml-1 opacity-80"></i>
                                                        السؤال : 
                                                        <p className="m-0 mr-1">{question.title}</p>
                                                    </div>
                                                    <p className="my-2">
                                                        <i className="fa-solid fa-quote-left ml-1 opacity-80"></i>
                                                        نوع السؤال : 
                                                        {/* {questionsTypes[question.type+1]} */}
                                                        {checkQuestionType(question.type)}
                                                    </p>
                                                    <div className="relative flex">
                                                        <p className="text-sm">
                                                            <i className="fa-solid fa-arrow-trend-up ml-1 opacity-80"></i>
                                                            الدرجة النهائية :
                                                            <span className="font-bold">
                                                                ( {question.grade} )
                                                            </span>
                                                        </p>
                                                        <p className="mr-3 text-sm">
                                                            <i className="fa-solid fa-graduation-cap ml-1 opacity-80"></i>
                                                            درجة الطالب : 
                                                            <span className={`font-bold ${question.pivot.grade < question.grade / 2 ? "text-red-500" : "text-green-500"}`}>
                                                            ( {question.pivot.grade} )
                                                            </span>
                                                            </p>
                                                    </div>
                                                </div>
                                                <AccordionOptions question={question}/>
                                                <div className="btns-div relative flex">
                                                    {/* <Button className="relative bg-[#1e6fbe] border-[#1e6fbe] ml-2"
                                                        onClick = {() => {
                                                            setCorrectAnswer(question.options[question.correct_option]);
                                                            setShowCorrectAnswer(true)
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-circle-check ml-1"></i>
                                                        عرض الاجابه الصحيحة</Button> */}
                                                    <Button className="relative bg-[#1e6fbe] border-[#1e6fbe] ml-2"
                                                        onClick = {() => {
                                                            setStudentAnswer(question.pivot.answer);
                                                            setShowStudentAnswer(true)
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-graduation-cap ml-1"></i>
                                                        عرض اجابة الطالب
                                                    </Button>
                                                    <Button className="relative bg-[#396495] border-[#396495] ml-2"
                                                        onClick = {() => {
                                                            if(question.video_url) {
                                                                setExplainVideo(question.video_url)
                                                                setShowExplainVideo(true)
                                                            }
                                                            else {
                                                                Swal.fire({
                                                                    title: "عذرا!",
                                                                    text: "لا يوجد شرح لهذا السؤال!",
                                                                    icon: "warning"
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <i className="fa-solid fa-circle-play ml-1"></i>
                                                        شرح السؤال
                                                    </Button>
                                                </div>
                                            </div>
                                            :
                                            <div key={index} style={{animationDelay : `.${index}s`}} className={`relative question-box flex flex-col mb-3 w-full md:w-[90%] shadow-md shadow-slate-200 p-3 bg-[#f2f2f2] mx-auto ${mounted ? "active" : "" } transition-all duration-500`}>
                                            <div className="relative question-header flex flex-col">
                                                <div className="question-title relative flex items-center">
                                                    <i className="fa-solid fa-circle-question ml-1 opacity-80"></i>
                                                    السؤال : 
                                                    <p className="m-0 mr-1">{question.title}</p>
                                                </div>
                                                <p className="my-2">
                                                    <i className="fa-solid fa-quote-left ml-1 opacity-80"></i>
                                                    نوع السؤال : 
                                                    {checkQuestionType(question.type)}
                                                </p>
                                                <div className="relative flex">
                                                    <p className="text-sm">
                                                        <i className="fa-solid fa-arrow-trend-up ml-1 opacity-80"></i>
                                                        الدرجة النهائية :
                                                        <span className="font-bold">
                                                            ( {question.grade} )
                                                        </span>
                                                    </p>
                                                    <p className="mr-3 text-sm">
                                                        <i className="fa-solid fa-graduation-cap ml-1 opacity-80"></i>
                                                        درجة الطالب :
                                                        <span className={`font-bold ${question.pivot.grade < question.grade / 2 ? "text-red-500" : "text-green-500"}`}>
                                                        ( {question.pivot.grade} )
                                                        </span></p>
                                                </div>
                                            </div>
                                            <AccordionOptions question={question}/>
                                            <div className="btns-div relative flex">
                                                <Button className="relative bg-[#1e6fbe] border-[#1e6fbe] ml-2"
                                                    onClick = {() => {
                                                        if(question.image_url){
                                                            setArticaleQuestion(question.image_url);
                                                            setShowArticaleQuestion(true)
                                                        }
                                                        else {
                                                            Swal.fire({
                                                                title: "عذرا!",
                                                                text: "لا يوجد صورة لهذا السؤال",
                                                                icon: "warning"
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <i className="fa-solid fa-image ml-1"></i>
                                                    عرض السؤال
                                                </Button>
                                                {/* <Button className="relative bg-[#1e6fbe] border-[#1e6fbe] ml-2"
                                                    onClick = {() => {
                                                        if(question.pivot.image_url) {
                                                            setArticaleAnswer(question.pivot.image_url);
                                                            setShowArticaleAnswer(true)
                                                        }else {
                                                            Swal.fire({
                                                                title: "عذرا!",
                                                                text: "لم يتم رفع صوره الاجابه لهذا السؤال",
                                                                icon: "warning"
                                                            });
                                                        }
                                                    }}
                                                >
                                                                                                                                                        <i className="fa-solid fa-graduation-cap ml-1"></i>
                                                    عرض اجابة الطالب
                                                </Button> */}
                                                
                                                <Button className="relative bg-[#396495] border-[#396495] ml-2"
                                                    onClick = {() => {
                                                        if(question.video_url) {
                                                            setExplainVideo(question.video_url)
                                                            setShowExplainVideo(true)
                                                        }
                                                        else {
                                                            Swal.fire({
                                                                title: "عذرا!",
                                                                text: "لا يوجد شرح لهذا السؤال!",
                                                                icon: "warning"
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <i className="fa-solid fa-circle-play ml-1"></i>
                                                    شرح السؤال
                                                </Button>
                                                
                                            </div>
                                            </div>
                                        )
                                    })
                                }
                                {
                                    data && attempt
                                    &&
                                    <div className="artical-answers relative w-full md:w-[90%] mx-auto flex flex-col py-3 my-3">
                                        <h3 className="mb-2">اجابات الاسئلة المقالية</h3>
                                        <div className="answers gap-1 relative flex flex-wrap mt-2">
                                            {
                                                attempt && attempt.images_url && attempt.images_url.length >= 1
                                                ?
                                                attempt.images_url.map((answer , index) => {
                                                    return (
                                                        <div key={index} className="relative img-div cursor-pointer w-[100px] h-[70px] rounded-md overflow-hidden" 
                                                            onClick={()=>{
                                                                setArticaleAnswer(answer);
                                                                setShowArticaleAnswer(true)
                                                            }}
                                                        >
                                                            <img loading="lazy" src={answer} className="absolute top-0 left-0 w-full h-full transition-all duration-500 hover:scale-105" alt="articale answer img" />
                                                        </div>
                                                    )

                                                })
                                                :
                                                <p className="mt-1">لم يتم رفع اي اجابات مقاليه في هذا الامتحان</p>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            <div className="container empty-cart-container relative w-[90%] mx-auto flex flex-col justify-center items-center py-4 my-[50px]">
                                <i className="fa-solid fa-question mb-2 text-[40px]"></i>
                                <p>لا يوجد اجابات لهذا الامتحان</p>
                                {/* <p>You Have No Items In Your Shopping Cart.</p> */}
                                {/* <Link to="airpods"> */}
                                    <p
                                        className="text-blue-500 cursor-pointer"
                                        onClick={() => window.history.back()}
                                    >رجوع</p>
                                {/* </Link> */}
                            </div>
                            
                        )
                    }
                </div>
            </div>
            <ShowVideoModal
                title = {"شرح السؤال"}
                videoUrl={explainVideo}
                show = {showExplainVideo}
                onHide = {() => setShowExplainVideo(false)}

            />
            <ShowAnswerModal
                show = {showNote}
                onHide = {() => setShowNote(false)}
                answer={note}
                headerText = {'ملحوظة من مصحح الامتحان'}
            />
            <ShowAnswerModal
                show = {showStudentAnswer}
                onHide = {() => setShowStudentAnswer(false)}
                answer={studentAnswer}
                headerText = {'اجابة الطالب'}
            />
            <ShowAnswerModal
                show = {showCorrectAnswer}
                onHide = {() => setShowCorrectAnswer(false)}
                answer={correctAnswer}
                headerText = {'الاجابة الصحيحة'}
            />
            <ShowImageModal
                title = {"السؤال المقالي"}
                show = {showArticaleQuestion}
                onHide = {() => setShowArticaleQuestion(false)}
                image={articaleQuestion}
            />
            <ShowImageModal
                title = {"اجابة السؤال المقالي"}
                show = {showArticaleAnswer}
                onHide = {() => setShowArticaleAnswer(false)}
                image={articaleAnswer}
            />
        </>
    )
}

export default AttemptPage;