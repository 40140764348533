import { Link } from "react-router-dom";


import logocustom from "../../images/resources/logo-custom.png";
import footer_bg from "../../images/backgrounds/footer-bg.png";
import platformLogoImg from "../../images/1.png"
import { useContext } from "react";
import ContextApi from "../../store/slices/Context";


export default function Footer() {
  const contextApi = useContext(ContextApi)
  const firstEmail = contextApi.generalSetting.emails[0];
  const firstPhone = contextApi.generalSetting.phones[0];
  const firstAddress = contextApi.generalSetting.addresses[0];
  const platformLogo = contextApi.generalSetting.platform_logo_url
    return (
      <>
      <footer className="footer-one">
        <div className="footer-one__bg bg-[#041739]">
        </div>{/* /.footer-one__bg */}
        <div className="footer-one__top">
          <div className="container">
            <div className="row">
              {/*Start Footer Widget Column*/}
              <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.1s">
                <div className="footer-widget__column footer-widget__about">
                  <div className="footer-widget__about-logo">
                    <a href="index.html">
                    <img loading="lazy" src={platformLogo ? platformLogo : platformLogoImg} alt="platfrom logo" />
                    </a>

                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.3s">
                <div className="footer-widget__column footer-widget__courses">
                  <h3 className="footer-widget__title">الكورسات</h3>
                  <ul className="footer-widget__courses-list list-unstyled">
                    <li><Link to="/courses">الرياضيات</Link></li>
                    <li><Link to="/courses">التاريخ</Link></li>
                    <li><Link to="/courses">العلوم</Link></li>
                    <li><Link to="/courses">الادب والنحو</Link></li>
                    <li><Link to="/courses">الاحياء والجيولوجيا</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-4 wow animated fadeInUp" data-wow-delay="0.5s">
                <div className="footer-widget__column footer-widget__links">
                  <h3 className="footer-widget__title">روابط سريعة</h3>
                  <ul className="footer-widget__links-list list-unstyled">
                    <li><Link to={"/about"}>عنا </Link></li>
                    <li><Link to={"/about"}>لمحات سريعة</Link></li>
                    <li><Link to={"/about"}>المعلمين</Link></li>
                    <li><Link to={"/contact"}>انضم الينا</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.7s">
                <div className="footer-widget__column footer-widget__contact">
                  <h3 className="footer-widget__title">تواصل معنا </h3>
                  <p className="text">{firstAddress}</p>
                  <p><a href="mailto:info@templatepath.com">{firstEmail}</a></p>
                  <p className="phone"><Link to="tel:123456789">{firstPhone}</Link></p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.9s">
                <div className="footer-widget__column footer-widget__social-links">
                  <ul className="footer-widget__social-links-list list-unstyled clearfix">
                    <li><Link href="https://www.facebook.com"><i className="fab fa-facebook" /></Link></li>
                    <li><Link href="https://www.twitter.com"><i className="fab fa-twitter" /></Link></li>
                    <li><Link href="https://www.instagram.com"><i className="fab fa-instagram" /></Link></li>
                  </ul>
                </div>
              </div>
              {/*End Footer Widget Column*/}
            </div>
          </div>
        </div>
        {/*Start Footer One Bottom*/}
        <div className="footer-one__bottom">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-one__bottom-inner">
                  <div className="footer-one__bottom-text text-center">
                    <p>© جميع الحقوق محفوظة لدي شركة  
                    <Link to=""> &nbsp;  &nbsp; TOPGROWTH </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*End Footer One Bottom*/}
      </footer>
      </>
    )
};
