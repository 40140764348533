import { Modal , Button, Accordion } from "react-bootstrap";
import { useState , useEffect, useContext } from "react";
import { useSelector , useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { subscribeRequestFunc } from "../../../../store/slices/courses/SubscribeRequestSlicer";
import Swal from "sweetalert2";
// import Select from "react-select";
// import SelectBox from "./Select";


function SubscirbeForm(props) {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      watch,
      reset,
    } = useForm();
    const { execution_course_id } = props;
    const { loading , data} = useSelector((state) => state.subscribeRequestSlicer);
    const dispatch = useDispatch();
    const subscribeApi = `${process.env.REACT_APP_PUBLIC_API}/api/contacts`;


    const handleSubmitRequest = (data) => {
        console.log({...data , execution_course_id})
        if(data) {
          if(data.phone && data.phone.length < 11) {
            Swal.fire({
              title: "عذرا!",
              text: "الرجاء ادخال رقم هاتف صحيح !",
              icon: "warning"
            });
          }else {
            dispatch(subscribeRequestFunc({api : subscribeApi, data : {...data , execution_course_id}}))
            .then(result => {
              console.log(result)
                if(result.payload && result.payload.errors) {
                    if(result.payload.errors.course){
                        Swal.fire({
                            title: "عذرا!",
                            text: result.payload.errors.course[0],
                            icon: "warning"
                        });
                    }
                    else if(result.payload.errors.phone){
                        Swal.fire({
                            title: "عذرا!",
                            text: result.payload.errors.phone[0],
                            icon: "warning"
                        });
                    }
                }
                else {
                    if(result.payload.success === true) {
                        Swal.fire({
                            title: "احسنت!",
                            text: "تم طلب الاشتراك بنجاح سوف يتم التواصل",
                            icon: "success"
                        });
                        reset();
                        props.onHide();
                    }else {
                        Swal.fire({
                            title: "عذرا!",
                            text: "!فشل طلب الاشتراك الرجاء المحاوله مرة اخري",
                            icon: "warning"
                        });
                    }
                }
            })
          }
        }
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="text-right w-full">
            طلب اشتراك
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <form
              id="subscribe-form"
                onSubmit={handleSubmit((data) => {
                    handleSubmitRequest(data)
                })}
            >
                <div className="row">
                    <div className="form-group mb-3 col-sm-12 col-md-6 text-right">
                    <label className="mb-1">البريد الالكتروني</label>
                    {errors.email?.message && (
                        <p className="text-red-600">{errors.email?.message}</p>
                    )}
                    <input
                        {...register("email", {
                        required: "الرجاء ادخال بريد الكتروني صحيح!",
                        })}
                        type="email"
                        className="form-control text-right"
                    />
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6 text-right">
                    <label className="mb-1">رقم الموبايل</label>
                    {errors.phone?.message && (
                        <p className="text-red-600">{errors.phone?.message}</p>
                    )}
                    <input
                        {...register("phone", {
                        required: "الرجاء ادخال رقم موبايل صحيح!",
                        })}
                        type="text"
                        max={11}
                        className="form-control text-right"
                    />
                    </div>
                </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button 
            className="bg-red-500 border-red-500"
          onClick={()=>{
            props.onHide();
          }}>الفاء</Button>
          <Button type="submit" form="subscribe-form" className="btn-primary bg-primary">
            {!loading ? (
              "اشتراك"
            ) : (
              <>
                <div className="relative flex justify-center items-center">
                  جاري الطلب..
                  <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white "></div>
                </div>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

export default SubscirbeForm;