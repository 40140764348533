import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { checkoutFunc } from '../../../store/slices/checkout-actions/CheckoutSlicer';
import electronicWalletIcon from "../../../images/cart-page/mobile-payment.png";
import electronicWalletIcon2 from "../../../images/cart-page/electronic-wallet.png";
import bankCardIcon from "../../../images/cart-page/credit-card.png";
import sendingreciptIcon from "../../../images/cart-page/invoice.png";
import visaIcon from "../../../images/cart-page/visa (1).png";
import masterCardIcon from "../../../images/cart-page/master-card.png";
import mizaCardIcon from "../../../images/cart-page/miza-card.png";
import "./checkout-modal.css";
import OverLayLoading from '../overlay-loading/OverLayLoading';
import { useNavigate } from 'react-router-dom';
function CheckoutModal(props) {
    const navigate = useNavigate()
    const {coupone , reset_cart} = props;
    const [receiptImage , setReceiptImage] = useState("");
    const [paymentType , setPaymentType] = useState("");
    const [walletNumber , setWalletNumber] = useState("");
    const {loading:checkoutLoading} = useSelector(
        state => state.checkoutSlicer
    )
    const checkoutApi = `${process.env.REACT_APP_PUBLIC_API}/api/checkout`;
    const dispatch = useDispatch();
    const checkout = (e) => {
        e.preventDefault()
        const paymentForm = new FormData();
        paymentForm.append("type_payment" , paymentType);
        receiptImage !== "" && paymentForm.append("receipt_image" , receiptImage);
        walletNumber !== "" && paymentForm.append("wallet_number" , walletNumber);
        coupone !== "" && paymentForm.append("coupon_code" , coupone);
        dispatch(checkoutFunc({api : checkoutApi , data : paymentForm}))
        .then(result => {
            if(result.payload.success === true) {
                console.log(paymentType)
                if(paymentType === "send_receipt"){
                    setPaymentType("");
                    setReceiptImage("");
                    setWalletNumber("");
                    props.onHide();
                    reset_cart();
                    navigate("profile");
                }else {
                    if(result.payload.data.response) {
                        window.location.replace(result.payload.data.response)
                    }
                }
            }
        })
    }

    const paymentsTypes = [
        {id : 1 , payment : "send_receipt" , title : "ارسال ايصال الدفع" , icon : sendingreciptIcon},
        {id : 2 , payment : "bank_card" , title : "فيزا" , icon : bankCardIcon},
        {id : 3 , payment : "electronic_wallet" , title : "محفظة الكترونية" , icon : electronicWalletIcon},
    ]
    const switchPaymentsForms = () => {
        if(paymentType !== "") {
            switch (paymentType) {
                case "send_receipt" :
                    return (
                        <form 
                        onSubmit={(e) => {
                            checkout(e);
                        }}
                        id="send-recipct-form" 
                        class="file-upload-form activation-form mt-3 flex items-center justify-center flex-col">
                        <label for="file" class="file-upload-label border-dashed border-slate-400 cursor-pointer">
                            <div class="file-upload-design flex flex-col items-center justify-center gap-2">
                            <svg viewBox="0 0 640 512" class="h-10 text-gray-500 fill-current">
                                <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                            </svg>
                            {
                                receiptImage &&
                                receiptImage !== "" &&
                                receiptImage.name
                                ?
                                receiptImage.name
                                :
                                <p>قم بتحميل صوره ايصال الدفع</p>
                            }
                            <span class="browse-button bg-gray-700 px-4 py-2 rounded-lg text-white transition duration-300 hover:bg-gray-900">تحميل صوره</span>
                            </div>
                            <input id="file" type="file" class="hidden" accept='image/*' 
                                onChange={(e) => {
                                    setReceiptImage(e.target.files[0])
                                    console.log(e.target.files[0])
                                }}
                            />
                        </label>
                        {
                            receiptImage !== ""
                            &&
                            <button 
                            type='submit' 
                            form='send-recipct-form' 
                            class={`relative group cursor-pointer text-sky-50  overflow-hidden h-13 w-56 my-3 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold ${checkoutLoading ? "pointer-events-none opacity-50" : ""}`}>
                                <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-40 h-40 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-900"></div>
                                <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-32 h-32 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-800"></div>
                                <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-24 h-24 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-700"></div>
                                <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-14 h-14 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-600"></div>
                                <p class="z-10">ارسال الان</p>
                            </button>
                        }
                        </form>
                    );
                case "electronic_wallet" :
                    return (
                        <form
                        id="send-wallet-number-form"
                        onSubmit={(e) => {
                            checkout(e);
                        }}
                        >
                            <div className='relative flex flex-col justify-center items-center'>
                                <div className='icon relative flex flex-col justify-center items-center'>
                                    <img src={electronicWalletIcon2} alt="icon" className='relative my-2 w-[40px]' />
                                    <p>قم بادخال رقم محفظه الدفع</p>
                                </div>
                                <input className='relative rounded-md placeholder:text-slate-300 p-2 my-2 form-control w-[90%]' placeholder='01XXXXXXXX' 
                                    onChange={(e) => setWalletNumber(e.target.value)}
                                />
                                <button 
                                type='submit' 
                                form='send-wallet-number-form' 
                                class={`relative group cursor-pointer text-sky-50  overflow-hidden h-13 w-56 my-3 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold ${checkoutLoading ? "pointer-events-none opacity-50" : ""}`}>
                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-40 h-40 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-900"></div>
                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-32 h-32 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-800"></div>
                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-24 h-24 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-700"></div>
                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-14 h-14 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-600"></div>
                                    <p class="z-10">ارسال الان</p>
                                </button>
                            </div>
                        </form>
                    );
                case "bank_card" :
                    return (
                        <form
                        id="payment-visa-form"
                        className='relative flex flex-col justify-center items-center'
                        onSubmit={(e) => {
                            checkout(e);
                        }}
                        >
                            <div className='relative flex items-center my-3'>
                                <img src={visaIcon} className='relative my-2 w-[50px] mx-1' alt="icon" />
                                <img src={masterCardIcon} className='relative my-2 w-[50px] mx-1' alt="icon" />
                                <img src={mizaCardIcon} className='relative my-2 w-[50px] mx-1' alt="icon" />
                            </div>
                            <button type='submit' form='payment-visa-form' class="go-payment-btn flex justify-center items-center text-[15px] hover:translate-y-[-3px] transition-all duration-500">
                                الذهاب للدفع    
                            </button>
                        </form>
                    );
                default :
                    return (
                        <form
                        id="activation-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            checkout()
                        }}
                        >
                            <p>فم باختيار طرق الدفع</p>
                        </form>
                    );

            };
        }
    }



  return (
    <Modal
      {...props}
      size="lg"
    //   className='z-[2]'
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='relative w-full flex justify-end items-center text-[17px]'>
            طرق الدفع
            <i className="fa-solid fa-credit-card text-slate-700 ml-1"></i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='relative w-full'>
            <div className='relative flex justify-center items-center payments-types'>
                {
                    paymentsTypes
                    &&
                    paymentsTypes.map((payment , index) => {
                        return (
                            <div key={index} className='relative payment-div flex justify-center items-center w-[70px] h-[70px] rounded-md bg-slate-100 cursor-pointer mx-2 p-1 transition-all duration-500 hover:translate-y-[-3px] border border-blue-500'>
                                <span className='absolute payment-title transition-all duration-500 top-0 left-[50%] translate-x-[-50%] p-1 bg-[#131d36] text-white w-[120px] text-sm flex justify-center items-center rounded-md z-[9999]'>{payment.title}</span>
                                <input 
                                    id={payment.id}
                                    value={payment.payment}
                                    type='radio' 
                                    name="payment" 
                                    className='absolute top-[3px] left-[3px]'
                                    onChange={(e) => {
                                        setPaymentType(e.target.value);
                                        setReceiptImage("");
                                        setWalletNumber("");
                                    }}
                                />
                                <label className='relative w-full h-full flex justify-center items-center flex-col cursor-pointer' htmlFor={payment.id}>
                                    <div className='img relative  w-[40px] h-[40px]'> 
                                        <img src={payment.icon} alt="payment icon" className='relative'/>
                                    </div>
                                </label>
                            </div>
                        )
                    })
                }
            </div>
            {
                switchPaymentsForms()
            }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={() => {
            props.onHide();
            setPaymentType("");
            setReceiptImage("");
            setWalletNumber("");
        }
        }>اغلاق</button>
      </Modal.Footer>
      <OverLayLoading loading={checkoutLoading} loadingtext={"جاري التحميل.."} />
    </Modal>
  );
}
export default CheckoutModal;