import { useEffect } from "react";
import PageHeader from "../../component/ui/PageHeader";
import Partners from "../../component/ui/Partners";
import AboutSection from "./component/AboutSection";
import Counter from "./component/Counter";
import Teachers from "./component/Teachers";
import aboutHeaderImg from "../../images/about/page-header-bg.jpg";

export default function About() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <PageHeader title="من نحن وماذا تعرف عنا؟" headerImg={aboutHeaderImg}/> 
      <AboutSection />
      <Partners />
      <Counter />
      <Teachers />
    </>
  );
}
