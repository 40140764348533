import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassFunc } from '../../../store/slices/users/ForgotPassSlicer';
import Swal from 'sweetalert2';

export default function SendEmailModal(props) {
    const {loading:sendEmailLoading} = useSelector(
        state => state.forgotPassSlicer
    )
    const [email , setEmail] = useState()
    const forgotPassApi = `${process.env.REACT_APP_PUBLIC_API}/api/forgot-password`;
    const dispatch = useDispatch();
    const handleSubmitData = () => {
        if(email || email !== "") {
            try {
                dispatch(forgotPassFunc({api : forgotPassApi , data : {email : email}}))
                .then(result => {
                    if(result.payload.success === true) {
                        props.onHide();
                        setEmail("");
                    }
                })
            }
            catch(error) {
                Swal.fire({
                    title: "عذرا!",
                    text: error,
                    icon: "warning"
                });
            }
        }
        else {
            Swal.fire({
                title: "عذرا!",
                text: "البريد  الالكتروني مطلوب",
                icon: "warning"
            });
        }
    }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='relative w-full flex justify-end items-center text-[17px]'>
          تحديث كلمة المرور
          <i className="fa-solid fa-unlock text-slate-700 ml-1"></i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='relative w-full'>
            <form
            id="activation-form"
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmitData()
            }}
            >
                <div className='relative flex flex-col justify-center items-center'>
                    <div className='activation-input relative col-sm-6 flex flex-col justify-center items-center'>
                        <input
                            type='email'
                            className='relative form-control mb-2 py-1 text-right'
                            placeholder='البريد الالكتروني'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <button class={`cursor-pointer transition-all duration-500 hover:translate-y-[-3px] mt-3 hover:rounded-md shadow-md hover:shadow-slate-500 bg-primary font-semibold overflow-hidden relative z-100 border-primary text-white px-8 py-2 border-none outline-none flex justify-center items-center text-[17px] ${(sendEmailLoading || email === "") ? "pointer-events-none opacity-50" : ""}`}>
                        {
                            sendEmailLoading
                            ?
                            "يرجي الانتظار.."
                            :
                            <>
                            ارسال
                            <i className="fa-solid fa-paper-plane ml-1"></i>
                            </>
                        }
                    </button>
                </div>
            </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button onClick={props.onHide}>اغلاق</button>
      </Modal.Footer>
    </Modal>
  );
}