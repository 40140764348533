import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css/pagination";
import faildImg from "../../../images/question-mark (1).png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { fetchAllCoursesFunc } from "../../../store/slices/courses/coursesSlicer";
import CardCourses from "../../../component/ui/course-card/CardCourses";
import Btn from "../../../component/ui/Btn";


export default function BestSellingSection() {
  const dispatch = useDispatch();
  let params = {
    breakpoints: {
        200 : {
            slidesPerView : 1,
        },
        640: {
            slidesPerView: 1,
        },
        768: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    },
}
const { loading:getExecutionsLoading , courses:data } = useSelector((s) => s.GetAllCoursesSlicer);
const [courses, setCourses] = useState([]);
const [excutions, setExcutions] = useState([]);
const [bestSellingCount, setBestSellingCount] = useState("");
const excutionsApi = `${process.env.REACT_APP_PUBLIC_API}/api/course-executions`;



useEffect(() => {
  let coursesApi = `${excutionsApi}${bestSellingCount !== "" ? `?best_selling_count=${bestSellingCount}` : `?best_selling_count=${0}`}`;
  let searchTimeout;
  let fetchExecutionData = async ()=>{
    if(bestSellingCount !== "") {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(()=>{
        dispatch(fetchAllCoursesFunc(coursesApi))
      },2000)
    }else {
      dispatch(fetchAllCoursesFunc(coursesApi))
    }
  }
  fetchExecutionData()
  return () => clearTimeout(searchTimeout)
},[bestSellingCount])


useEffect(() => {
  dispatch(fetchAllCoursesFunc(excutionsApi))
},[]);
useEffect(() => {
    data && console.log(data)
  data && setExcutions(data)
  const coursesArray = data && data.length >=1 && data.flatMap(excution => excution.course);
  setCourses(coursesArray);
},[data])
    
  return (
    <>
      <section className="best-selling-section py-3 relative w-full my-5">
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline text-[#00a4e5]">الاكثر مبيعا</span>
            <h2 className="section-title__title">الكورسات الاكثر مبيعا</h2>
          </div>
            {/* <div className="" dir="rtl">
                <div className="relative col-sm-12 my-4">
                    <div className="relative flex items-center">
                    <div className="input-group flex rounded-md bg-slate-800 items-center search-area w-auto ">
                        <i className="fa-solid fa-magnifying-glass p-3"></i>
                        <input
                            type="text"
                            className="bg-transparent placeholder:text-blue-500"
                            placeholder="عدد العرض.. (رقم)"
                            onChange={(e) => {
                                setBestSellingCount(e.target.value)
                            }}
                            value={bestSellingCount}
                        />
                    </div>
                    </div>
                </div>
            </div> */}
          <Swiper
            className="parent-of-slides"
            {...params}
            slidesPerView={3}
            navigation
            modules={[Pagination , Navigation]}
            pagination = {{clickable : true}}
            spaceBetween={80}
            speed={1000}
          >
          
          {
            getExecutionsLoading
            ?
            <>
              <div className="row d-flex justify-content-center gap-2">
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" />
                <Spinner animation="grow" variant="warning" />
              </div>
            </>
            :
            (
              excutions.length >= 1
              ?
              excutions.map((excution , index) => {
                const {course , discount} = excution
                return (
                  <SwiperSlide
                    key={index}
                  >
                    <CardCourses
                        key={index}
                        teacher={course.user.name}
                        title={course.name}
                        excutionId={excution.id}
                        price={excution.price}
                        courseDiscount = {discount}
                        startPublishForAnyoneDate = {excution.start_date_publish}
                        endPublishForAnyoneDate = {excution.end_date_publish}
                        startPublishForStudentDate = {excution.start_date_student}
                        endPublishForStudentDate = {excution.end_date_student}
                        courseImg={course.image_url}
                        courseInstructor={course.user}
                        typeCourse={course.type}
                        courseStatus = {course.publish}
                        categoryName = {course.category && course.category.name && course.category.name}
                    />
                  </SwiperSlide>
                )
              })
              :
              <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                <img 
                  loading="lazy"
                  src={faildImg}
                  alt="faild img"
                  className="w-[150px] mb-3"
                />
                <p>
                  لا يوجد مبيعات حتي الان!
                </p>
              </div>
            )
          }
          </Swiper>

          <div className="relative flex justify-center
          items-center  mt-3">
            <Btn title={'استكشف المزيد'} link="courses" classes={"mt-3"} />
          </div>
        </div>
      </section>
    </>
  );
}
