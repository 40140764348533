import { Route, Router, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Courses from "./pages/Courses/Courses";
import Contact from "./pages/Contact/Contact";
import Profile from "./pages/Profile/Profile";
import Login from "./pages/Login/Login";
import Singup from "./pages/Singup/Singup";
import Error from "./pages/Error/Error";
import "animate.css";
import { useEffect, useState } from "react";
import Lessons from "./pages/Lessons/Lessons";
import Exam from "./pages/Exam/Exam";
//Vendors
import "./vendors/bootstrap/css/bootstrap.min.css";
import "./vendors/animate/animate.min.css";
import "./vendors/animate/custom-animate.css";
import "./vendors/fontawesome/css/all.min.css";
import "./vendors/odometer/odometer.min.css";
import "./vendors/swiper/swiper.min.css";
import "./vendors/icomoon-icons/style.css";
import "./vendors/tiny-slider/tiny-slider.min.css";
import "./vendors/reey-font/stylesheet.css";
import "./style/zilom.css";
import "./style/zilom_ar.css";
import "./style/zilom-responsive.css";
import academyLogo from"./images/academy-logo.png"
import Wrappers from "./component/ui/Wrappers";
import Footer from "./component/ui/Footer";
import ScrollUp from "./component/ui/ScrollUp";
import LessonQuizePage from "./pages/Lessons/component/lesson-quize/LessonQuizePage";
import NavBar4 from "./component/NavBar";
import LogOut from "./store/LogoutComponent";
import CustomCourseDetails from "./pages/Courses/component/ui/CustomCourseDetails";
import MyCoursesPage from "./pages/Courses/my-courses/MyCoursesPage";
import CourseQuizePage from "./pages/Lessons/component/course-quize-page/CourseQuizePage";
import AttemptPage from "./pages/Lessons/component/attempt-page/AttemptPage"
import Loading from "./component/loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import { getUiSettingsFunc } from "./store/slices/ui-settings-actions/GetUiSettingsSlicer";
import ContextApi from "./store/slices/Context";
import CartPage from "./pages/cart-page/CartPage";
import firstSliderImg from "./images/slide1.jpg"
import secondSliderImg from "./images/slide2.jpg"
import thirdSliderImg from "./images/slide3.jpg"
import RegisterPage from "./pages/register-page/RegisterPage";
import InvoicesPage from "./pages/invoices-page/InvoicesPage";
import ResetPassPage from "./pages/reset-pass-page/ResetPassPage";
import VerifyEmailPage from "./pages/verify-email-page/VerifyEmailPage";
import Swal from "sweetalert2";
import OverLayLoading from "./component/global/overlay-loading/OverLayLoading";
import { getVerifyEmailFunc } from "./store/slices/users/VerifyEmailSlicer";
import PaymentStatusPage from "./pages/payment-status-page/PaymentStatusPage";
import SingleLessonPage from "./pages/single-lesson-page/SingleLessonPage";
import FreeAttemptsPage from "./pages/Lessons/component/free-attemp-page/FreeAttemptsPage";
function App() {
  const userData = JSON.parse(window.localStorage.getItem("userdata"));
  const navigate = useNavigate();
  const location = useLocation();
  const nameLocation = location.pathname;
  const userId = userData && userData.data && userData.data.student && userData.data.student.id;
  const {loading:getVerifingEmailLoading} = useSelector(
    state => state.getVerifyEmailSlicer
  )
  const getVerifingEmail = async (apiLink , data) => {
    try {
      dispatch(getVerifyEmailFunc({api : apiLink , data}))
      .then(result => {
        console.log(result);
        if(result.payload.success === true) {
          navigate("/login");
        } 
      })
    }
    catch (error) {
      Swal.fire({
        title: "Oops!",
        text: error,
        icon: "warning"
      });
    }
  }

  const routes = [
    {
      path: "/",
      component: Home,
    },
    {
      path: "/about",
      component: About,
    },
    {
      path: "/reset-pass",
      component: ResetPassPage,
    },
    {
      path: "reset-password",
      component: ResetPassPage,
    },
    {
      path: "/login/reset-pass",
      component: ResetPassPage,
    },
    {
      path: "/contact",
      component: Contact,
    },
    {
      path: "/courses",
      component: Courses,
    },
    {
      path: "/cart/courses",
      component: Courses,
    },
    {
      path: "/courses/:excutionId",
      component: CustomCourseDetails,
    },
    {
      path: "/courses/:excutionId/single-lesson/:lessonId",
      component: SingleLessonPage,
    },
    {
      path: "/my-courses",
      component: MyCoursesPage,
    },
    {
      path: "/profile",
      component: Profile,
    },
    {
      path: "/cart/profile",
      component: Profile,
    },
    {
      path: "/Login",
      component: Login,
    },
    {
      path: "/register",
      component: RegisterPage,
    },
    {
      path: "/Singup",
      component: Singup,
    },
    {
      path: "/profile/invoices/:studentId",
      component: InvoicesPage,
    },
    {
      path: "/cart/profile/invoices/:studentId",
      component: InvoicesPage,
    },
    {
      path: "/lessons/:executionId",
      component: Lessons,
    },
    {
      path: "/exam/:executionId",
      component: Exam,
    },
    {
      path: "/cart",
      component: CartPage,
    },
    {
      path: "/callback",
      component: PaymentStatusPage,
    },
    {
      path: "/error",
      component: Error,
    },
    {
      path: "login/verify-email",
      component: VerifyEmailPage,
    },
    {
      path: "courses/:excutionId/lesson-quize-page/:lessonId",
      component: LessonQuizePage,
    },
    {
      path: "/lessons/lesson-quize-page/:lessonId",
      component: LessonQuizePage,
    },
    {
      path: "/lesson-quize-page/:lessonId",
      component: LessonQuizePage,
    },
    {
      path: "/lessons/:executionId/lesson-quize-page/:lessonId",
      component: LessonQuizePage,
    },
    {
      path: "/course-quize-page/:courseId",
      component: CourseQuizePage,
    },
    {
      path: "/lessons/:executionId/course-quize-page/:courseId",
      component: CourseQuizePage,
    },
    {
      path: "/lessons/:executionId/attemp-page/:attempId",
      component: AttemptPage,
    },
    {
      path: "/attemp-page/:attempId",
      component: AttemptPage,
    },
    {
      path: "/courses/:excutionId/lesson-attempts-page/:lessonId",
      component: FreeAttemptsPage,
    },
    {
      path: "/courses/:excutionId/attemp-page/:attempId",
      component: AttemptPage,
    },
    {
      path: "/courses/:excutionId/lesson-attempts-page/:lessonId/attemp-page/:attempId",
      component: AttemptPage,
    },
    {
      path: "*",
      component: Error,
    },
  ];


  const defaultSettings =  {
    generalSetting: {
      id: 1,
      platform_name: "الأوائل",
      emails: [
        "info@company.com",
        "test@company.com"
      ],
      phones: [
        "92 666 888 0000",
        "92 666 888 3232"
      ],
      addresses: [
        "88 broklyn street, USA",
        "90 broklyn street, USA"
      ],
      about: "مرحبًا بك في منصتنا التعليمية، حيث نقدم لك فرصة فريدة لاكتساب المعرفة وتحقيق النجاح الأكاديمي. من خلال مجموعة متنوعة من الدروس والموارد التعليمية عبر الإنترنت، نسعى جاهدين لتوفير بيئة تعليمية محفزة وملهمة تمكنك من تحقيق أهدافك التعليمية بسهولة ويسر. انضم إلينا اليوم وابدأ رحلتك في عالم المعرفة والتعلم الشيقة.",
      more_about: "  مرحبًا بك في منصتنا التعليمية المبتكرة، حيث يجتمع التعليم التقليدي بالتكنولوجيا الحديثة لتوفير تجربة تعليمية مثيرة ومميزة. نحن نؤمن بأن التعلم يجب أن يكون ممتعًا وملهمًا، وهذا هو السبب في تصميم منصتنا لتلبية احتياجات كل متعلم وتحفيزه لتحقيق أقصى إمكاناته.ز منصتنا بمحتوى تعليمي ذو جودة عالية، وتفاعلية مبتكرة، ودعم فني متميز لضمان تجربة تعلم فريدة وفعالة. سواء كنت طالبًا جديدًا أو محترفًا في مجالك، فإننا نعمل جاهدين لتوفير بيئة تعليمية تحفزك على استكشاف المعرفة وتطوير مهاراتك بكل ثقة.",
      students_session_duration: 525600,
      users_session_duration: 525600,
      created_at: "2024-03-01T12:52:05.000000Z",
      updated_at: "2024-03-01T12:52:05.000000Z",
      platform_logo_url: academyLogo
    },
    sliderImages: [
      {
      id: 1,
      title: "استمتع برحلة التعلم",
      description: "اكتشف متعة التعلم الذاتي والتطوير",
      link: "https://alawa2el.com/courses",
      created_at: "2024-03-01T12:52:05.000000Z",
      updated_at: "2024-03-01T12:52:05.000000Z",
      image_url: firstSliderImg,
      mobile_image_url: "https://test-apis.alawa2el.com/storage/questions/exam.jpg"
      },
      {
      id: 2,
      title: "اختر التعلم",
      description: "اجعل التعلم جزءًا من حياتك<br>استمتع بمغامرة تعلم ممتعة.",
      link: "https://alawa2el.com/courses",
      created_at: "2024-03-01T12:52:05.000000Z",
      updated_at: "2024-03-01T12:52:05.000000Z",
      image_url: secondSliderImg,
      mobile_image_url: "https://test-apis.alawa2el.com/storage/questions/exam.jpg"
      },
      {
        id: 3,
        title: "منصة التعلم الرائدة",
        description: "تجربة فريدة تنقلك بعيدًا عن<br> العادي إلى التميز",
        link: "https://alawa2el.com/courses",
        created_at: "2024-03-01T12:52:05.000000Z",
        updated_at: "2024-03-01T12:52:05.000000Z",
        image_url: thirdSliderImg,
        mobile_image_url: "https://test-apis.alawa2el.com/storage/questions/exam.jpg"
      }
    ]
  }
  const [loadingDone , setLoadingDone] = useState();
  const [uiSettings , setUiSettings] = useState(defaultSettings);
  const global_data = {
    mainApi : process.env.REACT_APP_PUBLIC_API,
    platformData  : uiSettings 
  }
  
  const {loading:getUiSettingsLoading , data} = useSelector(
    state => state.getUiSettingsSlicer
  )
  const dispatch = useDispatch();
  const uiSettingsApi = `${global_data.mainApi}/api/settings`;
  useEffect(() => {
    dispatch(getUiSettingsFunc(uiSettingsApi))
  },[])
  useEffect(() => {
    data && setUiSettings(data);
  },[data])
  useEffect(() => {
    !getUiSettingsLoading && setTimeout(() => setLoadingDone(true) , 2000)
  },[])

  useEffect(() => {
    const maniURL = window.location.href;
    const locationUrl = new URL(maniURL);
    const RegHash = /verify-email\/\d+\/(\w+)/;
    const match = maniURL.match(RegHash);
    let hash;
    if (match) {
      hash = match[1];
    }
    const hashVerifyUrl = locationUrl.searchParams.get("email_verify_url");
    if(hashVerifyUrl) {
      const hashVerifyUrlSearch = new URL(hashVerifyUrl);
      const expires = hashVerifyUrlSearch.searchParams.get("expires")
      const signature = locationUrl.searchParams.get("signature");
      const data = {
        expires ,
        signature,
      }
      const getVerifingEmailApi = `${global_data.mainApi}/api/verify-email/${userId}/${hash}`;
      getVerifingEmail(getVerifingEmailApi , data)
    }
  },[])

  useEffect(() => {
    let resetPassToken;
    const maniURL = window.location.href;
    const regResetPassToken = /token=([^&]+)/;
    // const regResetPassToken = /token=.*/;
    const match = maniURL.match(regResetPassToken);
    if (match) {
      resetPassToken = match[1];
    }
    if(maniURL.includes("token")) {
      console.log("has reset token");
    }else {
      console.log("no token");
    }
  },[])

  return (
    <>
      {      
      (loadingDone && data && global_data.platformData)
      ?
      <>
      <ContextApi.Provider value={uiSettings}>
        <Wrappers>
          {
            nameLocation !== "/login" 
            &&
            nameLocation !== "/register" 
            &&
            nameLocation !== "/reset-pass"
            &&
            nameLocation !== "/reset-password"
            &&
            nameLocation !== "/login/verify-email"
            &&
            // nameLocation !== "/contact"
            // &&
            nameLocation !== "/callback"
            &&
            nameLocation !== "/lessons/lesson-quize-page/:lessonId"
            &&
            nameLocation !== "/courses/:excutionId/lesson-quize-page/:lessonId"
            &&
            nameLocation !== "/lesson-quize-page/:lessonId"
            &&
            <NavBar4/>
          }
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} exact path={`/${route.path}`} Component={route.component} />
              ))}
          </Routes>
          {
            userData
            &&
            <LogOut/>
          }
          {
            nameLocation !== "/login" 
            &&
            nameLocation !== "/register" 
            &&
            nameLocation !== "/reset-pass"
            &&
            nameLocation !== "/reset-password"
            &&
            nameLocation !== "/login/verify-email"
            &&
            // nameLocation !== "/contact"
            // &&
            nameLocation !== "/callback"
            &&
          <Footer />
          }
        </Wrappers>
      </ContextApi.Provider>
        <ScrollUp />
      </>
      :
      <Loading/>
      }

      <OverLayLoading loading={getVerifingEmailLoading} loadingtext={"جاري تاكيد البريد الالكتروني.."} />
    </>
  );
}

export default App;
