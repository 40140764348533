import { Link } from "react-router-dom"
import quizIcon from "../../../../images/quiz.png";
import fileIcon from "../../../../images/google-docs.png";
import pdfIcon from "../../../../images/file.png";
import rich_textIcon from "../../../../images/files.png";
import freeWatchIcon from "../../../../images/audiobook.png";
import { useDispatch } from "react-redux";
import { postCompletingLessonFunc } from "../../../../store/slices/lessons/PostCompletingLessonSlicer";
import Swal from "sweetalert2";
import downloadLessonFile from "../../../../component/global/global-functions/downloadLessonFile";
import OverLayLoading from "../../../../component/global/overlay-loading/OverLayLoading";
import { useState } from "react";
import axios from "axios";
import DownloadingProgress from "../../../../component/global/global-functions/DownloadingProgress";
function SingleFreeLessonBtn({ lessonType , lessonId , loading , getFreeLesson , startDate , uniqStartDate , setLessonIdFunc , downloadFunc}) {
  const postCompletingLessonApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}/complete`;
  const dispatch = useDispatch();
  const dateNow = new Date();
  const [downloadFileLoading , setDownloadFileLoading] = useState();
  const postCompletingLesson = () => {
    dispatch(postCompletingLessonFunc(postCompletingLessonApi))
  }
  const checkLessonSchualde = (getLessonFunc) => {
    if(startDate > dateNow) {
      Swal.fire({
        title: "عذرا!",
        text: "هذا الدرس غير متاح في الوقت الحالي!",
        icon: "warning"
      });
    }else {
      getLessonFunc();
      setLessonIdFunc();
    }
  }
  // const globalLessonsApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons`;
  // const [downloadingFilePercent , setDownloadingFilePercent] = useState(0);


  // const downloadLessonFileFunc = () => {
  //   try {
  //     // if (handleLoading && typeof handleLoading === 'function') {
  //     //     handleLoading()
  //     // }
  //     axios.get(`${globalLessonsApi}/${lessonId}/download-file` , {
  //         withCredentials : true,
  //         responseType: 'blob', // Important: Set response type to blob
  //         onDownloadProgress : (ProgressEvent) => {
  //           // console.log(ProgressEvent);
  //           setDownloadingFilePercent(((ProgressEvent.loaded / ProgressEvent.total) * 100).toFixed())
  //         }
  //     })
  //     .then(response => {
  //         const url = window.URL.createObjectURL(new Blob([response.data]));
  //         const link = document.createElement('a');
  //         link.href = url;
  //         if(response.data && response.data.type) {
  //         if (response.data.type === "application/pdf") {
  //             link.setAttribute('download', `${lessonName}.pdf`);
  //         } else {
  //             link.setAttribute('download', `${lessonName}.zip`);
  //         }
  //         document.body.appendChild(link);
  //         link.click();
  //         window.URL.revokeObjectURL(url);
  //         Swal.fire({
  //             title: "تم بنجاح!",
  //             text: "!تم تنزيل الملف بنجاح",
  //             icon: "success"
  //         });
  //         setDownloadingFilePercent(0)
  //         setTimeout(() => window.location.reload() , 1000)
  //         }
  //     })
  //     }
  //     catch(error) {
  //     Swal.fire({
  //         title: "عذرا!",
  //         text: error,
  //         icon: "error"
  //     })
  // }
  // }





  const checkFreeLessonBtn = () => {
      if(lessonType) {
        switch (lessonType) {
          case "video":
            return (
              <>
                {
                  startDate > dateNow
                  &&
                  <p className="flex items-center mb-0 mr-1 text-sm">{`متاح في ${uniqStartDate}`}</p>
                }
                <button className={`relative flex justify-center items-center bg-[#303F9F] text-white py-2 px-3 rounded-sm text-sm transition-all duration-300 hover:translate-y-[-4px] group my-3 mr-2 ${startDate && startDate > dateNow ? "pointer-events-none opacity-70" : ""}`}
                onClick={() => {
                  checkLessonSchualde(() => getFreeLesson(lessonId))
                }}
                >
                  <img loading="lazy" src={freeWatchIcon} className="relative w-[20px] mr-2" alt="show" />
                  <p className="mr-1">
                    {
                      loading
                      ?
                      "جاري التحميل.."
                      :
                      (
                        startDate > dateNow
                        ?
                        "غير متاح"
                        :
                        "شاهد مجانا"
                      )
                    }
                  </p>
                </button>
              </>
            )
          case "quiz":
            return (
              <>
                {
                  startDate > dateNow
                  &&
                  <p className="flex items-center mb-0 mr-1 text-sm">{`متاح في ${uniqStartDate}`}</p>
                }
                <>
                  <Link
                  to={`lesson-attempts-page/${lessonId}`}
                  className={`relative flex justify-center items-center bg-[#303F9F] text-white py-2 px-3 rounded-sm text-sm transition-all duration-300 hover:translate-y-[-4px] group my-3 mr-2 ${loading ? "pointer-events-none opacity-70" : ""} ${startDate && startDate > dateNow ? "pointer-events-none opacity-70" : ""}`}
                  
                  >
                    <img loading="lazy" src={quizIcon} className="relative w-[20px] mr-2" alt="show" />
                    <p className="mr-1">
                      عرض المحاولات
                    </p>
                  </Link>
                  <Link
                  to={`lesson-quize-page/${lessonId}`}
                  className={`relative flex justify-center items-center bg-[#303F9F] text-white py-2 px-3 rounded-sm text-sm transition-all duration-300 hover:translate-y-[-4px] group my-3 mr-2 ${loading ? "pointer-events-none opacity-70" : ""} ${startDate && startDate > dateNow ? "pointer-events-none opacity-70" : ""}`}
                  
                  >
                    <img loading="lazy" src={quizIcon} className="relative w-[20px] mr-2" alt="show" />
                    <p className="mr-1">
                      {
                        startDate > dateNow
                        ?
                        "غير متاح"
                        :
                        "امتحن مجانا "
                      }
                    </p>
                  </Link>
                </>
              </>
            )
          case "pdf":
            return (
              <>
                {
                  startDate > dateNow
                  &&
                  <p className="flex items-center mb-0 mr-1 text-sm">{`متاح في ${uniqStartDate}`}</p>
                }
                <button
                className={`relative flex justify-center items-center bg-[#303F9F] text-white py-2 px-3 rounded-sm text-sm transition-all duration-300 hover:translate-y-[-4px] group my-3 mr-2 ${startDate && startDate > dateNow ? "pointer-events-none opacity-70" : ""} ${downloadFileLoading ? "pointer-events-none opacity-70" : ""}`}
                // onClick={
                //   () => checkLessonSchualde(() => downloadLessonFile(downloadLessonFileApi , () => postCompletingLesson() , () => startDownloadLoading() , () => endDownloadLoading()))
                // }
                onClick={() => {
                  downloadFunc()
                }}
                >
                  <img loading="lazy" src={pdfIcon} className="relative w-[20px] mr-2" alt="show" />
                  <p className="mr-1">
                    {
                      startDate > dateNow
                      ?
                      "غير متاح"
                      :
                      (
                        downloadFileLoading
                        ?
                        "جاري التنزيل.."
                        :
                        "حمل مجانا "
                      )
                    }
                  </p>
                </button>
              </>
            )
          case "file":
            return (
              <>
                {
                  startDate > dateNow
                  &&
                  <p className="flex items-center mb-0 mr-1 text-sm">{`متاح في ${uniqStartDate}`}</p>
                }
                <button
                className={`relative flex justify-center items-center bg-[#303F9F] text-white py-2 px-3 rounded-sm text-sm transition-all duration-300 hover:translate-y-[-4px] group my-3 mr-2 ${startDate && startDate > dateNow ? "pointer-events-none opacity-70" : ""} ${downloadFileLoading ? "pointer-events-none opacity-70" : ""}`}
                // onClick={
                //   () => checkLessonSchualde(() => downloadLessonFile(downloadLessonFileApi , () => postCompletingLesson() , () => startDownloadLoading() , () => endDownloadLoading()))
                // }
                onClick={() => {
                  downloadFunc()
                }}
                >
                  <img loading="lazy" src={fileIcon} className="relative w-[20px] mr-2" alt="show" />
                  <p className="mr-1">
                    {
                      startDate > dateNow
                      ?
                      "غير متاح"
                      :
                      (
                        downloadFileLoading
                        ?
                        "جاري التنزيل.."
                        :
                        "حمل مجانا "
                      )
                    }
                  </p>
                </button>
              </>
            )
          case "rich_text":
            return (
              <>
                {
                  startDate > dateNow
                  &&
                  <p className="flex items-center mb-0 mr-1 text-sm">{`متاح في ${uniqStartDate}`}</p>
                }
                <Link
                to={`single-lesson/${lessonId}`}
                className={`relative flex justify-center items-center bg-[#303F9F] text-white py-2 px-3 rounded-sm text-sm transition-all duration-300 hover:translate-y-[-4px] group my-3 mr-2 ${startDate && startDate > dateNow ? "pointer-events-none opacity-70" : ""}`}
                >
                  <img loading="lazy" src={rich_textIcon} className="relative w-[20px] mr-2" alt="show" />
                  <p className="mr-1">
                  {
                      startDate > dateNow
                      ?
                      "غير متاح"
                      :
                      "شاهد مجانا "
                    }
                  </p>
                </Link>
              </>
            )
            default :
              console.log("no type")
        }
      }
  }
  return (
      <>
        {checkFreeLessonBtn()}
      </>
  )
}

export default SingleFreeLessonBtn;