import { Link } from "react-router-dom";

export default function Btn({title,link,classes , action}) {
  return (
    <>
      {/* <Link to={link} className={`thm-btn bg-[#00a4e5] ${classes && classes}`} >
       {title}
      </Link> */}
      <Link to={link} type="Link" class={`inline-block px-6 py-3 mr-3 font-bold text-center text-white uppercase align-middle transition-all duration-500 rounded-lg cursor-pointer bg-gradient-to-tl from-[#0a3763] to-[#489dec] leading-pro text-xs ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 hover:scale-110 hover:rotate-2 hover:bg-pink-700 hover:text-pink-200 hover:shadow-lg active:opacity-85 ${classes && classes}`}
      onClick={() => {
        action && action()
      }}
      >{title}</Link>
    </>
  );
}
