import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ShowMultibleImagesModal(props) {
    const {images , title} = props;
    const [mainImg , setMainImg] = useState();
    useEffect(() => {
        images && setMainImg(images[0])
    },[images])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='z-[99999]'
    >
      <Modal.Header className='relative flex justify-end items-center'>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='image-modal relative w-full flex justify-center items-center'>
            <img loading="lazy" src={mainImg} alt="img modal" className='relative' />
        </div>
        <div className='images-div relative flex my-2 w-full justify-center items-center'>
            {
                images && images.length >= 1 
                ?
                images.map((img , index) => {
                    return (
                        <div key={index} className='img-div relative w-[100px] h-[70px] rounded-md overflow-hidden cursor-pointer mr-2'
                            onClick={()=>setMainImg(img)}
                        >
                            <img loading="lazy" src={img} alt='img' className='absolute top-0 left-0 w-full h-full transition-all duration-500 hover:scale-110 hover:rotate-[3px]' />
                        </div>
                    )
                })
                :
                <p>لا يوجد صور اخري</p>
            }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className='bg-primary'>اغلاق</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ShowMultibleImagesModal