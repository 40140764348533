import Features from "./component/Features";
import About from "./component/About";
import Registeration from "./component/Registeration";
import Partners from "../../component/ui/Partners";
import Whyus from "./component/Whyus";
import SwiperSlider from "./component/SwiperSlider";
import BestSellingSection from "./component/BestSelligSection";

export default function Home() {
  // const [loadingDone , setLoadingDone] = useState();
  // const checkloading = () => {
  //   window.onload = () => setLoadingDone(true)
  // }
  // checkloading()
  // useEffect(()=>{
  //   window.scrollTo(0,0);
  //   setTimeout(() => setLoadingDone(true) , 2000)
  // },[])
  return (
      <>
          <SwiperSlider />
          <Features />
          <About />
          <BestSellingSection/>
          <Registeration />
          <Partners />
          <Whyus />
      </>
  )
}
