// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Pagination,
  Autoplay,
} from "swiper/modules";

import "swiper/css/pagination";
import '../styles/swiper-style.css'
import Btn from "../../../component/ui/Btn";
import { useContext } from "react";
import ContextApi from "../../../store/slices/Context";


export default function SwiperSlider() {
  const contextApi = useContext(ContextApi);
  const slidersList = contextApi && contextApi.sliderImages
  
  return (
    <>
    <section className="main-slider main-slider-two">
      <Swiper
        modules={[ Pagination, Autoplay]}
        effect="fade"
        slidesPerView={1}
        loop={true}
        speed={1000}
        pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
      >
        {
          slidersList
          &&
          slidersList.map((slider , index) => {
            return (
              <SwiperSlide key={index}>
                <div>
                  <div
                    className="image-layer"
                    style={{
                      backgroundImage: `url(${slider.image_url})`
                    }}
                  />
                  {/* <div className="image-layer-overlay" /> */}
                  <div className="container">
                    <div className="row flex justify-center items-center">
                      <div className="col-sm-12 col-md-6">
                        <div className="main-slider-two__content text-center">
                          <h2 className="main-slider-two__tagline font-bold text-slate-200 sm:text-[30px] md:text-[40px]">
                            {slider.title}
                          </h2>
                          <div 
                          className="main-slider__title sm:text-[20px] md:text-[40px]" 
                          dangerouslySetInnerHTML={{__html : slider.description}}  />
                        </div>
                        <div className="main-slider-two__button-box text-center">
                          <Btn title={'استكشف المزيد'} link={slider.link} classes={"mt-3"} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          }
          )
        }
      </Swiper>
      </section>
    </>
  );
}
