import { Link, useLocation } from "react-router-dom"
import "./Nav/custom-nav.css"
import platformLogoImg from "../images/1.png"
import { useContext, useEffect, useState } from "react";
import studentImg from "../images/graduated.png"
import { logout } from "../services/logoutAction";
import { Dropdown, DropdownButton, Modal } from "react-bootstrap";
import MobileNavBar from "./Nav/MobileNaveBar";
import CustomSearchOverLay from "./ui/search-overlay/CustomSearchOverLay";
import { useDispatch, useSelector } from "react-redux";
import { readAllNotficationsFunc } from "../store/slices/notfications-slicers/ReadAllNotficationsSlicer";
import NotficationsList from "./NotficationsList";
import { getNotficationsFunc } from "../store/slices/notfications-slicers/GetNotficationsSlicer";
import ContextApi from "../store/slices/Context";
import { getUiSettingsFunc } from "../store/slices/ui-settings-actions/GetUiSettingsSlicer";
import { getCartFunc } from "../store/slices/cart-actions/GetCartSlicer";
import Swal from "sweetalert2";

function LargModal({lgShow , setLgShow}) {
    return (
      <>
        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow()}
          aria-labelledby="example-modal-sizes-title-lg"
          style={{
            backgroundColor: "none",
            border: "none",
          }}
          className="z-[999999999999]"
        >
          <Modal.Body>
            <div className="search-popup__content">
              <form action="#">
                <label htmlFor="search" className="sr-only">
                  search here
                </label>
                {/* /.sr-only */}
                <input type="text" id="search" placeholder="Search Here..." />
                <button
                  type="submit"
                  aria-label="search submit"
                  className="thm-btn2"
                >
                  <i className="fa fa-search" aria-hidden="true" />
                </button>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
function NavBar4() {
    const user = JSON.parse(window.localStorage.getItem("userdata"));
    const userImg = user && user.data.student.image_url;
    const expire_date = user && user.data.expiry_token;
    const location = useLocation();
    const nameLocation = location.pathname;
    const [lgShow , setLgShow] = useState(false);
    const [showMobileNav , setShowMobileNav] = useState();
    const [showSearch , setShowSearch] = useState();
    const [showNotficationsList , setShowNotficationsList] = useState(false);
    const dispatch = useDispatch();
    const notficationsApi = `${process.env.REACT_APP_PUBLIC_API}/api/notifications`;
    const cartApi = `${process.env.REACT_APP_PUBLIC_API}/api/carts`;
    const {data} = useSelector(state => state.getNotficationSlicer);
    const {loading:readAllNotsLoading} = useSelector(state => state.readAllNotficationsSlicer);
    const [unReadNotficationsCount , setUnReadNotficationsCount] = useState(0);
    const [readNotfications , setReadNotfications] = useState([]);
    const [mounted , setMounted] = useState();
    let lastDate = new Date(expire_date).getTime();
    let [days , setDays] = useState();
    let [hours , setHours] = useState();
    let [minutes , setMinutes] = useState();
    let [seconds , setSeconds] = useState();
    const contextApi = useContext(ContextApi)
    const platformLogo = contextApi && contextApi.generalSetting && contextApi.generalSetting.platform_logo_url
    const emails = contextApi && contextApi.generalSetting && contextApi.generalSetting.emails
    const phones = contextApi && contextApi.generalSetting && contextApi.generalSetting.phones
    const {loading:getCartDataLoading , data:cartData} = useSelector(
        state => state.getCartSlicer
    )
    const [cartList , setCartList] = useState([]);
    const getNotfications = async () => {
        dispatch(getNotficationsFunc(notficationsApi))
    }
    useEffect(() => {
        if(user && expire_date){
            getNotfications()
        }
    },[])
    useEffect(() => {
        data && data.read_notifications && setReadNotfications(data.read_notifications)
        data && data.unread_notifications_count && setUnReadNotficationsCount(data.unread_notifications_count)
    },[data])
    useEffect(() => {
        let scrollHandler = () => {
            if (window.scrollY >= 50) {
                document.querySelector(".customNavbar").classList.add("scrolling");
            } else {
                document.querySelector(".customNavbar").classList.remove("scrolling");
            }
        };
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, []);

    const showMobileNavFunc = () => {
        setShowMobileNav(true)
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            if(lastDate) {
                const currentTime = Date.now();
                const remainingTime = lastDate - currentTime;
                if (remainingTime <= 1000) {
                    logout();
                    clearInterval(intervalId);
                }
                // const minutes = Math.floor(remainingTime / (60 * 1000));
                const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
                const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
                const d = Math.floor((remainingTime / (1000 * 60 * 60 * 24)));
                const h = Math.floor(remainingTime % (1000 * 60 * 60 * 24) / (1000 * 60 * 60));
                setDays(d);
                setHours(h);
                setMinutes(minutes);
                setSeconds(seconds);
            }
        }, 2000);
    
        return () => clearInterval(intervalId);
    }, []);
    const readAllNotfications = async () => {
        try {
            dispatch(readAllNotficationsFunc(notficationsApi))
            .then(result => {
                if(result.payload.success === true) {
                    setUnReadNotficationsCount(0);
                    getNotfications()
                }
            })
        }
        catch (error) {
            Swal.fire({
                title: "عذرا!",
                text: error,
                icon: "warning"
            });
        }
    }
    useEffect(() => {
        setTimeout(() => setMounted(true),200)
    },[])

    const getCartData = () => {
        try {
            dispatch(getCartFunc(cartApi))
        }
        catch (error) {
            Swal.fire({
                title: "عذرا!",
                text: error,
                icon: "warning"
            });
        }
    }
    useEffect(() => {
        if(nameLocation !== "/login" && user && expire_date) {
            getCartData()
        }
    },[nameLocation])
    useEffect(() => {
        cartData && setCartList(cartData)
    },[cartData])


    useEffect(() => {
        const allLinks = Array.from(document.getElementsByClassName("nav-link"));
        if(allLinks) {
            allLinks.forEach((link) => {
                link.addEventListener("mouseover" , () => {
                    allLinks.forEach((link) => {
                        link.style.opacity = .3
                    })
                    link.style.opacity = 1
                })
            })
            allLinks.forEach((link) => {
                link.addEventListener("mouseleave" , () => {
                    allLinks.forEach((link) => {
                        link.style.opacity = 1
                    })
                })
            })
        }
    },[])
    return (
        <>
            <div className={`transition-all sm:py-3 lg:py-0 duration-500 customNavbar fixed top-0 left-0 w-full z-[99999]
            `}>
                {
                    emails && phones
                    &&
                    <div className="contacts-nav transition-all duration-500 relative w-full bg-white mb-1 sm:hidden lg:flex">
                        <div className="relative contacts-div flex items-center justify-end w-[90%] mx-auto py-2">
                            <div className={`relative flex items-center mr-2 ${mounted ? "translate-y-0 opacity-100" : "translate-y-2 opacity-0"} transition-all duration-300`}>
                                <Link className="m-0 text-[#2196f3]" to={`tel:${phones[0]}`}>
                                    {phones[0]}
                                    <i className="fa-solid fa-phone-volume mr-1"></i>
                                </Link>
                            </div>
                            <div className={`relative flex items-center mr-2 ${mounted ? "translate-y-0 opacity-100" : "translate-y-2 opacity-0"} transition-all duration-700`}>
                                <Link className="m-0 text-[#2196f3]" to={`mailto:${emails[0]}`}>
                                    {emails[0]}
                                    <i className="fa-solid fa-envelope mr-1"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
                <div className="nav-container relative w-[90%] mx-auto flex justify-between items-center">
                <i className="fa-solid fa-list-ul togglerListIcon transition-all duration-500 flex lg:hidden flex-col justify-center items-center cursor-pointer w-6 h-5 relative text-slate-800 text-xl" onClick={()=>{
                    showMobileNavFunc()
                    }}></i>
                    <div className={`logo-and-links-in-large-screen sm1:hidden lg:flex relative items-center`}>
                        
                        <Link to="/" className={`transition-all duration-500 ${mounted ? "translate-x-0 opacity-100" : "translate-x-[10px] opacity-0"}`}>
                            <img loading="lazy" className="platform-logo w-[120px]" alt="logo" src={platformLogo ? platformLogo : platformLogoImg} />
                        </Link>
                        <ul className="flex links-list flex-col lg:flex-row py-2">
                            <li className={`relative text-center lg:mx-3 navbar-link ${mounted ? "active" : ""}`} style={{"--i" : "1.6s"}}>
                                <Link className={`block py-1 text-stone-50 nav-link transition-all duration-500 hover:translate-y-[-3px]`} to="/" onClick={()=>{
                                }}>الرئيسية</Link>
                            </li>
                            <li className={`relative text-center lg:mx-3 navbar-link ${mounted ? "active" : ""}`} style={{"--i" : "1.3s"}}>
                                <Link className={`block py-1 text-stone-50 nav-link transition-all duration-500 hover:translate-y-[-3px]`} to="/about" onClick={()=>{
                                }}>عنا</Link>
                            </li>
                            <li className={`relative text-center lg:mx-3 navbar-link ${mounted ? "active" : ""}`} style={{"--i" : "1s"}}>
                                <Link className={`block py-1 text-stone-50 nav-link transition-all duration-500 hover:translate-y-[-3px]`} to="/courses" onClick={()=>{
                                }}>المواد الدراسية</Link>
                            </li>
                            {
                                // (nameLocation !== "/login" && user && expire_date)
                                // &&
                                // <li className={`relative text-center lg:mx-3 navbar-link ${mounted ? "active" : ""}`} style={{"--i" : "1s"}}>
                                //     <Link className={`block py-1 text-stone-50 nav-link transition-all duration-500 hover:translate-y-[-3px]`} to="/cart" onClick={()=>{
                                //     }}> سلة الشراء </Link>
                                // </li>
                                // <li className={`relative text-center lg:mx-3 navbar-link ${mounted ? "active" : ""}`} style={{"--i" : "1s"}}>
                                //     <Link className={`block py-1 text-stone-50 nav-link transition-all duration-500 hover:translate-y-[-3px]`} to="/my-courses" onClick={()=>{
                                //     }}> كورساتي </Link>
                                // </li>
                            }
                            <li className={`relative text-center lg:mx-3 navbar-link ${mounted ? "active" : ""}`} style={{"--i" : "1s"}}>
                                <Link className={`block py-1 text-stone-50 nav-link transition-all duration-500 hover:translate-y-[-3px]`} to="/contact" onClick={()=>{
                                }}>تواصل معنا</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="profile-icon-and-search flex items-center">
                        {
                            nameLocation !== "/login"
                            &&
                            <i className="fa-solid fa-bell relative text-stone-50 ml-4 cursor-pointer transition-all duration-500 hover:translate-y-[-4px]"
                                onClick={()=>{
                                    readAllNotfications()
                                    setShowNotficationsList(!showNotficationsList)
                                }}
                            >
                                {
                                    parseInt(unReadNotficationsCount) >= 1
                                    &&
                                    <span className="absolute top-[-5px] right-[-5px] w-[10px] h-[10px] text-white bg-red-500 flex justify-center items-center rounded-[50%] p-2 text-[10px]">{unReadNotficationsCount}</span>
                                }
                            </i>
                        }
                        {
                            (nameLocation !== "/login" && user && expire_date)
                            &&
                            <Link 
                                className="relative text-stone-50 p-1 ml-4 transition-all duration-500 hover:translate-y-[-4px]"
                                to="/cart"
                            >
                                <i class="fa-solid fa-cart-shopping"></i>
                                {
                                    cartList.length >= 1
                                    &&
                                    <span className="absolute top-[-4px] py-[2px] px-[6px] text-white rounded-[50%] bg-red-500 right-[-2px] text-[10px]">{cartList.length}</span>
                                }
                            </Link>
                        }
                        <i className="fa-solid fa-magnifying-glass cursor-pointer text-stone-50 ml-4 z-[99] transition-all duration-500 hover:translate-y-[-4px]"
                            onClick={() => {
                                setShowSearch(true)
                            }}
                        ></i>

                        {
                            nameLocation !== "/login"
                            &&
                            <DropdownButton id="dropdown-basic-button" title="" className="bg-slate-800 rounded-md outline-none border-none show-session-timer sm:my-3 lg:m-0">
                                <img loading="lazy" src={userImg !== null ? userImg : studentImg} className="w-[70px] mx-auto" alt="user img"/>
                                {
                                    user
                                    ?
                                    <>
                                        <Dropdown.Item className="my-1 text-right">
                                            <ul className="flex">
                                                <li className="ml-1">الجلسه تنتهي بعد</li>
                                                <li className={`${minutes < 1 ? "text-red-500" : ""}`}>{seconds >= 10 ? seconds : `0${seconds}`}ث</li>
                                                :
                                                <li>{minutes >= 10 ? minutes : `0${minutes}`}د</li>
                                                :
                                                <li>{hours >= 10 ? hours : `0${hours}`}س</li>
                                                :
                                                <li>{days >= 10 ? days : `0${days}`}ي</li>
                                            </ul>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="my-1 text-right">
                                            <i className="fa-solid fa-user ml-1"></i>
                                            <Link to="/profile">الملف الشخصي</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="my-1 text-right"
                                            onClick={()=>logout()}
                                            >
                                            <i className="fa-solid fa-arrow-right-to-bracket ml-1"></i>
                                            <Link to="/login">تسجيل الخروج</Link>
                                        </Dropdown.Item>
                                    </>
                                    :
                                    <>
                                        <Dropdown.Item className="my-1 text-right">
                                            <i className="fa-solid fa-arrow-right-to-bracket ml-1"></i>
                                            <Link to="/login">تسجيل الدخول</Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="my-1 text-right">
                                            <i className="fa-solid fa-user-plus ml-1"></i>
                                            <Link to="/register">انشاء حساب</Link>
                                        </Dropdown.Item>
                                    </>
                                }
                            </DropdownButton>
                        }
                    </div>
                </div>
            </div>
            {
                showMobileNav
                &&
                <MobileNavBar
                    showMobileNav={showMobileNav}
                    hideShowMobileNav={()=>setShowMobileNav(false)}
                />
            }
            <LargModal
                lgShow={lgShow}
                setLgShow = {() => setLgShow(false)}
            />
            <NotficationsList
                show = {showNotficationsList}
                onHide = {()=>setShowNotficationsList(false)}
                notficationsLoading = {readAllNotsLoading}
                notfications = {readNotfications}
            />
            <CustomSearchOverLay showSearch={showSearch} hideSearch={() => setShowSearch(false)}/>
        </>
    )
}
export default NavBar4