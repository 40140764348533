import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../checkErrorsFunction";

export const postCompletingLessonFunc = createAsyncThunk("lessons/show", async (api) => {
  try {
    const response = await axios.post(api, {} , {
      withCredentials: true,
    });
      // if(response.data.success === false && response.data.message === "Unauthenticated.") {
      //   window.location.href = "/login";
      // }else{
      //   return response.data;
      // }
      // checkErrorsFunctions(response , response.data.errors);
      return response.data;
  } catch (error) {
    console.log(error)
  }
});

const postCompletingLessonSlicer = createSlice({
  name: "lessons",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(postCompletingLessonFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(postCompletingLessonFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(postCompletingLessonFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.data = action.payload
    });
  },
});

export default postCompletingLessonSlicer.reducer;
