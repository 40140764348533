
import PageHeader from "../../component/ui/PageHeader";
import CoursesShow from "./component/ui/CoursesShow";

export default function AllCourses() {


  


  return (
    <>
      {/* <PageHeader title="الكورسات" /> */}
      <CoursesShow />
    </>
  );
}
