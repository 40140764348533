import { useEffect, useState } from "react";
import OdometerCustom from "../../../component/ui/OdometerCustom";
import CountersBg from "../../../images/backgrounds/Counters-bg.png";
import { useDispatch, useSelector } from "react-redux";
import "../about.css";
import { getTeachersFunc } from "../../../store/slices/teachres/GetTeachresSlicer";
import { coursesApis, fetchAllCoursesFunc } from "../../../store/slices/courses/coursesSlicer";
import { Spinner } from "react-bootstrap";
export default function Counter() {
  const [teachersList , setTeachersList] = useState([]);
  const [coursesList , setCoursesList] = useState([]);
  const [loading ,setLoading] = useState();
  const teachersApi = `${process.env.REACT_APP_PUBLIC_API}/api/teachers`;
  const {data} = useSelector(state => state.getTeachersSlicer)
  const dispatch = useDispatch();

  const { loading:coursseLoading , courses:coursesFromApi } = useSelector((s) => s.GetAllCoursesSlicer);
  const executionsApi = `${process.env.REACT_APP_PUBLIC_API}/api/course-executions`;

  useEffect (() => {
    const getData = async () => {
      dispatch(getTeachersFunc(`${teachersApi}`))
      dispatch(fetchAllCoursesFunc(executionsApi))
      setLoading(true)
    }
    getData();
  },[])
  useEffect(()=>{
    data && setTeachersList(data)
    coursesFromApi && data ? setLoading(false) : setLoading(true);
    const courses = coursesFromApi && coursesFromApi.flatMap(execution => execution.course)
    coursesFromApi && setCoursesList(courses)
  },[data  , coursesFromApi])


    return (
      <>
          <section className="counter-one counter-one--about jarallax" data-jarallax data-speed="0.2" data-imgposition="50% 0%" style={{"background-image":`url(${CountersBg})`}}>
            <div className="container">
              <div className="row">
                {/*Start Counter One Left*/}
                <div className="col-xl-5 col-lg-5">
                  <div className="counter-one__left">
                    {
                      data && coursesFromApi &&
                      <>
                        <div className="section-title">
                          <span className="section-title__tagline">ارقام واحصائيات</span>
                          <h2 className="section-title__title text-[30px]">نتقدم بفخر بالأرقام الإيجابية والتحديات التي تجاوزناها مع مجتمعنا التعليمي المتميز.</h2>
                        </div>
                        <p className="counter-one__left-text">
                        مدرسون محترفون : يُقدم لك اكثر من <span className="text-blue-500 mx-1 font-bold">( {teachersList && teachersList.length} )</span> مدرسًا ذوي خبرة عالية.
                        </p>
                        <p className="counter-one__left-text">
                        عدد الكورسات : يتجاوز عدد دوراتنا <span className="text-blue-500 mx-1 font-bold">( {coursesList && coursesList.length} )</span> ويشمل مجموعة متنوعة من المجالات.
                        </p>
                      </>
                    }
                  </div>
                </div>
                {/*End Counter One Left*/}
                {/*Start Counter One Right*/}
                {
                  loading ?
                  (
                    <>
                      <div className="row d-flex justify-content-center gap-2 ">
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                      </div>
                    </>
                  )
                  :
                  data && coursesFromApi &&
                  <div className="col-xl-7 col-lg-7">
                    <div className="counter-one__right">
                      <ul className="counter-one__right-box list-unstyled">
                        {/*Start Counter One Right Single*/}
                        <li className="counter-one__right-single wow slideInUp animated" data-wow-delay="0.1s" data-wow-duration="1500ms">
                          <div className="counter-one__right-single-icon">
                            <span className="icon-teacher" />
                          </div>
                          <h3 className=" counter-h3">
                          <OdometerCustom endValue={teachersList && teachersList.length} timeStart={500} />
                          {/* {data && data.length} */}
                          </h3>
                          <p className="counter-one__right-text">امهر المعلمين</p>
                        </li>
                        {/*End Counter One Right Single*/}
                        {/*Start Counter One Right Single*/}
                        <li className="counter-one__right-single wow slideInUp animated" data-wow-delay="0.3s" data-wow-duration="1500ms">
                          <div className="counter-one__right-single-icon">
                            <span className="icon-online-course" />
                          </div>
                          {/* <h3 className="odometer" data-count={9800}>00</h3> */}
                          <h3 className=" counter-h3">
                          <OdometerCustom endValue={coursesList && coursesList.length} timeStart={1000} />
                          </h3>
                          <p className="counter-one__right-text">الكورسات المتاحة</p>
                        </li>
                        {/*End Counter One Right Single*/}
                      </ul>
                    </div>
                  </div>
                  
                }
                {/*End Counter One Right*/}
              </div>
            </div>
          </section>
      </>
    )
};
