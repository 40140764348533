import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCoursesFunc } from "../../../../store/slices/courses/coursesSlicer";
import CardCourses from "../../../../component/ui/course-card/CardCourses";
import CustomLoaders from "../../../../component/ui/CustomLoaders";
import faildImg from "../../../../images/planet.png"
import coursesHeader from "../../../../images/courses-header.jpg"
import PageHeader from "../../../../component/ui/PageHeader";

export default function CoursesShow() {
  const { loading, courses:data } = useSelector((s) => s.GetAllCoursesSlicer);
  const dispatch = useDispatch();
  const [courses, setCourses] = useState([]);
  const [excutions, setExcutions] = useState([]);
  const [coursesFilter, setCoursesFilter] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const excutionsApi = `${process.env.REACT_APP_PUBLIC_API}/api/course-executions`;
  const studentInvoices = JSON.parse(window.localStorage.getItem("studentInvoices"))
  const [studentExecutions , setStudentExecutions] = useState([])
  const [uniqStudentExecutions , setUniqStudentExecutions] = useState([])

    useEffect(() => {
      if (studentInvoices && studentInvoices.length >= 1) {
        setStudentExecutions(prevCoursesList => {
          // Map over each invoice in the invoicesList
          const updatedCoursesList = studentInvoices.reduce((accumulator, invoice) => {
            // Extract course_executions from each invoice
            const { course_executions } = invoice;
            // Concatenate the course_executions to the accumulator
            return [...accumulator, ...course_executions];
          }, prevCoursesList); // Start with the previous state of coursesList
    
          return updatedCoursesList; // Set the state to the updated coursesList
        });
      }
    }, []);

    useEffect(() => {
      const uniq = new Set();
      studentExecutions.map((ex) => {
        uniq.add(JSON.stringify(ex))
      })
      const uniqExecutionsObjects = Array.from(uniq).map(ex => JSON.parse(ex));
      setUniqStudentExecutions(uniqExecutionsObjects)
    },[studentExecutions])

  useEffect(() => {
    let coursesApi = `${excutionsApi}${searchValue ? `?search=${searchValue}` : ""}`;
    let searchTimeout;
    let fetchExecutionData = async ()=>{
      if(searchValue !== "") {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(()=>{
          dispatch(fetchAllCoursesFunc(coursesApi))
        },2000)
      }else {
        dispatch(fetchAllCoursesFunc(coursesApi))
      }
    }
    fetchExecutionData()
    return () => clearTimeout(searchTimeout)
  },[searchValue])


  useEffect(() => {
    dispatch(fetchAllCoursesFunc(excutionsApi))
  },[]);
  useEffect(() => {
    data && setExcutions(data)
    const coursesArray = data && data.length >=1 && data.flatMap(excution => excution.course);
    setCourses(coursesArray);
  },[data])
  return (
    <>
      <PageHeader title="اكتشف عالم المعرفة مع دوراتنا المميزة!" headerImg={coursesHeader}/> 
      <section className="courses-section">
        <div className="container-courses mx-auto sm:w-[90%]">
          <div className="section-title text-center">
            <h2 className="section-title__title">استكشف الكورسات الموجودة</h2>
            <span className="section-title__tagline text-sm"> نقدم مجموعة متنوعة من الدورات التعليمية في مختلف المجالات لتلبية احتياجاتك واهتماماتك التعليمية</span>
          </div>
          <div className="row">
            {/*Start case-studies-one Top*/}
            <div className="" dir="rtl">
              <div className="col-xl-12 relative col-lg-12 col-md-12 col-sm-12">
                <div className="relative flex items-center md:mr-3">
                  {/* <i className="fa-solid fa-filter text-xl cursor-pointer text-blue-500"
                    onClick={()=>setShowFilter(true)}
                  ></i> */}
                  <div className="input-group flex rounded-md bg-slate-800 items-center search-area w-auto my-2 mr-4">
                    <i className="fa-solid fa-magnifying-glass p-3"></i>
                    <input
                        type="text"
                        className="bg-transparent placeholder:text-blue-500"
                        placeholder="ابحث عن كورس.."
                        onChange={(e) => {
                        setSearchValue(e.target.value)
                        }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/*End case-studies-one Top*/}
          </div>
          <div className="row gap-3 my-[30px]">
            {loading ? (
              <>
                <div className="text-center py-1">
                  <CustomLoaders />
                  <CustomLoaders />
                  <CustomLoaders />
                </div>
              </>
            ) : (
              <>
                {
                data && excutions && excutions.length >= 1 ?  (
                  <>
                    {excutions.map((excution, index) => {
                      const {course , discount} = excution
                      return (
                        <CardCourses
                          key={index}
                          execution={excution}
                          executionsArray = {uniqStudentExecutions}
                          overlayText={course.semester}
                          teacher={course.user.name}
                          title={course.name}
                          excutionId={excution.id}
                          price={excution.price}
                          courseDiscount = {discount}
                          startPublishForAnyoneDate = {excution.start_date_publish}
                          endPublishForAnyoneDate = {excution.end_date_publish}
                          startPublishForStudentDate = {excution.start_date_student}
                          endPublishForStudentDate = {excution.end_date_student}
                          courseImg={course.image_url}
                          courseInstructor={course.user}
                          typeCourse={course.type}
                          courseStatus = {course.publish}
                          categoryName = {course.category && course.category.name && course.category.name}
                      />
                      )
                    })}
                  </>
                )
                  :
                  <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                    <img 
                      loading="lazy"
                      src={faildImg}
                      alt="faild img"
                      className="w-[150px] mb-3"
                    />
                    <p>
                      لا يوجد كورسات!
                    </p>
                  </div>
              }
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
