import { useState } from "react";
import "./attemp-page.css";
function AccordionOptions({question}) {
    const [showOptions , setShowOptions] = useState();
    return (
        <>
            <div className="relative question-answers my-1">
                <p className=" cursor-pointer my-2 flex items-center rounded-md bg-[#396495] text-white p-2"
                    onClick={() => {
                        setShowOptions(!showOptions)
                    }}
                >اختيارات السؤال
                <i className={`fa-solid fa-circle-left transition-all duration-500 mr-2 text-blue-500 text-[20px] ${showOptions ? "rotate-[90deg] opacity-100" : "rotate-[-90deg] opacity-50"}`}></i>
                </p>
                <ul className={`overflow-y-auto question-answer-ul transition-all duration-500 ${showOptions ? "h-[100px]" : "h-0"}`}>
                    {
                        question
                        && 
                        question.options 
                        &&
                        question.options.map((option , index) => <li key={index} className="relative flex mb-1 p-2 text-[#353537] bg-white items-center">
                            <i className="fa-solid fa-circle-dot text-[13px] opacity-80 ml-2"></i>
                            {option}</li>)
                    }
                </ul>
            </div>
        
        </>
    )
}
export default AccordionOptions;