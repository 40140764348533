import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ShowAnswerModal(props) {
    const {headerText , answer} = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='z-[99999]'
    >
      <Modal.Header className='relative flex justify-end items-center'>
        <Modal.Title id="contained-modal-title-vcenter">
          {headerText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='image-modal relative w-full flex flex-col'>
            <p className='relative w-full flex mb-1 p-2 items-center justify-end'>
                {answer}
                <i className="fa-solid fa-circle-dot text-[13px] opacity-80 ml-2"></i>
            </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className='bg-primary'>اغلاق</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ShowAnswerModal