import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const categoriestApis = createAsyncThunk("category/get", async () => {
  // return await fetch(`${process.env.REACT_APP_PUBLIC_API}/api/categories
  // `, {
  //   method: "GET",})
  //   .then((res) => res.json())
  //   .then((data) => data)
  //   .catch((err) => err);
  const response = await axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/categories` , {withCredentials : true})
  checkErrorsFunctions(response , response.data.errors);
  return response.data;
});

const categoriesSlicer = createSlice({
  name: "category",
  initialState: {
    loading: false,
    responce: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(categoriestApis.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(categoriestApis.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.status ==  true) {
        state.responce = action.payload;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(categoriestApis.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default categoriesSlicer.reducer;
