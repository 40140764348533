import { useParams , Link } from "react-router-dom";
import { useState , useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuizeData } from "../../../../store/slices/quizes/showQuizesSlicers";
// import "./lesson-quize-page.css";
import { Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { postStudentAnswersFunc } from "../../../../store/slices/quizes/PostStudentAnswersSlicer";
import noQuestionImg from "../../../../images/question-mark (1).png";
import explainIcon from "../../../../images/vid-img.png";
import swal from "sweetalert";
import LoadingBook from "../loading-book/LoadingBook";
import PageHeader from "../../../../component/ui/PageHeader";
import Swal from "sweetalert2";
import QuizeTimer from "../quize-timer/QuizeTimer";
import { keyboard } from "@testing-library/user-event/dist/keyboard";
import { postCompletingLessonFunc } from "../../../../store/slices/lessons/PostCompletingLessonSlicer";
function CourseQuizePage () {
    const params = useParams();
    const courseId = params.courseId;
    const dispatch = useDispatch();
    const [quize , setQuize] = useState();
    const [quizeQuestions , setQuizeQuestions] = useState([]);
    const [thereIsArticleQ , setThereIsArticleQ] = useState([]);
    const [answersImages , setAnswersImages] = useState([]);
    const [submitionQuizeId , setSubmitionQuizeId] = useState([]);
    const [articleQuestionsLength , setArticleQuestionsLength] = useState();
    const {data , loading} = useSelector(state => state.shwoLessonSlicer)
    const {handleSubmit,  reset , register , formState : {errors} , setValue} = useForm();
    const [quizeTimer , setQuizeTimer] = useState();
    const [onSubmition , setOnSubmition] = useState();
    const quizeApi = `${process.env.REACT_APP_PUBLIC_API}/api/courses/${courseId}/start-quiz`;
    const submitquizeAnswersApi = `${process.env.REACT_APP_PUBLIC_API}/api/courses/${courseId}/submit-quiz`;
    // const quizeApi = `${process.env.REACT_APP_PUBLIC_API}/api/quizzes/${quizeId}`;
    const quizeAttemptApi = `${process.env.REACT_APP_PUBLIC_API}/api/quiz-attempts/${submitionQuizeId}`;
    const  {loading : postAnswersLoading} = useSelector(state => state.postStudentAnswersSlicer);
    const postCompletingLessonApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${courseId}/complete`
    const postCompletingLesson = () => {
        dispatch(postCompletingLessonFunc(postCompletingLessonApi))
    }
    const getQuizeDataFunction = async () => {
        dispatch(getQuizeData(quizeApi));
    }
    useEffect(() => {
        getQuizeDataFunction()
    },[]);
    useEffect(() => {
        data && setQuizeQuestions(data.questions);
        data && setQuize(data)
        data && setSubmitionQuizeId(data.id)
        data && data.pivot && setQuizeTimer(parseInt(data.pivot.duration))
        if(data && data.pivot){
            if(!window.localStorage.getItem("quizeTimer")){
                window.localStorage.setItem("quizeTimer" , JSON.stringify(data.pivot.duration))
            }
        }
    },[data , quizeQuestions])
    useEffect(() => {
        const articleQuestion = data &&  quizeQuestions && quizeQuestions.filter(question => {
            return question.type === 3 })
            articleQuestion && articleQuestion.length > 0 ? setThereIsArticleQ(true) : setThereIsArticleQ(false)
            setArticleQuestionsLength(articleQuestion && articleQuestion.length)
    },[quizeQuestions , data])

    const handleSubmittion = async (data) => {
            if(data && answersImages) {
                if(answersImages.length !== articleQuestionsLength){
                    swal({
                        title: "فشل الارسال!",
                        text: "صور الاجابات يجب ان تكون نفس عدد الاسئله المقاليه!",
                        icon: "warning",
                        button: true,
                    })
                }else {
                    setOnSubmition(true);
                    window.localStorage.removeItem("quizEndTime");
                    const mainForm = new FormData();
                    for(const img of answersImages){
                        mainForm.append("images[]" , img);
                    }
                    for (const [key, value] of Object.entries(data)) {
                        mainForm.append(`answers[${key}]`, +value);
                    }
                    if(thereIsArticleQ){
                        dispatch(postStudentAnswersFunc({url : submitquizeAnswersApi, answers : mainForm }))
                        .then(result => {
                            // if(result.payload.message === "Quiz time is over!"){
                            //     Swal.fire({
                            //         title: "فشل الارسال",
                            //         text: "لا يمكنك ارسال الاجابات بعد انتهاء وفت الاختبار",
                            //         icon: "warning",
                            //         showCancelButton: true,
                            //         confirmButtonText: "تم"
                            //     })
                            // }else{
                            //     if(result.payload.status_code === 403){
                            //         Swal.fire({
                            //             title: "فشل الارسال",
                            //             text: "لا يمكن ارسال الاجابات مره اخري",
                            //             icon: "warning",
                            //             showCancelButton: true,
                            //             confirmButtonText: "تم"
                            //         })
                            //     }else{
                            //         postCompletingLesson()
                            //         if(result.payload.success === true) {
                            //             swal("احسنت", "تم رفع الاجابات بنجاح", "success");
                            //             setTimeout(()=>window.history.back(),3000)
                            //         }
                            //     }
                            // }
                            postCompletingLesson()
                            if(result.payload.success === true) {
                                swal("احسنت", "تم رفع الاجابات بنجاح", "success");
                                setTimeout(()=>window.history.back(),3000)
                            }
                        })
                    }else {
                        dispatch(postStudentAnswersFunc({url : submitquizeAnswersApi, answers : {answers : data}}))
                        .then(result => {
                            // if(result.payload.status_code === 403){
                            //     Swal.fire({
                            //         title: "فشل الارسال",
                            //         text: "لا يمكن ارسال الاجابات مره اخري",
                            //         icon: "warning",
                            //         showCancelButton: true,
                            //         confirmButtonText: "تم"
                            //     })
                            // }else{
                            //         if(result.payload.success === true) {
                            //         postCompletingLesson();
                            //         swal("احسنت", "تم رفع الاجابات بنجاح", "success");
                            //         setTimeout(()=>window.history.back(),3000);
                            //         window.localStorage.removeItem("quizEndTime");
                            //         window.localStorage.removeItem("quizeTimer");
                            //     }
                            // }
                            if(result.payload.success === true) {
                                postCompletingLesson();
                                swal("احسنت", "تم رفع الاجابات بنجاح", "success");
                                setTimeout(()=>window.history.back(),3000);
                                window.localStorage.removeItem("quizEndTime");
                                window.localStorage.removeItem("quizeTimer");
                            }
                        })
                    }
                }
        }
    }

    const submitionForm = useRef();
    const submitBTN = document.querySelector(".submit-answer-btn");
    const autoSubmition = () => {
        if(submitBTN) {
            submitBTN.click();
            postCompletingLesson();
            setOnSubmition(true);
        }
    }
    return (
        <>
            <PageHeader headerImg={"https://images.pexels.com/photos/6373308/pexels-photo-6373308.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} title={"Quize Content"}/>
            <div className="quize-page">
                {
                    loading
                    ?
                        <>
                            <div className="row d-flex justify-content-center gap-2 my-5">
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                            </div>
                        </>
                    :
                    <div className="container-quize-page mx-auto my-4 w-[80%]">
                        <div className="relative flex justify-between items-center">
                            <h3 className="text-primary mb-2 my-2">{quize && quize.title}</h3>
                            <h3 className="text-primary mb-2 my-2">عدد الاسئله: ({quizeQuestions && quizeQuestions.length})</h3>
                        </div>
                        <form
                            ref={submitionForm}
                            className="questions-submit"
                            onSubmit={handleSubmit((data) => {
                                handleSubmittion(data)
                            })}
                        >
                        {
                            quizeQuestions
                            &&
                            quizeQuestions.map((question , index) => {
                                console.log(question.image_url)
                                return (
                                    <div style={
                                        {"animationDelay" : `.${index}s`}
                                    } key={question.id} className={`question-card relative transition-all duration-500 p-3 bg-white shadow-md shadow-slate-300 rounded-lg my-2 ${!loading ? "active" : ""}`}>
                                        <div className="question-title d-flex mb-3">
                                            <p className="ml-3">{index+1}: </p>
                                            {
                                                question.type !== 3
                                                ?
                                                <p className="text-primary">{question.title}</p>
                                                :
                                                <div
                                                className={`question-image rounded-md overflow-hidden relative w-full h-[200px]`}
                                                
                                                >
                                                    <img loading="lazy" src={question.image_url ? question.image_url : "no image" } alt="question-img" className="absolute top-0 left-0 w-full h-full"/>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            {
                                                question.type !== 3
                                                &&
                                                <div className="relative flex flex-col">
                                                    {
                                                        question.options && 
                                                        question.options.map((option,index)=>{
                                                            return(
                                                                <div className="relative flex items-center my-2" key={index}>
                                                                    <input 
                                                                    {...register(question.id.toString() , {required : "answers required!"})}
                                                                    id={option.id} 
                                                                    className="mr-2"
                                                                    type="radio" 
                                                                    value={+index}
                                                                    name={question.id}/>
                                                                    <label 
                                                                    htmlFor={option.id}
                                                                    className="mrl-1 "
                                                                    >{option}</label>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            thereIsArticleQ
                            &&
                            <div className="answers-images relative flex flex-col my-3">
                                <label className="mb-2" htmlFor="articales-answers">ارفع الاجابات المقالية</label>
                                <input 
                                id="articales-answers" 
                                className="form-control" 
                                type="file" 
                                name="files" 
                                multiple
                                onChange={(e)=>{
                                    setAnswersImages(Array.from(e.target.files));
                                }}
                                />
                                <p className="relative text-sm m-0 text-red-500 mt-3">ملحوظه : عند  رفع الاجابات للاسئلة المقاليه يجب كتابه رقم السوال قبل الاجابه مثال <span className="text-primary">(اجابه السؤال رقم 2)</span></p>
                            </div>
                        }
                        {
                            quizeQuestions && quizeQuestions.length < 1
                            &&
                            <div className="empty-questions relative my-5 flex flex-col justify-center items-center">
                                <img loading="lazy" src={noQuestionImg} alt="no questions img" className="relative w-[100px] my-4" />
                                <p>عذرا, لا يوجد اسئله علي هذا الامتحان حتي الان 
                                    <span className="cursor-pointer  text-primary mr-1 " 
                                    onClick={()=>window.history.back()}
                                    >رجوع</span>
                                </p>
                            </div>
                        }
                        {
                            quizeQuestions && quizeQuestions.length >= 1
                            &&
                            <>
                                <Button type="submit" className={`bg-primary my-2 submit-answer-btn ${onSubmition ? "opacity-30 pointer-events-none" : ""}`}>اتمام الامتحان</Button>
                                <Button type="reset" className="bg-primary my-2 mr-3">تفريغ الاجابات</Button>
                            </>
                        }
                        </form>
                    </div>
                }
            </div>
            <LoadingBook loadingText={"Don't Refresh.Please Wait For Sending Your Answers!"} loading={postAnswersLoading}/>
            <QuizeTimer 
                getQuizeLoading = {loading} 
                exitQuize={autoSubmition} 
                timeWithMunites = {quizeTimer && quizeTimer}
            />
        </>
    )
}

export default CourseQuizePage;