import { useContext } from "react";
import aboutImage1 from "../../../images/about/about-img1.png";
import aboutImage2 from "../../../images/about/about-img2.png";
import ContextApi from "../../../store/slices/Context";
import { Link } from "react-router-dom";
import img from "../../../images/about/photo-1610484826967-09c5720778c7.webp"
import emailIcon from "../../../images/about/mail.png";
import adressIcon from "../../../images/about/adress.png";
import phoneIcon from "../../../images/about/phone-call.png";
export default function AboutSection() {
  const contextApi = useContext(ContextApi);
  const emails = contextApi.generalSetting.emails;
  const phones = contextApi.generalSetting.phones;
  const addresses = contextApi.generalSetting.addresses;
  const more_about = contextApi.generalSetting.more_about
    return <>
      <section className="about-two">
        <div className="about-container w-[90%] mx-auto row">
          <div className="image-side relative col-sm-12 col-md-6 flex justify-center items-center">
            <div className="image-div relative w-full h-[400px]">
              <img src={img} alt="img" className="absolute top-0 left-0 w-full h-full"/>
            </div>
          </div>
          <div className="text-side relative col-sm-12 col-md-6">
            <div className="about-text relative flex flex-col">
              <h2 className=" transition-all duration-500 hover:tracking-[2px] text-[20px] md:text-[30px] lg:text-[40px] font-bold text-slate-700 mb-2"> من نحن ؟</h2>
              <div  dangerouslySetInnerHTML={{__html : more_about}}/>
            </div>
          </div>
        </div>
        <div className="about-container w-[90%] mx-auto row gap-2 justify-between py-4">
          <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
            <img src={emailIcon} alt="img" className=" my-2 w-[50px]" />
            {
              emails
              &&
              emails.map((email , index) => {
                return <Link key={index} to={`mailto:${email}`} className="mb-1">{email}</Link>
              })
            }
          </div>
          <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
          <img src={phoneIcon} alt="img" className=" my-2 w-[50px]" />
            {
              phones
              &&
              phones.map((phone , index) => {
                return <Link key={index} to={`https://wa.me/:${phone}`} className="mb-1">{phone}</Link>
              })
            }
          </div>
          <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
          <img src={adressIcon} alt="img" className=" my-2 w-[50px]" />
            {
              addresses
              &&
              addresses.map((address , index) => {
                return <Link key={index} className="mb-1">{address}</Link>
              })
            }
          </div>
        </div>
      </section>
    </>
};
