import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ShowImageModal(props) {
    const {image , title} = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='z-[99999]'
    >
      <Modal.Header className='relative flex justify-end items-center'>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='image-modal relative w-full flex justify-center items-center'>
            <img loading="lazy" src={image} alt="img modal" className='relative' />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className='bg-primary'>اغلاق</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ShowImageModal