import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const getLessonData = createAsyncThunk("lessons/show", async (url) => {
  try {
    const response = await axios.get(url, {
      withCredentials: true,
    });
      // if(response.data.success === false && response.data.message === "Unauthenticated.") {
      //   window.location.href = "/login";
      // }else{
      //   return response.data.data
      //   // return {lesson : {lesson : response.data.data.lesson , quiz : response.data.data.quiz , progres : response.data.data.progres}};
      // }
      checkErrorsFunctions(response , response.data.errors);
      return response.data.data
  } catch (error) {
    console.log(error)
  }

  // return await axios
  //   .get(
  //    ,"https://apis.alawa2el.com/api/profile"

  //     { withCredentials: true }
  //   )
  //   .then((data) => data)
  //   .catch((error) => error);
});

const shwoLessonSlicer = createSlice({
  name: "lessons",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getLessonData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getLessonData.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.data = action.payload
    });
  },
});

export default shwoLessonSlicer.reducer;
