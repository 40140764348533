import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import ContextApi from "../../store/slices/Context";
import platfotmImg from "../../images/1.png"
function MobileNavBar ({showMobileNav , hideShowMobileNav}) {
    const location = useLocation()
    const nameLocation = location.pathname;
    const contextApi = useContext(ContextApi)
    const platformLogo = contextApi && contextApi.generalSetting.platform_logo_url
    const contactsEmails = contextApi && contextApi.generalSetting.emails
    return (
        <>
            <div className={`h-[100vh] z-[999999] bg-slate-800 flex flex-col items-center justify-center fixed ${showMobileNav ? 'right-0' : "right-[-300px]"} transition-all duration-500 top-0 w-[300px]`}>
                <i className="fa-solid fa-xmark text-[#00aaeb] text-[30px] absolute top-[10px] cursor-pointer left-[10px]"
                    onClick={()=>hideShowMobileNav()}
                ></i>
                <div className="logo-and-links relative flex flex-col justify-center">
                    <Link to="/" className=" flex justify-center items-center">
                        <img loading="lazy" className="platform-logo w-[200px]" alt="logo" src={platformLogo ? platformLogo : platfotmImg} />
                    </Link>
                    <ul className="flex flex-col">
                        <li className="relative text-center lg:mx-3 hover:translate-x-1 transition-all duration-500" style={{"--i" : "1.6s"}}>
                            <Link className="block py-2 text-white" to="/" onClick={()=>{
                                hideShowMobileNav()
                            }}>الرئيسية</Link>
                        </li>
                        <li className="relative text-center lg:mx-3 hover:translate-x-1 transition-all duration-500" style={{"--i" : "1.3s"}}>
                            <Link className="block py-2 text-white" to="/about" onClick={()=>{
                                hideShowMobileNav()
                            }}>عنا</Link>
                        </li>
                        <li className="relative text-center lg:mx-3 hover:translate-x-1 transition-all duration-500" style={{"--i" : "1s"}}>
                            <Link className="block py-2 text-white" to="/courses" onClick={()=>{
                                hideShowMobileNav()
                            }}>المواد الدراسية</Link>
                        </li>
                        {
                            nameLocation !== "/login"
                            &&
                            <li className="relative text-center lg:mx-3" style={{"--i" : "1s"}}>
                                <Link className={`block py-2 text-white`} to="/my-courses" onClick={()=>{
                                    hideShowMobileNav()
                                }}> كورساتي </Link>
                            </li>
                        }
                        <li className="relative text-center lg:mx-3 hover:translate-x-1 transition-all duration-500" style={{"--i" : "1s"}}>
                            <Link className="block py-2 text-white" to="/contact" onClick={()=>{
                                hideShowMobileNav()
                            }}>تواصل معنا</Link>
                        </li>
                    </ul>
                    <div className="relative flex ">
                        <Link>
                            
                        </Link>
                    </div>
                    <div className="relative flex flex-col">
                        {
                            contactsEmails && contactsEmails.map((email , index) => {
                                return (
                                    <Link className="mb-1 text-center" key={index} href={`mailto:${email}`}>
                                        {email}
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
      );
}

export default MobileNavBar;