import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
export const fetchAllCoursesFunc = createAsyncThunk(
    "get all courses function",
    async (api) => {
        try {
            const response = await axios.get(api , {withCredentials : true});
            // if(response.data.status_code === 401) {
            //     swal({
            //         title: "Oops!",
            //         text: `Session is end please loging again !`,
            //         icon: "warning",
            //         button: "Login",
            //     }).then((login)=>{
            //         if(login){
            //             window.localStorage.clear();
            //             window.location.reload();
            //         }
            //     })
            // }else{
            //     return response.data.data.courseExecution;
            // }
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.courseExecution;
        }
        catch (error) {
            return error;
        }
        
    }
)

const GetAllCoursesSlicer = createSlice({
    name : "get all courses slicer",
    initialState : {
        loading : null,
        courses : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(fetchAllCoursesFunc.pending , (state) => {
            state.loading = true
            state.courses = null
            state.error = null
        })
        builder.addCase(fetchAllCoursesFunc.rejected , (state) => {
            state.loading = null
            state.courses = null
            state.error = true
        })
        builder.addCase(fetchAllCoursesFunc.fulfilled , (state , action) => {
            state.loading = false
            state.courses = action.payload
            state.error = null
        })
    }
})

export default GetAllCoursesSlicer.reducer;
