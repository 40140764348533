import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../component/ui/Input";
import { Spinner, Alert, Button } from "react-bootstrap";
import { Formik, from } from "formik";
import * as Yup from "yup";
import { contactApis } from "../../../../store/slices/contact/contactSlicer";
import Swal from "sweetalert2";

const contactSchema = Yup.object().shape({
  name: Yup.string()
    .required("بيانات مطلوبة ")
    .min(3, "بيانات قصيرة جدا ")
    .max(50, "بيانات طويلة جدل"),
    subject: Yup.string()
    .required("بيانات مطلوبة ")
    .min(3, "بيانات قصيرة جدا ")
    .max(50, "بيانات طويلة جدل"),
  email: Yup.string().required("بيانات مطلوبة ").email("بريد غير صالح"),
  phone: Yup.string()
    .matches(/^\d{11}$/, "رقم غير صالح")
    .min(11, "رقم قصير ")
    .required("بيانات مطلوبة "),
  message: Yup.string()
    .required("بيانات مطلوبة ")
    .min(3, "بيانات قصيرة جدا")
    .max(2000, "بيانات طويلة جدا"),
});

export default function RegistrationFrom() {
  const { loading, error } = useSelector((s) => s.contactSlice);
  const dispatch = useDispatch();

  const handleSubmit = (value, { resetForm }) => {
    dispatch(contactApis(value)).then((res) => {
      if (res.payload.message === "Contact Created Successfully") {
        Swal.fire({
          title: "نجاح الطلب!",
          text: "تم ارسال طلبك بنجاح الرجاء انتظار رساله للتواصل",
          icon: "success"
        });
        resetForm();
      }
    });
  };
  return (
    <>
      <div className="registration-one__right-form">
        <div className="title-box">
          <h4>املاء بياناتك الان</h4>
        </div>
        <div className="form-box">
          <Formik
            initialValues={{
              name: "",
              email: "",
              subject:"" ,
              phone: "",
              message: "",
            }}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              setValues,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  {errors.name && errors.name}
                  <input
                    type="text"
                    name="name"
                    placeholder="الاسم بالكامل"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </div>
                <div className="form-group">
                  {errors.email && errors.email}
                  <input
                    type="email"
                    name="email"
                    placeholder="البريد الالكتروني"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </div>
                <div className="form-group">
                  {errors.subject && errors.subject}
                  <input
                    type="text"
                    name="subject"
                    placeholder="الموضوع :طلب تسجيل طالب"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subject}
                  />
                </div>
                <div className="form-group">
                  {errors.phone && errors.phone}
                  <input
                    type="text"
                    name="phone"
                    placeholder="رقم الهاتف"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                </div>
                <div className="form-group">
                  {errors.message && errors.message}
                  <textarea
                    placeholder={"اترك رسالة"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.message}
                    name="message"
                  />
                </div>
                <Button type="submit" className="bg-primary py-2 px-4 transition-all duration-500 hover:shadow-slate-400 shadow-md hover:translate-y-[-5px] mt-2 right-[50%] relative translate-x-[50%] w-full">
                  {loading ? <Spinner variant="border" /> : "سجل الان"}
                </Button>
                {error && (
                  <>
                    <div className="form-group">
                      <Alert variant="danger">{error}</Alert>
                    </div>
                  </>
                )}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
