import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner, Table } from "react-bootstrap";
import noAttemptsImg from "../../../../images/planet.png";
import headerImg from "../../../../images/quize-people.jpg";
// import PageTitle from "../../../layouts/PageTitle";
import { Link } from "react-router-dom";
// import correctIcon from "../../../../media/images/checklist.png";
// import noAttemptsImg from "../../../../media/images/planet.png";
// import questionIcon from "../../../../media/images/question.png";
// import studentImg from "../../../../media/images/graduated.png";
import { getAllAtemptsFunc } from "../../../../store/slices/attempt-actions/GetFreeAttempsSlicer";
import PageHeader from "../../../../component/ui/PageHeader";
function FreeAttemptsPage() {
    const params = useParams();
    const lessonId = params.lessonId;
    const dispatch = useDispatch();
    const {loading , data} = useSelector(state => state.getAllAtemptsSlicer);
    const attemptsApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}/quiz-attempts`;
    // const attemptsApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/quiz-attempts`;
    const [lessonAttempts , setLessonAttempts] = useState([]);
    const [lessonData , setLessoData] = useState({})
    const getLessonAttempts = () => {
        dispatch(getAllAtemptsFunc(attemptsApi))
        .then(result => console.log(result))
    }
    useEffect(() => {
        getLessonAttempts();
    },[])
    useEffect(() => {
        if(data) {
            data && setLessonAttempts(data.attempts);
            data && setLessoData(data.lesson);
        }
    },[data])
    const checkQuizStatus = (status) => {
        if(status) {
            switch(status) {
                case "started" :
                return <span className="relative flex items-center">
                    <i className="fa-solid fa-circle-play ml-1 text-blue-500"></i>
                    غير مكتمل
                </span>
                case "compleated" :
                return <span className="relative flex items-center">
                    <i className="fa-solid fa-circle-check ml-1 text-green-500"></i>
                    مكتمل
                </span>
                case "review" :
                return <span className="relative flex items-center">
                    <i className="fa-regular fa-clock ml-1 text-orange-500"></i>
                    فيد التصحيح
                </span>
                default :
                return "لايوجد"
            }
        }
    }

    return (
        <>
        <PageHeader headerImg={headerImg} title={"محاولات الامتحان"} />
        {
            loading
            ?
            (
                <>
                    <div className="row d-flex justify-content-center gap-2 my-8">
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                        <Spinner animation="grow" variant="warning" />
                    </div>
                </>
            )
            :
            <div className="attempt-setion flex flex-col mt-3">
            {/* <PageTitle activeMenu="All Attempts" motherMenu={"Course"} /> */}
        {
            data && lessonAttempts && lessonAttempts.length > 0 ?
            <div className="all-attempts-content relative w-[90%] mx-auto">
                <Table responsive className="">
                    <thead>
                    <tr>
                        <th className="width80">
                            <strong className="text-blue-700">الدرجه</strong>
                        </th>
                        <th>
                            <strong className="text-blue-700">التاريخ</strong>
                        </th>
                        <th>
                            <strong className="text-blue-700">حاله الامتحان</strong>
                        </th>
                        <th>
                            <strong className="text-blue-700">الاجابات</strong>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        {data && lessonAttempts.map((attemp,index)=>{
                            const date = new Date(attemp.created_at)
                            const year = date.getFullYear();
                            const month = date.getMonth()+1;
                            const day = date.getDate();
                            return (
                            <tr key={index}>
                                <td className="flex items-center">
                                    {attemp.score ? attemp.score : "لم يتم الاضافه"}
                                </td>
                                <td>{year} / {month} / {day}</td>
                                <td>
                                    {checkQuizStatus(attemp.status_passed)}
                                </td>
                                <td>
                                    {
                                        attemp.status_passed === "completed"
                                        ?
                                        <Link to={`attemp-page/${attemp.id}`} className="btn bg-primary text-white">
                                            <i className="fa-solid fa-eye ml-1"></i>
                                            عرض الاجابات
                                        </Link>
                                        :
                                        "يجب اكمال الامتحان"
                                    }
                                </td>
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            :
            <div className="relative w-full h-[100vh] flex flex-col justify-center items-center">
                <img
                src={noAttemptsImg}
                alt="faild img"
                className="w-[150px] mb-3 opacity-80"
                />
                <p>
                    لا يوجد محاولات !
                </p>
            </div>
        }
        </div>
        }
        </>
    )


}

export default FreeAttemptsPage