import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ShowRichTextContents(props) {
    const {text , title} = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='z-[99999]'
    >
      <Modal.Header className='relative flex justify-end items-center'>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='image-modal relative w-full flex justify-center items-center'>
            <div className='text-right w-full' dangerouslySetInnerHTML={{__html : text}} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide} className='bg-primary'>اغلاق</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ShowRichTextContents