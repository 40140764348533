import { useContext } from "react";
import ContextApi from "../../../store/slices/Context";
import PurpleBtn from "../../../component/global/btn/PurpleBtn";

export default function About() {
  const contextApi = useContext(ContextApi)
  const aboutText = contextApi.generalSetting.about
  return (
    <>
      <section className="about-one clearfix" dir='rtl'> 
        <div className="container-about row flex items-center w-[90%] mx-auto">
          <div className="about-images relative col-sm-12 col-md-6">
            <div className="image-div relative w-[300px] h-[400px] after:content after:top-0 after:left-0 after:w-[50px] after:h-full after:bg-blue-700 after:z-20">
              <img src={"https://plus.unsplash.com/premium_photo-1664195539781-b8eebcc2d817?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="img" className="absolute top-0 left-0 w-full rounded-md h-full"/>
              <img src={"https://images.unsplash.com/photo-1627556704302-624286467c65?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="img" className="second-img absolute w-[250px] hover:translate-y-[-10px] transition-all duration-500 rounded-md h-[300px] bottom-[-70px] left-[-100px]"/>
            </div>
          </div>
          <div className="about-text relative flex flex-col col-sm-12 col-md-6 mt-[100px] md:mt-0">
            <div  dangerouslySetInnerHTML={{__html : aboutText}}/>
          
            <PurpleBtn pathname={"about"} btnText={"تعرف المزيد"} customeClasses={"mt-3"}/>
          </div>
        </div>
      </section>
    </>
  );
}
