import "./details-page.css";
import defaultCourseImg from "../../../../images/slide3.jpg"
import lessonsIcon from "../../../../images/details-page/learning.png";
import typeIcon from "../../../../images/details-page/text-along-path.png";
import categoryIcon from "../../../../images/details-page/categories.png";
import defaultInstructorImg from "../../../../images/presentation.png";
import freeWatchIcon from "../../../../images/audiobook.png";
import priceIcon from "../../../../images/details-page/price-tag.png";
import imgHeader from "../../../../images/details-page/header-img.jpg";
import videoIcon from "../../../../images/video-camera.png";
import quizIcon from "../../../../images/quiz.png";
import fileIcon from "../../../../images/google-docs.png";
import pdfIcon from "../../../../images/file.png";
import rich_textIcon from "../../../../images/files.png";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import CustomLoaders from "../../../../component/ui/CustomLoaders";
import { coursesApis, getSingleCourseFunc } from "../../../../store/slices/courses/singleCourseSlicer";
import PageHeader from "../../../../component/ui/PageHeader";
import SubscirbeForm from "./SubscribeForm";
import ShowVideoModal from "../../../../component/show-video-modal/ShowVideoModal";
import Swal from "sweetalert2";
import OverLayLoading from "../../../Contact/component/ui/SendingMessageLoading";
import { addToCartFunc } from "../../../../store/slices/cart-actions/AddToCartSlicer";
import { getCartFunc } from "../../../../store/slices/cart-actions/GetCartSlicer";
import { getLessonData } from "../../../../store/slices/lessons/showLessonSlicer";
import { downloadLessonFileFunc } from "../../../../store/slices/lessons/DownloadLessonFileSlicer";
import SingleFreeLessonBtn from "./SingleFreeLessonBtn";
import { postCompletingLessonFunc } from "../../../../store/slices/lessons/PostCompletingLessonSlicer";
import axios from "axios";
import { toast } from "react-toastify";
import { getSubscriptionsCourses } from "../../../../store/slices/users/subscriptionsCoursesSlicer";
import limitationIcon from "../../../../images/speedometer.png";
import { Accordion } from "react-bootstrap";
import SingleLessonRow from "./SingleLessonRow";
import Btn from "../../../../component/ui/Btn";
// import 'react-quill/dist/quill.snow.css';
export default function CustomCourseDetails() {
  const { excutionId } = useParams();
  const { loading , error , data} = useSelector(
    (s) => s.singleCourseSlicer
  );
  const {loading:addTocartLoading} = useSelector(
    state => state.addToCartSlicer
  )
  const dispatch = useDispatch();
  const [course, setCourse] = useState({});
  const [lessons, setLessons] = useState([]);
  const [courseInstructor, setCourseInstructor] = useState({});
  const [updateDay , setUpdateDay] = useState();
  const [updateYear , setUpdateYear] = useState();
  const [mounted , setMounted] = useState();
  const [showSubscribeForm , setShowSubscribeForm] = useState();
  const [priceAfterOffer , setPriceAfterOffer] = useState();
  const [discountValue , setDiscountValue] = useState();
  const [courseExcution , setCourseExcution] = useState({});
  const [coursePrice , setCoursePrice] = useState();
  const [showFreeLesson , setShowFreeLesson] = useState();
  const [freeVideoUrl , setFreeVideoUrl] = useState();
  const [freeLessonTitle , setFreeLessonTitle] = useState();
  const cartApi = `${process.env.REACT_APP_PUBLIC_API}/api/carts`;
  const downloadLessonFileApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons`;
  const [freeLesson , setFreeLesson] = useState({});
  const [targetLessonId , setTargetLessonId] = useState();
  const {loading:getFreeLessonLoading , data:freeLessonData} = useSelector(
    state => state.shwoLessonSlicer
  )
  const user = JSON.parse(window.localStorage.getItem("userdata"));
  const expire_date = user && user.data.expiry_token;
  const {
    loading: subscriptionsCoursesLoading,
    data: subscriptionsCoursesData,
    error: subscriptionsCoursesError,
  } = useSelector((s) => s.subscriptionsCoursesSlicer);
  const globalLessonsApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons`;
  const getAllLessons = async () => {
    dispatch(
      getSubscriptionsCourses(
        `${process.env.REACT_APP_PUBLIC_API}/api/course-executions/${excutionId}/lessons`
      ))
  };
  const getSingleCourse = () => {
    dispatch(getSingleCourseFunc(excutionId))
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getSingleCourse();
  }, [excutionId]);
  useEffect(() => {
    data && data.courseExecution && setCourseExcution(data.courseExecution);
    data && data.courseExecution && data.courseExecution.discount && setDiscountValue(data.courseExecution.discount);
    data && data.courseExecution && data.courseExecution.price && setCoursePrice(data.courseExecution.price);
    data && data.courseExecution && data.courseExecution.course && setCourse(data.courseExecution.course);
    data && course.user && setCourseInstructor(course.user);
    !user && !expire_date && data && data.lessons && setLessons(data.lessons);
    const offerValue = data && courseExcution.price - parseInt(data.courseExecution.discount)
    setPriceAfterOffer(offerValue);
  },[data , course , courseExcution])

  useEffect(() => {
    user && expire_date && getAllLessons();
  }, []);
  useEffect(() => {
    subscriptionsCoursesData &&
    subscriptionsCoursesData.lessons &&
    setLessons(subscriptionsCoursesData.lessons);
  },[subscriptionsCoursesData])
  const checkLessonType = (lessonType) => {
      if(lessonType) {
          switch(lessonType) {
              case "video" :
              return <img loading="lazy" src={videoIcon} alt="icon" className="relative mr-1 w-[30px]" />
              case "quiz" :
              return <img loading="lazy" src={quizIcon} alt="icon" className="relative mr-1 w-[30px]" />
              case "file" :
              return <img loading="lazy" src={fileIcon} alt="icon" className="relative mr-1 w-[30px]" />
              case "pdf" :
              return <img loading="lazy" src={pdfIcon} alt="icon" className="relative mr-1 w-[30px]" />
              case "rich_text" :
              return <img loading="lazy" src={rich_textIcon} alt="icon" className="relative mr-1 w-[30px]" />
              default :
              return <img loading="lazy" src={videoIcon} alt="icon" className="relative mr-1 w-[30px]" />
          }
      }
  }
  const additionFunction = () => {
      const addTocart = () => {
        try {
            dispatch(addToCartFunc({api : cartApi , data : {course_execution_id : excutionId}}))
            .then(result => {
              if(result.payload.success === true) {
                dispatch(getCartFunc(cartApi));
              }
            })
        }
        catch(error) {
            Swal.fire({
                title: "عذرا!",
                text: error,
                icon: "warning"
            });
        }
      }
      Swal.fire({
        title: "تاكيد",
        text: "تاكيد تضمين الكورس الي عربة التسوق!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "الغاء",
        confirmButtonText: "اضافة!"
      }).then((result) => {
        if (result.isConfirmed) {
          addTocart();
        }
      });
      
    }
  const postCompletingLesson = (lessonId) => {
      const postCompletingLessonApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}/complete`;
    dispatch(postCompletingLessonFunc(postCompletingLessonApi))
  }
  useEffect(() => {
    !loading ? setTimeout(() => setMounted(true) , 1000) : setMounted(false)
  },[loading])

  const getFreeLesson = (lessonId) => {
    dispatch(
      getLessonData(`${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}`)
      )
      .then(result => {
        if(result.payload) {
          if(result.payload.lesson.file_url) {
            setShowFreeLesson(true);
            setFreeVideoUrl(result.payload.lesson.file_url);
            setFreeLessonTitle(result.payload.lesson.name);
            postCompletingLesson(lessonId);
          }
          else {
            Swal.fire({
              title: "عذرا!",
              text: "لا يوجد فيديو لهذا الدرس!",
              icon: "warning"
            });
          }
        }
      })
  }
  useEffect(() => {
    freeLessonData && setFreeLesson(freeLessonData);
  },[freeLessonData])

  const {
    loading: downloadLessonFileLoaing,
  } = useSelector((s) => s.downloadLessonFileSlicer);

  const [downloadFileLoading , setDownloadFileLoading] = useState();

  // const downloadFile = async (lessonId) => {
  //   try {
  //     setDownloadFileLoading(true)
  //     const response = await axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}`, {
  //       responseType: 'blob', withCredentials : true // Set the response type to blob
  //     });
  //     console.log(response);
  //     // Create a blob from the response data
  //     const blob = new Blob([response.data], { type: response.headers['content-type'] });
  
  //     // Create a temporary URL for the blob
  //     const url = window.URL.createObjectURL(blob);
  
  //     // Create a link element
  //     const link = document.createElement('a');
  //     link.href = url;
  
  //     // Set the file name for the downloaded file
  //     link.setAttribute('download', 'filename.pdf'); // You can change 'filename.pdf' to the desired file name
  
  //     // Append the link to the document body
  //     document.body.appendChild(link);
  
  //     // Trigger a click event on the link to initiate the download
  //     link.click();
  
  //     // Cleanup: remove the link and revoke the temporary URL
  //     link.parentNode.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     toast.error(error);
  //     setDownloadFileLoading(false)
  //   } finally {
  //     setDownloadFileLoading(false)
  //   }
  // };
  


  const [downloadingFilePercent , setDownloadingFilePercent] = useState(0);


  const downloadLessonFileFunc = (lessonId , lessonName) => {
    try {
      axios.get(`${globalLessonsApi}/${lessonId}/download-file` , {
          withCredentials : true,
          responseType: 'blob', // Important: Set response type to blob
          onDownloadProgress : (ProgressEvent) => {
            setDownloadingFilePercent(((ProgressEvent.loaded / ProgressEvent.total) * 100).toFixed())
          }
      })
      .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          if(response.data && response.data.type) {
          if (response.data.type === "application/pdf") {
              link.setAttribute('download', `${lessonName}.pdf`);
          } else {
              link.setAttribute('download', `${lessonName}.zip`);
          }
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          Swal.fire({
              title: "تم بنجاح!",
              text: "!تم تنزيل الملف بنجاح",
              icon: "success"
          });
          setDownloadingFilePercent(0)
          setTimeout(() => window.location.reload() , 1000)
          }
      })
      }
      catch(error) {
      Swal.fire({
          title: "عذرا!",
          text: error,
          icon: "error"
      })
  }
  }
  const checLessonLimitation = (complatingArr ,  countView , watchedCount , element) => {
    console.log(countView , watchedCount)
    if(complatingArr && complatingArr.length >= 1 && ( parseInt(watchedCount) > 0 && parseInt(countView) >= parseInt(watchedCount))) {
      return (
        <p className="relative flex items-center text-sm mr-1 text-red-500">
          تم الوصول للحد الاقصي من المشاهدة
          <img src={limitationIcon} alt="limit icon" className="ml-1 w-[30px]" />
        </p>
      )
    }else {
      return element
    }
  }

  return (
    <>
      <PageHeader headerImg={imgHeader} title={course && course.name && course.name} loadingPage={loading} />
      <section className="course-details">
        <div className="container">
          {loading ? (
            <>
              <div className="row">
                <div className="text-center py-1">
                  <CustomLoaders />
                  <CustomLoaders />
                  <CustomLoaders />
                </div>
              </div>
            </>
          ) : (
            <>
              {error ? (
                <>
                  <div className="row">
                    <div className="text-center py-1">
                      <h2>{error}</h2>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {data && course && (
                    
                      <div className="details-content relative w-full flex flex-col">
                        <div className="relative content-container w-[90%] flex-col md:flex-row mx-auto flex justify-between">
                          <div className={`details-side-bar relative flex flex-col  col-sm-12 col-md-4 py-3 sm:mb-[20px] md:mb-0 rounded-md transition-all duration-500 shadow-md shadow-slate-200 ${mounted ? "translate-y-0 opacity-100" : "translate-y-[20px] opacity-0"}`}>
                            <h2 className="relative text-right px-3 mb-3">لمحه  عن الكورس</h2>
                            <div className="offer-div flex rotate-[10deg] items-center justify-center relative my-[20px]">
                                {
                                    !courseExcution.discount
                                    ?
                                    <h2 className="offer text-3xl font-bold mr-2">&pound;{priceAfterOffer}</h2>
                                    :
                                    <>
                                    <h2 className="offer text-3xl font-bold mr-2">&pound;{priceAfterOffer}</h2>
                                    <del className="old-price text-ls font-bold text-slate-600 mr-3 de">&pound;{courseExcution.price}</del>
                                    <div className="offer-bercent flex justify-center items-center bg-red-200 rounded-sm text-red-500 p-1 mr-3 ">
                                        <p className="bercent-num mr-1">&pound;{courseExcution.discount}</p>
                                        <p className="text-sm ml-1">خصم</p>
                                    </div>
                                    </>
                                }
                            </div>
                            <ul>
                              <li className="relative border-b border-slate-300 mb-2 py-2 px-3 flex items-center justify-end text-right">
                                <p className="m-0 text-right text-sm">
                                  <span className="font-bold ml-1">السعر  :</span>
                                  {courseExcution && courseExcution.price} جنيه
                                </p>
                                <img loading="lazy" src={priceIcon} alt="icon" className="relative w-[20px] ml-2" />
                              </li>
                              <li className="relative border-b border-slate-300 mb-2 py-2 px-3 flex items-center justify-end text-right">
                                <p className="m-0 text-right text-sm">
                                  <span className="font-bold ml-1">عدد الدروس :</span>
                                  {lessons && lessons.length}
                                </p>
                                <img loading="lazy" src={lessonsIcon} alt="icon" className="relative w-[20px] ml-2" />
                              </li>
                              <li className="relative border-b border-slate-300 mb-2 py-2 px-3 flex items-center justify-end text-right">
                                <p className="m-0 text-right text-sm">
                                  {/* {course && course.type} */}
                                  اونلاين
                                  <span className="font-bold ml-1">: الحضور </span>
                                </p>
                                <img loading="lazy" src={typeIcon} alt="icon" className="relative w-[20px] ml-2" />
                              </li>
                              <li className="relative border-b border-slate-300 mb-2 py-2 px-3 flex items-center justify-end text-right">
                                <p className="m-0 text-right text-sm">
                                  <span className="font-bold ml-1">المادة :</span>
                                  {course && course.category && course.category.name && course.category.name}
                                </p>
                                <img loading="lazy" src={categoryIcon} alt="icon" className="relative w-[20px] ml-2" />
                              </li>
                            </ul>
                            <Btn 
                            action={() => additionFunction()}
                            title={
                              addTocartLoading 
                              ? 
                              "..جاري الاضافة" 
                              : 
                              <>
                              اضافة للعربة
                              <i className="fa-solid fa-cart-shopping ml-1"></i>
                              </>
                            } link={"#"}  classes={"w-fit mx-auto text-[20px] mt-3"}
                            />
                          </div>
                          <div className={`about-course relative transition-all duration-500 flex flex-col col-sm-12 col-md-6 ${mounted ? "translate-y-0 opacity-100" : "translate-y-[-20px] opacity-0"}`}>
                            <div className="relative course-img col-sm-12 h-[300px]">
                              <img loading="lazy" src={course && course.image_url !== null ? course.image_url : defaultCourseImg} alt="course img" className="absolute top-0 left-0 w-full h-full transition-all duration-500" />
                            </div>
                            <div className="coures-info relative flex flex-col">
                              <div className="course-name relative flex items-center justify-end col-sm-12 mb-2 mt-3">
                                <p className="m-0 text-sm">{course && course.name && course.name}</p>
                                <h4 className="text-sm m-0">: اسم الكورس </h4>
                              </div>
                              <span className="text-sm col-sm-12 text-right">
                                    تم التحديث موخرا في شهر {updateDay} , فبراير {updateYear}
                              </span>
                              <div className="relative col-sm-12 flex flex-col">
                                <h4 className="m-0 my-3 col-sm-12 text-right">عن الكورس</h4>{course && course.description && <div dangerouslySetInnerHTML={{__html : course.description}} />}
                              </div>
                              <div className="course-name relative flex flex-col items-center justify-end">
                                <h4 className="m-0 my-3 col-sm-12 text-right">معلم الكورس </h4>
                                <div className="relative flex items-center justify-end col-sm-12">
                                  <p className="m-0 text-sm">{course && courseInstructor.name && courseInstructor.name}</p>
                                  <div className="teacher-img relative ml-2 w-[30px] h-[30px] rounded-[50%]">
                                    <img loading="lazy" src={courseInstructor.image_url && courseInstructor.image_url !== null ? courseInstructor.image_url : defaultInstructorImg} alt="instructor img" className="absolute top-0 left-0 w-full h-full" />
                                  </div>
                                </div>
                                <div className="relative flex items-center justify-end col-sm-12 my-2">
                                  <p className="m-0 text-sm">{course && courseInstructor.email && courseInstructor.email}</p>
                                  <i className="fa-solid fa-envelope ml-1"></i>
                                </div>
                                <div className="relative flex items-center justify-end col-sm-12 mb-2">
                                {
                                course && courseInstructor.publish && courseInstructor.publish === "publish"
                                ?
                                <div className="relative flex items-center justify-end col-sm-12 mb-2">
                                  <p className="m-0 text-sm">نشط</p>
                                  <i className="fa-solid fa-temperature-three-quarters text-green-500 ml-2"></i>
                                </div>
                                :
                                <div className="relative flex items-center justify-end col-sm-12 mb-2">
                                  <p className="m-0 text-sm">غير نشط</p>
                                  <i className="fa-solid fa-temperature-three-quarters text-red-500 ml-2"></i>
                                </div>
                                }
                                </div>

                              </div>
                            </div>
                            <div className="relative flex flex-col">
                              <div className="course-name relative flex flex-col col-sm-12 mb-2 mt-3">
                                <h2 className="mb-3 text-right">
                                  فتره السماح بالاشتراك
                                  <i className="fa-solid fa-clock ml-1 opacity-85"></i>
                                </h2>
                                <div className="relative row flex justify-between items-start">
                                  <div className="relative flex flex-col justify-end items-end col-sm-12 col-md-6">
                                    <p className="m-0 mb-1 text-sm">
                                      فتره اشتراك الطالب
                                      <i className="fa-solid fa-graduation-cap ml-1"></i>
                                    </p>
                                    <div className="relative flex flex-col justify-end items-end">
                                      <p className="m-0 text-sm">
                                        بدءا من تاريخ {courseExcution.start_date_student}
                                        <i className="fa-solid fa-calendar-days ml-1"></i>  
                                      </p>
                                      <p className="m-0 text-sm">
                                        حتي نهاية {courseExcution.end_date_student}
                                        <i className="fa-solid fa-calendar-days ml-1"></i>
                                      </p>
                                    </div>
                                  </div>
                                  <div className="relative flex flex-col justify-end items-end col-sm-12 col-md-6">
                                    <p className="m-0 mb-1 text-sm">
                                      فترة السماح للتسجيل
                                      <i className="fa-solid fa-users ml-1"></i>
                                    </p>
                                    <div className="relative flex flex-col justify-end items-end">
                                      <p className="m-0 text-sm"> 
                                        حتي نهاية {courseExcution.end_date_publish}
                                        <i className="fa-solid fa-calendar-days ml-1"></i>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="course-content relative flex flex-col">
                              <h2 className="text-right py-2 mb-2 relative flex items-center justify-end"
                              >
                                محتوي الكورس
                                <i className="fa-brands fa-salesforce ml-2"></i>
                              </h2>
                                <Accordion className="mt-3">
                                {
                                  lessons
                                  &&
                                  lessons.map((lesson , index) => {
                                    const {lesson_schadules} = lesson;
                                    const pivot = lesson_schadules && lesson_schadules.length >= 1
                                    && lesson_schadules[0].pivot;
                                    const compleatingArr = lesson.completed_students && lesson.completed_students.length >=1 && lesson.completed_students[0].pivot && lesson.completed_students
                                    const watchLimit = lesson.completed_students && lesson.completed_students.length >=1 && lesson.completed_students[0].pivot && lesson.completed_students[0].pivot.completion_count
                                    const watchedCount = lesson && lesson.view_count
                                    return (
                                      <Accordion.Item eventKey={index.toString()} className="single-lesson-row">
                                        <Accordion.Header>
                                          <div className="relative flex items-center">
                                            {checkLessonType(lesson.type)}
                                            {
                                              lesson.can_view_without_pay !== 1
                                              &&
                                              <i className="fa-solid fa-lock text-slate-700 mx-2"></i>
                                            }
                                            {lesson.name}
                                          </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        <div className="m-0 text-sm" dangerouslySetInnerHTML={{ __html: lesson.description }}/>
                                          {
                                            lesson.can_view_without_pay === 1
                                            &&
                                            checLessonLimitation(
                                              compleatingArr,
                                              watchLimit ,
                                              watchedCount ,
                                              <SingleFreeLessonBtn
                                                lessonId = {lesson.id}
                                                lessonType={lesson.type}
                                                view_count = {lesson.view_count}
                                                loading={getFreeLessonLoading}
                                                getFreeLesson = {() => getFreeLesson(lesson.id)}
                                                downloadFileLoading = {downloadFileLoading}
                                                startDate = {new Date(pivot.start_date)}
                                                uniqStartDate = {pivot.start_date}
                                                setLessonIdFunc = {() => setTargetLessonId(lesson.id)}
                                                downloadFunc={() => {
                                                  downloadLessonFileFunc(lesson.id , lesson.name)
                                                }}
                                              />
                                            )
                                            // :
                                            // <div className="relative flex items-center">
                                            //   <i className="fa-solid fa-lock mr-1 text-slate-700"></i>
                                            //   <p className="mb-0 text-red-500 text-[15px]">يلزم الاشتراك</p>
                                            // </div>
                                          }
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    )
                                  })
                                }
                                </Accordion>
                            </div>
                          </div>
                        </div>
                      </div>
                  )
                  }
                </>
              )}
            </>
          )}
        </div>
      </section>
      <SubscirbeForm
        show = {showSubscribeForm}
        onHide = {() => setShowSubscribeForm(false)}
        execution_course_id = {excutionId}
      />
      <ShowVideoModal
        title= {freeLessonTitle}
        videoUrl={freeVideoUrl}
        show = {showFreeLesson}
        onHide = {() => setShowFreeLesson(false)}
        lesson_id = {targetLessonId}
      />
        {
          parseInt(downloadingFilePercent) >= 1 && parseInt(downloadingFilePercent) !== 100
          &&
          <div className="fixed transition-all duration-300 top-0 h-[100vh] left-0 w-full z-[99999999] bg-[#263676] flex flex-col justify-center items-center">
            <div className="relative w-[350px] overflow-hidden h-[40px] rounded-md bg-[#cbd5e182]">
                <span className={`absolute flex justify-center items-center bg-[#0f60a3] text-stone-50 transition-all duration-500 top-0 left-0 h-full`} style={{ width: `${downloadingFilePercent}%` }}>
                    {
                        parseInt(downloadingFilePercent) >= 1
                        &&
                        `${parseInt(downloadingFilePercent)}%`
                    }
                </span>
            </div>
            <p className="text-stone-50 mt-2">جاري التنزيل..</p>
          </div>
        }
    </>
  );
}

