import { useDispatch, useSelector } from "react-redux";
import verifyImg from "../../images/undraw_Authentication_re_svpt.png";
import { postVeryfyEmailFunc } from "../../store/slices/users/PostVerifyEmailSlicer";
import { useState } from "react";
import OverLayLoading from "../../component/global/overlay-loading/OverLayLoading";
function VerifyEmailPage() {
    const verifyEmailApi = `${process.env.REACT_APP_PUBLIC_API}/api/email/verification-notification`;
    const dispatch = useDispatch();
    const [sendingAgain , setSendingAgaing] = useState("");
    const [sent , setSent] = useState(false)
    const {loading:postVerifingLoading} = useSelector(
        state => state.postVeryfyEmailSlicer
    )
    const [sendingMessage , setSendingMessage] = useState("يرجي تاكيد البريد الالكتروني!")
    const sendVerify = () => {
        setSent(true)
        dispatch(postVeryfyEmailFunc({api : verifyEmailApi , data : {}}))
        .then(result => {
            setSent(false)
            console.log(result)
            if(result.payload.success === true) {
                setSendingMessage("تم ارسال الكود بنجاح!");
            }
        })
    }
    return (
        <>
            <div className="page-content relative w-full h-[100vh]">
                <div className="page-container relative w-[90%] mx-auto flex flex-col justify-center items-center h-full">
                    <img src={verifyImg} alt="verify img" className="relative mb-3 w-[200px]" />
                    <div className="sending-conetnt relative flex flex-col justify-center items-center">
                        <h3 className="mb-3">{sendingMessage}</h3>
                        <button className={`relative mt-3 bg-primary text-white py-2 px-3 rounded-md bg-[#6c63ff] flex justify-center items-center ${postVerifingLoading ? "pointer-events-none opacity-70" : ""} overflow-hidden`}
                            onClick={() => {
                                sendVerify()
                            }}
                        >
                            ارسال كود التاكيد
                            <i className={`fa-solid fa-paper-plane mr-1 transition-all duration-500 ${sent ? "translate-x-[30px] translate-y-[-30px]" : "translate-x-0 translate-y-0"}`}></i>
                        </button>
                    </div>
                </div>
            </div>
        <OverLayLoading loading={postVerifingLoading} loadingtext={"جاري الارسال.."}/>
        </>
    )
}
export default VerifyEmailPage;