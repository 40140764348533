import { useEffect, useRef, useState } from "react";
import OdometerCustom from "../../../component/ui/OdometerCustom";
import RegistrationFrom from "./ui/RegistrationFrom";

import registration_one_bg from "../../../images/photo-1704098622887-1032175fee07.webp";
export default function Registeration() {
  return (
    <>
      <section
        className="registration-one jarallax"
        data-jarallax
        data-speed="0.2"
        data-imgposition="50% 0%"
      >
        <div
          className="registration-one__bg"
          style={{
            backgroundImage:
              `url(${registration_one_bg})`,
          }}
        />
        <div className="resgister-container">
          <div className="row">
            {/*Start Registration One Left*/}
            <div className="col-xl-7 col-lg-7">
              <div className="registration-one__left">
                <div className="section-title">
                  <span className="section-title__tagline">
                   سجل مجانا معنا الان
                  </span>
                  <h2 className="text-white sm:text-[30px] md:text-[45px] font-bold">
                  احتل مكانك بين الأوائل: انضم إلينا اليوم وابدأ رحلتك نحو النجاح
                  </h2>
                </div>
                <p className="registration-one__left-text">
                تمتع بفعاليات تعليمية تفاعلية وموارد غنية، حيث يقوم معلمونا بتوجيهك خطوة بخطوة نحو التميز. سنقدم لك تجربة تعلم شيقة ومحفزة تحمل في طياتها فرصًا لا حصر لها.
                </p>
              </div>
            </div>
            {/*End Registration One Left*/}
            {/*Start Registration One Right*/}
            <div className="col-sm-12 col-md-5">
              <div
                className="registration-one__right wow slideInRight"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
              >
               <RegistrationFrom />
              </div>
            </div>
            {/*End Registration One Right*/}
          </div>
        </div>
      </section>
    </>
  );
}
