import { useEffect, useState } from "react";
import { Link, json } from "react-router-dom";
import StudentDecisions from "./StudentDescion";
import avatar_profile from "../../../images/avatar/avatar_profile.png";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../../store/slices/users/profileSlicer";
import StudentCourseCard from "./StudentCourseCard";
import UpdateUserImageForm from "./ChangeImageForm";
import PageHeader from "../../../component/ui/PageHeader";
import { editUserImageFunc } from "../../../store/slices/users/ChangeImageSlicer";
import SendEmailModal from "../../../component/global/global-modals/ForgotPassModal";
import { getStudentCoursesFunc } from "../../../store/slices/courses/GetStudentCoursesSlicer";
export default function PersonInformation() {
  const userData = JSON.parse(localStorage.getItem("userdata")).data.student;
  const [user, setUser] = useState(null);
  const [showChangeForm, setShowChangeForm] = useState();
  const [showImageForm, setShowImageForm] = useState();
  const [allExcutions, setAllExcutions] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);
  const dispatch = useDispatch();
  const {
    loading: profileLoading,
    data: profileData,
    error: profileError,
  } = useSelector((s) => s.profileSlicer);
  const {
    loading: getCoursesLoading,
    data: coursesData,
    // error: profileError,
  } = useSelector((s) => s.getStudentCoursesSlicer);

  const [invoicesList, setInvoicesList] = useState([]);
  const [studentData, setStudentData] = useState({});
  const [showChangePass, setShowChangePass] = useState();

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;

  const refetchProfiledata = async () => {
    try {
      dispatch(getProfileData(profileApi))
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getProfile = async () => {
      try {
        dispatch(getProfileData(profileApi))
        dispatch(getStudentCoursesFunc())
      } catch (error) {
        console.log(error);
      }
    };
    getProfile();
  }, []);

  useEffect(() => {
    // profileData && console.log(profileData)
    profileData && setStudentData(profileData);
    profileData && setInvoicesList(profileData.invoices);
  }, [profileData , invoicesList]);
  useEffect(() => {
    coursesData && console.log(coursesData)
    coursesData && setStudentCourses(coursesData)
  },[coursesData])
  useEffect(() => console.log(studentCourses) , [studentCourses])
  const [allExecutions , setAllExecutions] = useState([]);
  const [uniqAllExecutions , setuniqAllExecutions] = useState([]);

  useEffect(() => {
    if (invoicesList && invoicesList.length >= 1) {
      setAllExecutions(prevCoursesList => {
        // Map over each invoice in the invoicesList
        const updatedCoursesList = invoicesList.reduce((accumulator, invoice) => {
          // Extract course_executions from each invoice
          const { course_executions } = invoice;
          // Concatenate the course_executions to the accumulator
          return [...accumulator, ...course_executions];
        }, prevCoursesList); // Start with the previous state of coursesList
  
        return updatedCoursesList; // Set the state to the updated coursesList
      });
    }
  }, [invoicesList]);
  

  useEffect(() => {
    const uniq = new Set();
    allExecutions.map((ex) => {
      uniq.add(JSON.stringify(ex))
    })
    const uniqExecutionsObjects = Array.from(uniq).map(ex => JSON.parse(ex));
    setuniqAllExecutions(uniqExecutionsObjects)
  },[allExecutions])



  const checkStudentStatus = (status) => {
    if(status) {
      switch (status) {
        case "publish" :
          return <span className="relative rounded-md bg-green-500 text-white py-1 px-2 text-sm">نشط</span>
        case "un_publish" :
          return <span className="relative rounded-md bg-red-500 text-white py-1 px-2 text-sm">غير نشط</span>
        default :
          console.log("no status")
      }
    }
  }


  return (
    <>
    <PageHeader headerImg={"https://images.pexels.com/photos/6373308/pexels-photo-6373308.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} title={"الملف الشخصي"}/>
    {
      profileData
      &&
      // <div id="page-content">
      //   <h1>akja</h1>
      //   <div className="tutor-wrap tutor-wrap-parent tutor-full-width-student-profile tutor-page-wrap tutor-user-public-profile tutor-user-public-profile-pp-rectangle tutor-instructor post-48 post type-post status-publish format-standard has-post-thumbnail hentry category-bussiness category-life-style tag-courses tag-education tag-programing">
      //     <div className="tutor-container photo-area relative">
      //       <StudentDecisions 
      //         customClasses={"bg-red-500 absolute left-[20px] bottom-0"}
      //         showPasswordForm = {showPasswordForm}
      //         showImageForm = {showImageFormFunc}
      //       />
      //       <div className="cover-area">
      //         <div
      //           style={{
      //             backgroundImage: `url(https://images.pexels.com/photos/956981/milky-way-starry-sky-night-sky-star-956981.jpeg?auto=compress&cs=tinysrgb&w=600)`,
      //           }}
      //         />
      //         <div />
      //       </div>
      //       <div className="pp-area rounded-[50%]">
      //         <div
      //           className="profile-pic relative right-1"
      //           style={{
      //             backgroundImage: `url(${profileData.image_url !== null ? profileData.image_url : avatar_profile})`,
      //           }}
      //         />

      //         <div className="tutor-social-container content-for-mobile"></div>
      //         <div className="profile-rating-media content-for-desktop">
      //           <div className="tutor-social-container content-for-desktop"></div>
      //         </div>
      //       </div>
      //     </div>
      //     <div className="tutor-container flex flex-col" style={{ overflow: "auto" }}>
      //       <div className=" p-3 shadow-md shadow-slate-200 mt-[70px] rounded-md w-full">
      //         <p className="flex items-center mb-2"><i className="ml-2 fa-solid fa-id-card"></i> {userData !== null ? userData.name : "اسم الطالب "}</p>
      //         <p className="flex items-center mb-2"> <i className="ml-2 fa-solid fa-envelope"></i> {userData !== null ? userData.email : "البريد الالكتروني "} </p>
      //         <p className="flex items-center mb-2"><i className="ml-2 fa-solid fa-phone"></i> {userData !== null ? userData.phone : "رقم الهاتف "}</p>
      //       </div>
      //       <div className="tutor-user-profile-content tutor-d-block tutor-mt-72">
      //         <h3>الكورسات المسجلة</h3>
      //         <div className="tutor-courses">
      //           {profileLoading ? (
      //             <>
      //               <div
      //                 className="spinner-border text-primary"
      //                 role="status"
      //               ></div>
      //             </>
      //           ) : (
      //             <div className="courses-section relative flex flex-wrap">
      //               {profileData && allExcutions && allExcutions.length > 0 ? (
      //                 <>
      //                   {allExcutions.map((excution, index) => {
      //                     console.log(excution)
      //                     const {
      //                       course,
      //                       price , 
      //                       discount_price,
      //                       start_date_student , 
      //                       end_date_student , 
      //                       date_student_status,
      //                     } = excution
      //                     console.log(course)
      //                     return (
      //                     <StudentCourseCard
      //                       key={index}
      //                       index={index}
      //                       courseId={course.id}
      //                       courseImg={course.image_url}
      //                       price = {price}
      //                       courseName={course.name}
      //                       startDate={start_date_student}
      //                       endDate={end_date_student}
      //                       loading = {profileLoading}
      //                       teacherName={course.user && course.user.name}
      //                       teacherImg={course.user && course.user.image_url}
      //                       courseStatus={date_student_status}
      //                     />
      //                     )
      //                   }
      //                   )}
      //                 </>
      //               ) : (
      //                 <>
      //                   <div className="alert alert-primary">
      //                     لا يوجد كورسات بعد .
      //                   </div>
      //                 </>
      //               )}
      //             </div>
      //           )}
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
      <div className="page-content relative w-full py-5">
        <div className="relative w-[90%] mx-auto flex flex-col">
          <div className="user-images relative w-full mb-3">
            <div className="relative w-full h-[350px] user-cover rounded-md overflow-hidden">
              <img src={"https://images.unsplash.com/photo-1704098623946-2f273eeeead2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="cover img" className="absolute top-0 left-0 w-full h-full"/>
            </div>
            <div className="relative">
              <div className="personal-image absolute bottom-[-50px] right-[30px] rounded-[50%] overflow-hidden w-[100px] h-[100px] border-[7px] border-white">
                <img src={studentData.image_url ? studentData.image_url : "https://images.unsplash.com/photo-1704098623946-2f273eeeead2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="img" className="absolute top-0 left-0 w-full h-full"/>
              </div>
              <div className=" w-fit z-[2] absolute bottom-[-40px] right-[23px]">
                <label className="relative rounded-[50%] bg-slate-700 text-white cursor-pointer w-fit p-2 flex justify-center items-center"
                  onClick={() => setShowImageForm(true)}
                >
                  <i class="fa-solid fa-camera"></i>
                </label>
              </div>
            </div>
            </div>
          <div className="user-data relative mb-2 mt-4 py-2">
            <div className="relative data-content flex flex-col">
              <div className="relative flex items-center mt-2">
                <i className="fa-brands fa-cuttlefish ml-1"></i>
                <h5>الاسم : {studentData.name}</h5>
              </div>
              <div className="relative flex items-center mt-2">
                <i className="fa-solid fa-envelope ml-1"></i>
                <h5>البريد الالكتروني : {studentData.email}</h5>
              </div>
              <div className="relative flex items-center mt-2">
                <i className="fa-solid fa-square-phone-flip ml-1"></i>
                <h5>رقم الموبايل : {studentData.phone}</h5>
              </div>
              <div className="relative flex items-center mt-2">
                <i className="fa-solid fa-signal ml-1"></i>
                <h5>حالة الطالب : {checkStudentStatus(studentData.publish)}</h5>
              </div>
              <div className="relative flex items-center gap-2 mt-4">
                <Link 
                className="btn w-fit bg-[#153776] text-white flex items-center" 
                to="#"
                onClick={() => {
                  setShowChangePass(true)
                }}
                >
                <i className="fa-solid fa-key ml-1"></i>
                تغيير كمة المرور
                </Link>
                <Link className="btn w-fit bg-[#4d5fe3] text-white flex items-center" to={`invoices/${studentData.id}`}>
                <i className="fa-solid fa-money-check-dollar ml-1"></i>
                عمليات الدفع
                </Link>
              </div>
            </div>
          </div>
          <div className="courses-content relative flex flex-wrap sm:justify-center lg:justify-start">
          {profileData && studentCourses && studentCourses.length > 0 ? (
              <>
                {
                studentCourses.map((course, index) => {
                  const {
                    courseExecution,
                    // course,
                    // pivot, 
                    // start_date_student , 
                    // end_date_student , 
                    // date_student_status,
                  } = course
                  const {title , start_date_student , end_date_student , pivot ,  date_student_status , course:studentCourse} = courseExecution
                  const {price , descount  , course_execution_id} = pivot
                  return (
                  <StudentCourseCard
                    key={index}
                    index={index}
                    courseDiscount = {descount}
                    courseId={studentCourse.id}
                    courseImg={studentCourse.image_url}
                    price = {price}
                    courseName={studentCourse.name}
                    exName={title}
                    startDate={start_date_student}
                    endDate={end_date_student}
                    loading = {profileLoading}
                    teacherName={course.user && course.user.name}
                    teacherImg={course.user && course.user.image_url}
                    courseStatus={date_student_status}
                    courseExId = {course_execution_id}
                  />
                  )
                }
                )}
              </>
            ) : (
              <>
                <div className="alert alert-primary">
                  لا يوجد كورسات بعد .
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    }
      <UpdateUserImageForm
          show={showImageForm}
          onHide={() => setShowImageForm(false)} 
          refetch_data= {() => refetchProfiledata()}
      />
      <SendEmailModal
          show={showChangePass}
          onHide={() => setShowChangePass(false)} 
          // refetch_data= {() => getProfile()}
      />
    </>
  );
}
