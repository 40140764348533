import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
export const getVerifyEmailFunc = createAsyncThunk(
    "get verify email function",
    async ({api , data}) => {
        try {
            const response = await axios.get(api , { params : data , withCredentials : true});
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data;
        }
        catch (error) {
            return error;
        }
        
    }
)

const getVerifyEmailSlicer = createSlice({
    name : "get verify email slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(getVerifyEmailFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(getVerifyEmailFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(getVerifyEmailFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default getVerifyEmailSlicer.reducer;
