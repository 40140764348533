import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../component/ui/PageHeader";
import headerImage from "../../images/cart-page/photo-1704098622814-14065965af9a.avif"
import couponeIcon from "../../images/cart-page/coupon.png"
import { getCartFunc } from "../../store/slices/cart-actions/GetCartSlicer";
import { useEffect, useState } from "react";
import CustomLoaders from "../../component/ui/CustomLoaders";
import { deleteFromCartFunc } from "../../store/slices/cart-actions/DeleteFromCardSlicer";
import Swal from "sweetalert2";
import faildImg from "../../images/planet.png";
import { Link, useNavigate } from "react-router-dom";
import OverLayLoading from "../../component/global/overlay-loading/OverLayLoading";
import { applayCouponeFunc } from "../../store/slices/copons-actions/ApplayCouponSlicer";
import "./cart-page.css";
import CheckoutModal from "../../component/global/global-modals/CheckoutModal";
import { Accordion } from "react-bootstrap";
import visaIcon from "../../images/cart-page/visa (1).png";
import masterCardIcon from "../../images/cart-page/master-card.png";
import mizaCardIcon from "../../images/cart-page/miza-card.png";
import { checkoutFunc } from "../../store/slices/checkout-actions/CheckoutSlicer";
import electronicWalletIcon from "../../images/cart-page/mobile-payment.png";
import electronicWalletIcon2 from "../../images/cart-page/electronic-wallet.png";
import sendingImg from "../../images/cart-page/photo.png";
function CartPage() {
    const dispatch = useDispatch();
    const cartApi = `${process.env.REACT_APP_PUBLIC_API}/api/carts`;
    const couponsApi = `${process.env.REACT_APP_PUBLIC_API}/api/aplly-coupon`;
    const {loading:getCartDataLoading , data:cartData} = useSelector(
        state => state.getCartSlicer
    )
    const {loading:deleteProductLoading} = useSelector(
        state => state.deleteFromCartSlicer
    )
    const {loading:applayCoupneLoading , data:coupon} = useSelector(
        state => state.applayCouponeSlicer
    )
    const {loading:checkoutLoading} = useSelector(
        state => state.checkoutSlicer
    )
    const [cartList , setCartList] = useState([]);
    const [couponCode , setCouponCode] = useState("");
    const [couponInput , setCouponInput] = useState("");
    const [couponeDescount , setCouponeDescount] = useState();
    const [totalPrice , setTotalPrice] = useState();
    const [totalDiscount , setTotalDiscount] = useState();
    const [subTotalPrice , setSubTotalPrice] = useState();
    // const [showCheckoutModal , setShowCheckoutModal] = useState();
    const [couponDiscountValue , setCouponDiscountValue] = useState("");
    const [descountType , setDescountType] = useState("");
    const [thereIsCouponDescount , setThereIsCouponDescount] = useState();
    const navigate = useNavigate()
    // const {coupone , reset_cart} = props;
    const [receiptImage , setReceiptImage] = useState("");
    // const [paymentType , setPaymentType] = useState("");
    const [walletNumber , setWalletNumber] = useState("");
    const checkoutApi = `${process.env.REACT_APP_PUBLIC_API}/api/checkout`;
    const getCartData = () => {
        try {
            dispatch(getCartFunc(cartApi))
        }
        catch (error) {
            Swal.fire({
                title: "عذرا!",
                text: error,
                icon: "warning"
            });
        }
    }
    useEffect(() => {
        getCartData()
    },[])
    useEffect(() => {
        cartData && setCartList(cartData)
        const allCartExecutions = cartData && cartList.length >= 1 && cartList.flatMap(cart => cart.course_execution)
        const allPrices = cartData && allCartExecutions.length >= 1 && allCartExecutions.flatMap(execution => execution.price)
        const allDiscounts = cartData && allCartExecutions.length >= 1 && allCartExecutions.flatMap(execution => execution.discount)
        const calcTotalPrice = cartData && cartList.length >= 1 && allPrices.reduce((current , nn) => {
            return parseInt(current) + parseInt(nn)
        });
        const calcTotalDiscount = cartData && cartList.length >= 1 && allDiscounts.reduce((current , nn) => {
            return parseInt(current) + parseInt(nn)
        });
        setTotalPrice(calcTotalPrice)
        setTotalDiscount(calcTotalDiscount)
    },[cartData , cartList])
    useEffect(() => {
        setSubTotalPrice(totalPrice - totalDiscount)
    },[totalPrice , totalDiscount])
    const deleteFromCart = (cartId) => {
        const deleteFunc = () => {
            try {
                dispatch(deleteFromCartFunc(`${cartApi}/${cartId}`))
                .then(result => {
                    if(result.payload.success === true) {
                        getCartData()
                    }
                })
            }
            catch(error) {
                Swal.fire({
                    title: "عذرا!",
                    text: error,
                    icon: "warning"
                });
            }
        }
        Swal.fire({
            title: "تاكيد الحذف",
            text: `هل انت متاكد من حذف هذا الكورس من سلة الشراء!`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "الغاء",
            confirmButtonText: "حذف"
          }).then((result) => {
            if (result.isConfirmed) {
                deleteFunc()
            }
          });
    }

    const applayCoupne = () => {
        dispatch(applayCouponeFunc({api : couponsApi , data : {coupon_code : couponCode}}))
        .then(result => {
            console.log(result)
            if(result.payload.code) {
                setThereIsCouponDescount(true)
                setCouponInput("");
            }else {
                setThereIsCouponDescount(false)
            }
        })
    }
    useEffect(() => {
        coupon && setCouponCode(coupon.code);
        coupon && setCouponDiscountValue(coupon.discount);
        coupon && setDescountType(coupon.type);
        coupon && setCouponeDescount(coupon)
    },[coupon])
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    useEffect(() => {
        if(couponDiscountValue !== "" && descountType !== "") {
            if(descountType === "fixed") {
                const priceAfterCouponDescount = parseInt(subTotalPrice) - parseInt(couponDiscountValue);
                setSubTotalPrice(priceAfterCouponDescount)
            }else {
                const descountMount = (parseInt(couponDiscountValue) * parseInt(subTotalPrice)) / 100;
                const priceAfterCouponDescount = parseInt(subTotalPrice) - descountMount;
                setSubTotalPrice(priceAfterCouponDescount)
            }
        }
    },[couponDiscountValue , descountType])
    

    const checkout = (e , paymentType) => {
        e.preventDefault()
        const paymentForm = new FormData();
        paymentForm.append("type_payment" , paymentType);
        receiptImage !== "" && paymentForm.append("receipt_image" , receiptImage);
        walletNumber !== "" && paymentForm.append("wallet_number" , walletNumber);
        couponCode !== "" && paymentForm.append("coupon_code" , couponCode);
        dispatch(checkoutFunc({api : checkoutApi , data : paymentForm}))
        .then(result => {
            if(result.payload.success === true) {
                console.log(paymentType)
                if(paymentType === "send_receipt"){
                    // setPaymentType("");
                    setReceiptImage("");
                    setWalletNumber("");
                    // props.onHide();
                    // reset_cart();
                    navigate("profile");
                }else {
                    if(result.payload.data.response) {
                        window.location.replace(result.payload.data.response)
                    }
                }
            }
        })
    }
    return (
        <>
        <PageHeader headerImg={headerImage} title={"سلة الشراء"}/>
            <div className="content relative w-full">
                {
                    getCartDataLoading
                    ?
                    (
                    <div className="text-center py-5">
                        <CustomLoaders />
                        <CustomLoaders />
                        <CustomLoaders />
                        <CustomLoaders />
                    </div>
                    )
                    :
                    (
                        cartData
                        ?
                        (
                            cartList
                            &&
                            cartList.length >= 1
                            ?
                            (
                                <>
                                    <table className="responsive w-[90%] mx-auto my-3 rounded-md bg-[#f9f9f9">
                                        <thead>
                                            <tr>
                                                <th className="border-b text-slate-700 font-normal text-[15px] border-slate-200">
                                                    <i className="fa-brands fa-cuttlefish ml-1"></i>
                                                    الاسم
                                                </th>
                                                <th className="border-b text-slate-700 font-normal text-[15px] border-slate-200">
                                                    <i className="fa-brands fa-discourse ml-1"></i>
                                                    الكورس
                                                </th>
                                                <th className="border-b text-slate-700 font-normal text-[15px] border-slate-200">
                                                    <i className="fa-regular fa-money-bill-1 ml-1"></i>
                                                    السعر
                                                </th>
                                                <th className="border-b text-slate-700 font-normal text-[15px] border-slate-200">
                                                    <i className="fa-solid fa-tag ml-1"></i>
                                                    الخصم
                                                </th>
                                                <th className="border-b text-slate-700 font-normal text-[15px] border-slate-200">
                                                    <i className="fa-solid fa-ban ml-1"></i>
                                                    حذف
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            cartList.map((cart , index) => {
                                                const {course_execution} = cart;
                                                const {course , price , discount , title} = course_execution;
                                                return (
                                                    <tr key={index}>
                                                        <td className="border-slate-200 text-[15px]">{title}</td>
                                                        <td className="border-slate-200 text-[15px]">{course.name}</td>
                                                        <td className="border-slate-200 text-[15px]">{price}&pound;</td>
                                                        <td className="border-slate-200 text-[15px]">{discount ? <>{discount}&pound;</> : "لايوجد خصومات"}</td>
                                                        <td className="border-slate-200 text-[15px]">
                                                            <section class="flex justify-center items-center">
                                                                <button
                                                                href="/"
                                                                class="group flex justify-center p-2 rounded-md drop-shadow-xl bg-[#7289da] from-gray-800 to-black text-white font-semibold hover:rounded-[50%] transition-all duration-500 hover:from-[#331029] hover:to-[#310413]"
                                                                onClick={() => deleteFromCart(cart.id)}
                                                                >
                                                                <i className="fa-solid fa-trash text-white"></i>
                                                                </button>
                                                            </section>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                    <div className="relative my-2 w-[90%] mx-auto flex justify-between py-4 row">
                                        <div className="price-side relative flex flex-col p-2 col-sm-12 col-md-6 bg-[#ffffff] shadow-slate-100 rounded-md justify-center items-center">
                                            <h4 className="my-2">طرق الدفع</h4>
                                            <div className="relative  w-full">
                                                <div className="price flex justify-between mb-2 py-3 px-2 border-b border-[#c0c0c0]">
                                                    <h5 className="price-title text-[16px]">اجمالي</h5>
                                                    <h5 className="price-title text-[16px]">{totalPrice}&pound;</h5>
                                                </div>
                                                {
                                                    thereIsCouponDescount
                                                    &&
                                                    <div className="price flex justify-between mb-2 py-3 px-2 border-b border-[#c0c0c0]">
                                                        <h5 className="price-title text-[16px]">خصم الكوبون</h5>
                                                        {
                                                            descountType === "fixed"
                                                            ?
                                                            <h5 className="price-title text-blue-500 text-[16px] flex items-center">
                                                                <span className="relative text-[12px] text-red-500 translate-y-[12px]">{(couponDiscountValue / totalPrice) * 100} %</span>
                                                                <span className="relative flex items-center">
                                                                    <img src={couponeIcon} alt="icon" className="relative ml-2 w-[30px]" />
                                                                    {couponDiscountValue}&pound;
                                                                </span>
                                                            </h5>
                                                            :
                                                            <h5 className="price-title text-blue-500 text-[16px] flex items-center">
                                                                <span className="relative text-[12px] text-red-500 translate-y-[12px]">{(totalPrice * (couponDiscountValue / 100)).toFixed(2)} &pound;</span>
                                                                <span className="relative flex items-center">
                                                                    <img src={couponeIcon} alt="icon" className="relative ml-2 w-[30px]" />
                                                                    {couponDiscountValue} %
                                                                </span>
                                                            </h5>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    thereIsCouponDescount
                                                    &&
                                                    <div className="price flex justify-between mb-2 py-3 px-2 border-b border-[#c0c0c0]">
                                                        <h5 className="price-title text-[16px]">السعر بعد الخصم</h5>
                                                        <h5 className="price-title text-[16px]">{subTotalPrice}&pound;</h5>
                                                    </div>
                                                }
                                                {/* <div class="payment-container mx-auto my-3"
                                                    onClick={() => setShowCheckoutModal(true)}
                                                >
                                                <div class="left-side">
                                                    <div class="card">
                                                    <div class="card-line flex items-center text-[8px] text-white">xxx xx xxx xx xx</div>
                                                    <div class="buttons"></div>
                                                    </div>
                                                    <div class="post">
                                                    <div class="post-line"></div>
                                                    <div class="screen">
                                                        <div class="dollar">&pound;</div>
                                                    </div>
                                                    <div class="numbers"></div>
                                                    <div class="numbers-line2"></div>
                                                    </div>
                                                </div>
                                                <div class="right-side">
                                                    <div class="new text-[17px]">شراء الان</div>
                                                </div>
                                                </div> */}


                                            <Accordion className="payments-accordin">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header className="border-2 border-blue-300 p-1 ">
                                                    <div className='relative flex items-center'>
                                                        <img src={visaIcon} className='relative my-2 w-[30px] mx-1' alt="icon" />
                                                        <img src={masterCardIcon} className='relative my-2 w-[30px] mx-1' alt="icon" />
                                                        <img src={mizaCardIcon} className='relative my-2 w-[30px] mx-1' alt="icon" />
                                                    </div>
                                                        {/* <p>قم بالشراء بواسطه الفيزه كارت</p> */}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                    <form
                                                    id="payment-visa-form"
                                                    className='relative flex flex-col justify-center items-center'
                                                    onSubmit={(e) => {
                                                        checkout(e , "bank_card");
                                                    }}
                                                    >
                                                        <button type='submit' form='payment-visa-form' class="payment-container mx-auto my-3"
                                                            // onClick={() => checkout()}
                                                        >
                                                        <div class="left-side">
                                                            <div class="card">
                                                            <div class="card-line flex items-center text-[8px] text-white">xxx xx xxx xx xx</div>
                                                            <div class="buttons"></div>
                                                            </div>
                                                            <div class="post">
                                                            <div class="post-line"></div>
                                                            <div class="screen">
                                                                <div class="dollar">&pound;</div>
                                                            </div>
                                                            <div class="numbers"></div>
                                                            <div class="numbers-line2"></div>
                                                            </div>
                                                        </div>
                                                        <div class="right-side">
                                                            <div class="new text-[17px]">شراء الان</div>
                                                        </div>
                                                        </button>
                                                    </form>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header className="border-2 border-blue-300 mt-1">
                                                    <div className='icon relative flex justify-center items-center ml-2'>
                                                        <p>محافظ الكترونية</p>
                                                        <img src={electronicWalletIcon2} alt="icon" className='relative my-2 w-[30px] mr-2' />
                                                    </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                    <form
                                                        id="send-wallet-number-form"
                                                        onSubmit={(e) => {
                                                            checkout(e , "electronic_wallet");
                                                        }}
                                                        >
                                                            <div className='relative flex flex-col justify-center items-center'>
                                                                <div className='icon relative flex flex-col justify-center items-center'>
                                                                    <img src={electronicWalletIcon2} alt="icon" className='relative my-2 w-[40px]' />
                                                                    <p>قم بادخال رقم محفظه الدفع</p>
                                                                </div>
                                                                <input className='relative rounded-md placeholder:text-slate-300 p-2 my-2 form-control w-[90%]' placeholder='01XXXXXXXX' 
                                                                    onChange={(e) => setWalletNumber(e.target.value)}
                                                                />
                                                                <button 
                                                                type='submit' 
                                                                form='send-wallet-number-form' 
                                                                class={`relative group cursor-pointer text-sky-50  overflow-hidden h-13 w-56 my-3 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold ${checkoutLoading ? "pointer-events-none opacity-50" : ""}`}>
                                                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-40 h-40 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-900"></div>
                                                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-32 h-32 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-800"></div>
                                                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-24 h-24 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-700"></div>
                                                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-14 h-14 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-600"></div>
                                                                    <p class="z-10">ارسال الان</p>
                                                                </button>
                                                            </div>
                                                        </form>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header className="border-2 border-blue-300 mt-1">
                                                        <div className='icon relative flex justify-center items-center ml-2'>
                                                            <p>ارسال ايصال الدفع</p>
                                                            <img src={sendingImg} alt="icon" className='relative my-2 w-[30px] mr-2' />
                                                        </div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <form 
                                                            onSubmit={(e) => {
                                                                
                                                                checkout(e , "send_receipt");
                                                            }}
                                                            id="send-recipct-form" 
                                                            class="file-upload-form activation-form mt-3 flex items-center justify-center flex-col">
                                                            <label for="file" class="file-upload-label border-dashed border-slate-400 cursor-pointer">
                                                                <div class="file-upload-design flex flex-col items-center justify-center gap-2">
                                                                <svg viewBox="0 0 640 512" class="h-10 text-gray-500 fill-current">
                                                                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                                                                </svg>
                                                                {
                                                                    receiptImage &&
                                                                    receiptImage !== "" &&
                                                                    receiptImage.name
                                                                    ?
                                                                    receiptImage.name
                                                                    :
                                                                    <p>قم بتحميل صوره ايصال الدفع</p>
                                                                }
                                                                <span class="browse-button bg-gray-700 px-4 py-2 rounded-lg text-white transition duration-300 hover:bg-gray-900">تحميل صوره</span>
                                                                </div>
                                                                <input id="file" type="file" class="hidden" accept='image/*' 
                                                                    onChange={(e) => {
                                                                        setReceiptImage(e.target.files[0])
                                                                        console.log(e.target.files[0])
                                                                    }}
                                                                />
                                                            </label>
                                                            {
                                                                receiptImage !== ""
                                                                &&
                                                                <button 
                                                                type='submit' 
                                                                form='send-recipct-form' 
                                                                class={`relative group cursor-pointer text-sky-50  overflow-hidden h-13 w-56 my-3 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold ${checkoutLoading ? "pointer-events-none opacity-50" : ""}`}>
                                                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-40 h-40 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-900"></div>
                                                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-32 h-32 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-800"></div>
                                                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-24 h-24 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-700"></div>
                                                                    <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-14 h-14 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-600"></div>
                                                                    <p class="z-10">ارسال الان</p>
                                                                </button>
                                                            }
                                                        </form>
                                                        </Accordion.Body>
                                                </Accordion.Item>
                                                </Accordion>

                                            </div>
                                        </div>
                                        <div className="copone-side relative col-sm-12 col-md-5 flex flex-col justify-center items-center mt-3 md:mt-0">
                                            <img src={couponeIcon} alt="icon" className="relative my-2 w-[60px]" />
                                            <h5 className="mb-2">قم بادخال الكوبون</h5>
                                            <div className="relative flex flex-col justify-center items-center w-full">
                                                <input 
                                                    className="relative bg-slate-100 w-full md:w-[90%] px-2 py-[9px] rounded-sm"
                                                    placeholder="XXXXX" 
                                                    onChange={(e)=>{
                                                        setCouponCode(e.target.value)
                                                        setCouponInput(e.target.value)
                                                    }}
                                                    value={couponInput}
                                                />
                                                <button class={`btn applay-coupone-btn mt-3 ${(couponInput !== "") ? "active" : "opacity-50 pointer-events-none"} ${applayCoupneLoading ? "pointer-events-none opacity-45" : ""}`}
                                                onClick={() => applayCoupne()}
                                                >
                                                    {
                                                        applayCoupneLoading
                                                        ?
                                                        "جاري التحقق"
                                                        :
                                                        "تحقق"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                            :
                            <div className="container empty-cart-container w-[90%] mx-auto flex justify-center items-center flex-col my-4">
                                <h2>السلة فارغة.</h2>
                                <img src="https://cdn.shopify.com/s/files/1/0132/3116/1408/files/cart.png?13612" alt="cart-img" className="opacity-90 w-[100px] my-3" />
                                <p>لم يتم وضع كورسات حتي الان.</p>
                                <Link to="courses" className="flex items-center mt-2">
                                    <p>ابدا التعلم</p>
                                    <i className="fa-solid fa-caret-left mr-1"></i>
                                </Link>
                            </div>
                        )
                        :
                        <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                            <img 
                            loading="lazy"
                            src={faildImg}
                            alt="faild img"
                            className="w-[150px] mb-3"
                            />
                            <p>
                                تعذر تحميل سلة الشراء <span className="text-primary" 
                                onClick={() => getCartData()}
                                >اعادة المحاولة</span>
                            </p>
                        </div>
                    )
                }
            </div>
            <OverLayLoading loading={deleteProductLoading} loadingtext={"جاري الحذف.."}/>
            {/* <CheckoutModal
                show = {showCheckoutModal}
                onHide = {() => setShowCheckoutModal(false)}
                coupone = {couponCode}
                reset_cart = {()=>getCartData()}
            /> */}
            <OverLayLoading loading={checkoutLoading} loadingtext={"جاري التحميل.."} />
        </>
    )
}
export default CartPage;