import { useEffect } from "react";
import PageHeader from "../../component/ui/PageHeader";
import ContactDetails from "./component/ui/ContactDetails";
import ContactForm from "./component/ui/ContactForm";
import contactHeaderImg from "../../images/contact-img.jpg"

export default function Contact() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <ContactForm />
    </>
  );
}
