import { useForm } from "react-hook-form";
import registerImg from "../../images/register-page/graphic1.svg";
import { useDispatch, useSelector } from "react-redux";
import { registerFunc } from "../../store/slices/register-action/RegisterSlicer";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { postVeryfyEmailFunc } from "../../store/slices/users/PostVerifyEmailSlicer";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
function RegisterPage() {
    const {register , reset , handleSubmit , formState : {errors}} = useForm();
    const registerApi = `${process.env.REACT_APP_PUBLIC_API}/api/register`;
    const verifyEmailApi = `${process.env.REACT_APP_PUBLIC_API}/api/email/verification-notification`;
    const dispatch = useDispatch();
    const [pass , setPass] = useState("");
    const [confirmPass , setConfirmPass] = useState("");
    const [showPass , setShowPass] = useState(false);
    const [showConfirmPass , setShowConfirmPass] = useState(false);
    const {loading:registerLoading } = useSelector(
        state => state.registerSlicer
    )
    const navigate = useNavigate()
    const registerFunction = (data) => {
        if(data) {
            if(pass.length < 8) {
                Swal.fire({
                    title: "كلمة المرور",
                    text: "كلمه المرور يجب ان تكون اكبر من  8 احرف ",
                    icon: "warning"
                });
            }else {
                if(pass !== confirmPass) {
                    Swal.fire({
                        title: "تاكيد كلمة المرور",
                        text: "كلمة المرور وتاكيدها غير متطابقان",
                        icon: "warning"
                    });
                }else {
                    try {
                        dispatch(registerFunc({api : registerApi , data : data}))
                        .then(result => {
                            if(result.payload.success === true) {
                                navigate("/login");
                            }
                        })
                    }
                    catch (error) {
                        Swal.fire({
                            title: "عذرا!",
                            text: error,
                            icon: "warning"
                        });
                    }
                }
            }
        }
    }



    const handleSuccess = (response) => {
        const dcode = jwtDecode(response.credential)
        console.log('Login successful:', response);
        console.log('Login successful:', response.credential);
        console.log(dcode);
        // You can send the authentication token to your backend here
      };
    
      const handleError = (error) => {
        console.error('Error during login:', error);
        // Handle login error
      };
    
    return (
        <>
        
        <div className="register-page relative pt-[100px] pb-[60px]">
            <div className="page-container relative w-[90%] mx-auto flex justify-between items-center row">
                <div className="relative resgiter-page-image col-sm-12 col-md-6">
                    <div className="relative col-sm-12 h-[400px] image-content ">
                        <img src={registerImg} alt="img" className="absolute top-0 left-0 w-full h-full" />
                    </div>
                </div>
                <div className="form-content col-sm-12 col-md-6">
                    <form 
                        className="relative w-full col-sm-12"
                        id="register-form"
                        onSubmit={handleSubmit((data) => {
                            registerFunction(data)
                        })}
                    >
                        <div className="form-header relative flex flex-col justify-center items-center col-sm-12 mb-2 py-2">
                            <h2 className="font-bold mb-2 w-full md:text-right">احصل على المزيد من الأشياء المنجزة مع منصة التعليم</h2>
                            <h3 className=" w-full md:text-right">الوصول إلى أقوى أداة في صناعة التعليم والتعلم بأكملها</h3>
                        </div>
                        {/* <GoogleOAuthProvider 
                            clientId="1093374369515-55ufnmms1k7d50jca71s8gu3n0tts34e.apps.googleusercontent.com">
                            <GoogleLogin
                                // clientId="1093374369515-55ufnmms1k7d50jca71s8gu3n0tts34e.apps.googleusercontent.com"
                                onSuccess={handleSuccess}
                                onFailure={handleError}
                                buttonText="Sign up with Google"
                            />
                        </GoogleOAuthProvider>   */}
                        <div className="row mt-2">
                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                {errors.name?.message && (
                                <p className="text-red-600">
                                    {errors.name?.message}
                                </p>
                                )}
                                <input
                                {...register("name", {
                                    required: "الاسم مطلوب!"
                                })}
                                type="text"
                                className="form-control"
                                placeholder="الاسم"
                                onChange={(e)=>{
                                    // setName(e.target.value)
                                }}
                                />
                            </div>
                            <div className="form-group mb-3 col-sm-12 col-md-6">
                                {errors.email?.message && (
                                <p className="text-red-600">
                                    {errors.email?.message}
                                </p>
                                )}
                                <input
                                {...register("email", {
                                    required: "البريد الالكتروني مطلوب!"
                                })}
                                type="text"
                                className="form-control"
                                placeholder="البريد الالكتروني"
                                onChange={(e)=>{
                                    // setName(e.target.value)
                                }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group mb-3 col-sm-12">
                                {errors.phone?.message && (
                                <p className="text-red-600">
                                    {errors.phone?.message}
                                </p>
                                )}
                                <input
                                {...register("phone", {
                                    required: "رقم الموبايل مطلوب"
                                })}
                                type="text"
                                className="form-control"
                                placeholder="رقم الموبايل"
                                onChange={(e)=>{
                                    // setName(e.target.value)
                                }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group relative mb-3 col-sm-12 col-md-6">
                                {errors.password?.message && (
                                <p className="text-red-600">
                                    {errors.password?.message}
                                </p>
                                )}
                                <div className="relative">
                                    <input
                                    {...register("password", {
                                        required: "كلمة المرور مطلوبة"
                                    })}
                                    type={showPass ? "text" : "password"}
                                    className="form-control"
                                    placeholder="كلمة المرور"
                                    onChange={(e)=>{
                                        setPass(e.target.value)
                                    }}
                                    value={pass}
                                    />
                                    <i className={`fa-solid fa-eye absolute top-[50%] translate-y-[-50%] left-[10px] ${pass !== "" ? "opacity-100" : "opacity-10"} transition-all duration-300 cursor-pointer`}
                                        onClick={() => setShowPass(!showPass)}
                                    ></i>
                                </div>
                            </div>
                            <div className="form-group relative mb-3 col-sm-12 col-md-6">
                                {errors.password_confirmation?.message && (
                                <p className="text-red-600">
                                    {errors.password_confirmation?.message}
                                </p>
                                )}
                                <div className="relative">
                                    <input
                                    {...register("password_confirmation", {
                                        required: "يجب تاكيد كلمة المرور !"
                                    })}
                                    type={showConfirmPass ? "text" : "password"}
                                    className="form-control"
                                    placeholder="تاكيد كلمة المرور"
                                    onChange={(e)=>{
                                        setConfirmPass(e.target.value)
                                    }}
                                    value={confirmPass}
                                    />
                                    <i className={`fa-solid fa-eye absolute top-[50%] translate-y-[-50%] left-[10px] ${confirmPass !== "" ? "opacity-100" : "opacity-10"} transition-all duration-300 cursor-pointer`}
                                        onClick={() => setShowConfirmPass(!showConfirmPass)}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div className="inputs relative flex items-center my-2">
                            <button type="submit" className={`relative flex justify-center items-center bg-blue-400 rounded-md text-white p-2 ${registerLoading ? " pointer-events-none opacity-50" : ""}`}
                            >
                                {
                                    registerLoading
                                    ?
                                    "جاري التحميل.."
                                    :
                                    "انشاء حساب"
                                }
                            </button>
                            <Link to="/login" className="relative flex justify-center items-center mr-2 p-2"
                            >
                                تسجيل الدخول
                            </Link>
                            <Link to="/" className="relative flex justify-center items-center mr-2 p-2"
                            >
                                الرئيسية
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
}


export default RegisterPage;