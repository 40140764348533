import CardTeachers from "./ui/CardTeachers";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeachersFunc } from "../../../store/slices/teachres/GetTeachresSlicer";
import { Spinner } from "react-bootstrap";

export default function Teachers() {
  const [teachersList , setTeachersList] = useState([]);
  const [searchValue , setSearchValue] = useState("");
  const [loadingTeachers ,setLoadingTeachers] = useState();
  const teachersApi = `${process.env.REACT_APP_PUBLIC_API}/api/teachers`;
  const {data} = useSelector(state => state.getTeachersSlicer)
  const dispatch = useDispatch();

  useEffect (() => {
    let searchTimeOut;
    const getData = async () => {
      if(searchValue !== "") {
        searchTimeOut = setTimeout(()=>{
          dispatch(getTeachersFunc(`${teachersApi}?search=${searchValue}`))
        },2000)
      }else{
        dispatch(getTeachersFunc(`${teachersApi}`))
      }
      setLoadingTeachers(true)
    }
    getData();
    return () => clearTimeout(searchTimeOut)
  },[searchValue])
  useEffect(()=>{
    data && setTeachersList(data)
    data && setLoadingTeachers(false)
  },[data])
  return (
    <>
      <section className="meet-teachers-one">
        <div className="container">
          <div className="section-title text-center">
            <span className="section-title__tagline">مهارات &amp; موهلين</span>
            <h2 className="section-title__title sm:text-[20px] md:text-[30px]">قسم المعلمين لتجربة تدريس استثنائية</h2>
          </div>
          <div className="search-div relatiev flex my-3 w-[300px] rounded-md overflow-hidden">
            <input 
              className="w-full"
              type="text"
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="ابحث عن معلمين.."
            />
            <div className="search-icon relative flex justify-center items-center bg-[#0d2d63] p-3">
              <i className="fa-solid fa-magnifying-glass text-white"></i>
            </div>
          </div>
          {
              loadingTeachers ? 
              (
                <>
                  <div className="row d-flex justify-content-center gap-2 ">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                  </div>
                </>
              )
              :
              <div className="relative flex flex-wrap gap-2">
                {
                  data && teachersList.length > 0 &&
                  teachersList.map((teacher , index) => {
                    return(
                      <CardTeachers
                        key = {index}
                        name={teacher.name}
                        status = {teacher.publish}
                        email = {teacher.email}
                        phone = {teacher.phone}
                        imgurl={teacher.image_url}
                        coursesCount = {teacher.courses_count}
                      />
                    )
                  })
                }
                {
                  !loadingTeachers && !data&&
                  <div className="relative flex justify-center items-center">
                    <h2>لا يوجد معلمين حتي الان</h2>
                  </div>
                }
              </div>
            }
        </div>
      </section>
    </>
  );
}
