import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ShowLessonRequirments(props) {
    const {show , lessonRequirments} = props;
  return (
    <>
      <Modal show={show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title className='relative w-full flex items-center justify-end'>متطلبات الدرس</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                lessonRequirments
                &&
                lessonRequirments.length >= 1
                &&
                lessonRequirments.map((requirment , index) => {
                    return <p key={index} className='relative mb-1 text-right'>{requirment.required_lesson.name}</p>
                })
            }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='bg-primary' onClick={props.onHide}>
            اغلاق
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShowLessonRequirments;