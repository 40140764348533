import { useEffect } from "react";
import LessonsSection from "./component/lessonsSection";
export default function Lessons() {
  
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (

    <>
      <LessonsSection />
    </>
  );
}
