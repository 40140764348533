import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const getAttmptFunc = createAsyncThunk(
  "quize/attempt", 
  async (api) => {
    try {
      const response = await axios.get(api , {withCredentials : true})
      console.log(response)
      checkErrorsFunctions(response , response.data.errors);
      return response.data.data;
    }
    catch (error) {
      console.log(error)
    }
  }
);

const getAttemptSlicer = createSlice({
  name: "attempt",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getAttmptFunc.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getAttmptFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getAttmptFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    });
  },
});

export default getAttemptSlicer.reducer;
