import { useEffect } from "react";
import swal from "sweetalert";
import { logout } from "../services/logoutAction";
import Swal from "sweetalert2";
function LogOut() {
  const user = JSON.parse(window.localStorage.getItem("userdata"))
  const expire_date = user && user.data.expiry_token;
    // const expire_date = window.localStorage.getItem("expiry_date")
    
    useEffect(() => {
        const checkerInterval = setInterval(() => {
          const dateNow = Date.now();
          const expirationTime = new Date(expire_date).getTime();
          const timeDifference = expirationTime - dateNow;
        if(timeDifference > 0 && timeDifference <= 60000) {
          Swal.fire({
            title: "انتبه",
            text: "جلسه تسجيل الدخول تنتهي بعد دقيقه!",
            icon: "warning"
          });
        }
          if (dateNow >= expirationTime) {
            logout()
          }
        }, 60 * 1000); // Check every 1 minute
        return () => clearInterval(checkerInterval);
      }, [expire_date]);
    return (
        <>
        </>
    )
}
export default LogOut;