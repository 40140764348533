import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { logout } from "../../../services/logoutAction";
import checkErrorsFunctions from "../checkErrorsFunction";

export const getProfileData = createAsyncThunk("user/profile", async (url) => {
  try {
    const response = await axios.get(url, {
      withCredentials: true,
    });
      checkErrorsFunctions(response , response.data.errors);
      return response.data.data.student;
  } catch (error) {
    return error;
  }
});

const profileSlicer = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getProfileData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default profileSlicer.reducer;
