import { useCallback, useEffect, useRef, useState } from "react";
import { getSubscriptionsCourses } from "../../../store/slices/users/subscriptionsCoursesSlicer";
import "../style/style.css";
import "./ui/lesson-section.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import top from "../../../images/top.png"
import defaultTeacherImg from "../../../images/presentation.png"
import audioImg from "../../../images/audiobook.png"
import Swal from "sweetalert2";
import { getLessonData } from "../../../store/slices/lessons/showLessonSlicer";
import PageHeader from "../../../component/ui/PageHeader";
import CustomLoaders from "../../../component/ui/CustomLoaders";
import SessionsDropDown from "./sessions-page/SessionsDropDown";
import { Button} from "react-bootstrap";
import videoIcon from "../../../images/video-camera.png";
import quizIcon from "../../../images/quiz.png";
import fileIcon from "../../../images/google-docs.png";
import pdfIcon from "../../../images/file.png";
import rich_textIcon from "../../../images/files.png";
import questionIcon from "../../../images/question-mark (1).png";
import lockIcon from "../../../images/padlock.png";
import endIcon from "../../../images/end.png";
import infinitImg from "../../../images/loop.png";
import ShowLessonRequirments from "./show-lesson-requirment/ShowLessonRequirmentsModal";
import { postCompletingLessonFunc } from "../../../store/slices/lessons/PostCompletingLessonSlicer";
import ShowRichTextContents from "../../../component/show-rich-text-contents/ShowRichTextContents";
import axios from "axios";
import "../style/lesson-section-style.css";
import ShowImageModal from "../../../component/show-image-modal/ShowImageModal";
import { Player, ControlBar, PlaybackRateMenuButton, BigPlayButton  } from 'video-react';
import 'video-react/dist/video-react.css';
import limitationIcon from "../../../images/speedometer.png";
import DownloadingProgress from "../../../component/global/global-functions/DownloadingProgress";
import "../style/lesson-section-style.css";
export default function LessonsSection() {
  const { executionId } = useParams();
  const dispatch = useDispatch();
  const [singleLesson, setSingleLesson] = useState({});
  const [singleLessonQuize, setSingleLessonQuize] = useState();
  const [thereIsLessonQuize, setThereIsLessonQuize] = useState();
  const [course, setCourse] = useState({});
  const [courseData , setCourseData] = useState();
  const [executionCourse , setExecutionCourse] = useState({});
  const dateNow = new Date();
  const [courseSessions , setCourseSessions] = useState([]);
  const {
    loading: showLessonsLoading,
    data: showLessonsData,
    error: showLessonsError,
  } = useSelector((s) => s.shwoLessonSlicer);
  const {
    loading: subscriptionsCoursesLoading,
    data: subscriptionsCoursesData,
    error: subscriptionsCoursesError,
  } = useSelector((s) => s.subscriptionsCoursesSlicer);

  const [lessonCourse, setLessonCourse] = useState([]);
  const [showLessons, setShowLessons] = useState();
  const [showCourseSessions, setShowCourseSessions] = useState();
  const [showLessonRequirments , setShowLessonRequirments] = useState()
  const [requirmentsList , setRequirmentsList] = useState()
  const [showRichTextContents , setShowRichTextContents] = useState()
  const [richTextContent , setRichTextContent] = useState()
  const [showLessonContents , setShowLessonContents] = useState(false);
  // const [showingLessonContents , setShowingLessonContents] = useState();
  const [targetLesson , setTargetLesson] = useState({});
  const [showInstructoImg , setShowInstructoImg] = useState();
  const [instructorImage , setInstructorImage] = useState("");
  const globalLessonsApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons`;
  const [downloadFileLoading , setDownloadFileLoading] = useState();
  useEffect(() => {
    window.scrollTo(0,0)
  },[])
  const getData = async () => {
    dispatch(
      getSubscriptionsCourses(
        `${process.env.REACT_APP_PUBLIC_API}/api/course-executions/${executionId}/lessons`
      ))
  };
  useEffect(() => {
    getData();
  }, []);
  // for get course lessons for show it
  useEffect(() => {
    subscriptionsCoursesData 
    && 
    subscriptionsCoursesData.lessons 
    && 
    setLessonCourse(subscriptionsCoursesData.lessons);

    subscriptionsCoursesData 
    && subscriptionsCoursesData.courseExecution 
    && setExecutionCourse(subscriptionsCoursesData.courseExecution);

    subscriptionsCoursesData 
    && subscriptionsCoursesData.courseExecution 
    && subscriptionsCoursesData.courseExecution.course 
    && setCourseData(subscriptionsCoursesData.courseExecution.course);

    subscriptionsCoursesData 
    && subscriptionsCoursesData.sessions 
    && setCourseSessions(subscriptionsCoursesData.sessions);
  }, [subscriptionsCoursesData]);

  const postCompletingLesson = (lessonId) => {
    dispatch(postCompletingLessonFunc(`${globalLessonsApi}/${lessonId}/complete`))
  }
  const getLessonDataFromServer = async (lessonId) => {
    dispatch(
      getLessonData(`${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}`))
      .then(result => {
        if(result.payload && result.payload.lesson) {
          if(result.payload.lesson && result.payload.lesson.type === "rich_text") {
            postCompletingLesson(result.payload.lesson.id)
          }
        }
      })
  };
  useEffect(()=>{
    showLessonsData 
    && 
    showLessonsData.lesson 
    && setSingleLesson(showLessonsData.lesson);

    showLessonsData 
    && 
    showLessonsData.quizAttempt 
    && setSingleLessonQuize(showLessonsData.quizAttempt);

    targetLesson 
    && 
    targetLesson.quizzes_count 
    && targetLesson.quizzes_count >= 1 
    && setThereIsLessonQuize(true)
  },[showLessonsData , targetLesson])
  
  const [downloadingFilePercent , setDownloadingFilePercent] = useState(0);


  const downloadLessonFileFunc = () => {
    try {
      axios.get(`${globalLessonsApi}/${targetLesson && targetLesson.id}/download-file` , {
          withCredentials : true,
          responseType: 'blob', // Important: Set response type to blob
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
          onDownloadProgress : (ProgressEvent) => {
            console.log(ProgressEvent);
            setDownloadingFilePercent(((ProgressEvent.loaded / ProgressEvent.total) * 100).toFixed())
          }

      })
      .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          if(response.data && response.data.type) {
          if (response.data.type === "application/pdf") {
              link.setAttribute('download', `${targetLesson.name}.pdf`);
          } else {
              link.setAttribute('download', `${targetLesson.name}.zip`);
          }
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          Swal.fire({
              title: "تم بنجاح!",
              text: "!تم تنزيل الملف بنجاح",
              icon: "success"
          });
          setDownloadingFilePercent(0)
          setTimeout(() => window.location.reload() , 1000)
          }
      })
      }
      catch(error) {
      Swal.fire({
          title: "عذرا!",
          text: error,
          icon: "error"
      })
  }
  }
  
  let limitationDownlad = useRef();
  useEffect (() => {
    if(targetLesson.type === "pdf" || targetLesson.type === "file") {
      // const freeStatus = targetLesson.can_view_without_pay
      if( targetLesson.completed_students && targetLesson.completed_students.length >= 1) {
        const downloadedCount = targetLesson.completed_students[0].pivot && targetLesson.completed_students[0].pivot.completion_count
        const downloadLimit = targetLesson.view_count

        if(downloadedCount >= downloadLimit) {
          limitationDownlad.current = (
            <p className="relative flex items-center text-red-500">
              <img src={limitationIcon} alt="limit icon" className="ml-1 w-[30px]" />
              تم الوصول للحد الاقصي من التحميل
            </p>
          )
        }
        else {
          let stDate;
          if(targetLesson && targetLesson.lesson_schadules && targetLesson.lesson_schadules.length >= 1) {
            const startDate = new Date(targetLesson.lesson_schadules[0].pivot.start_date);
            stDate = startDate;
          }
          limitationDownlad.current = (
            <button
            onClick={() => {
              downloadLessonFileFunc()
            }}
            // onClick={() => downloadLessonFile(`${globalLessonsApi}/${targetLesson && targetLesson.id}/download-file` , postCompletingLesson(targetLesson.id))}
            className={`shadow-md hover:shadow-slate-400 hover:translate-y-[-5px] relative bg-green-500 text-white transition-all duration-500 ml-3 py-2 px-4 rounded-md ${(parseInt(downloadingFilePercent) >= 1 && parseInt(downloadingFilePercent) !== 100) ? "opacity-40 pointer-events-none" : ""} ${stDate > dateNow ? "pointer-events-none opacity-50" : ""}`}
          >
              <i className="fa-solid fa-download ml-1"></i>
              {
                showLessonsLoading
                ?
                "يرجي الانتظار.."
                :
                "اضغط لتحميل الملف"
              }
            </button>
          )
        }
      }
      else {
        let stDate;
        if(targetLesson && targetLesson.lesson_schadules && targetLesson.lesson_schadules.length >= 1) {
          const startDate = new Date(targetLesson.lesson_schadules[0].pivot.start_date);
          stDate = startDate;
        }
        limitationDownlad.current = (
          <button
          onClick={() => {
            downloadLessonFileFunc()
          }}
          // onClick={() => downloadLessonFile(`${globalLessonsApi}/${targetLesson && targetLesson.id}/download-file` , postCompletingLesson(targetLesson.id))}
          className={`shadow-md hover:shadow-slate-400 hover:translate-y-[-5px] relative bg-green-500 text-white transition-all duration-500 ml-3 py-2 px-4 rounded-md ${(parseInt(downloadingFilePercent) >= 1 && parseInt(downloadingFilePercent) !== 100) ? "opacity-40 pointer-events-none" : ""} ${stDate > dateNow ? "pointer-events-none opacity-50" : ""}`}
        >
            <i className="fa-solid fa-download ml-1"></i>
            {
              downloadFileLoading
              ?
              "يرجي الانتظار.."
              :
              "اضغط لتحميل الملف"
            }
          </button>
        )
      }
    }
  },[targetLesson , downloadFileLoading])

    // check target lesson quize status
    let el = useRef();
    useEffect(() => {
      let stDate;
      if(targetLesson && targetLesson.lesson_schadules && targetLesson.lesson_schadules.length >= 1) {
        const startDate = new Date(targetLesson.lesson_schadules[0].pivot.start_date);
        stDate = startDate;
      }
  
      if(targetLesson) {
        const viewCount = targetLesson.view_count;

        const studentWatchCount = targetLesson.completed_students 
        &&
        targetLesson.completed_students.length >= 1
        && targetLesson.completed_students[0] 
        // && targetLesson.completed_students[0].completed_students
        && targetLesson.completed_students[0].pivot
        && targetLesson.completed_students[0].pivot.completion_count
        // const studentWatchCount = targetLesson.completed_students?.[0]?.pivot?.completion_count ?? false;
        
        console.log(viewCount , studentWatchCount )
        if(targetLesson.completed_students && targetLesson.attempts[0] && targetLesson.attempts[0].status_passed) {
          switch (targetLesson.attempts[0].status_passed) {
            case "review":
              el.current = <p className="mt-3 text-red-500">الامتحان الخاص بك قيد التصحيح الان!</p>;
              break
             case "compleated":
              el.current = (
                <div className="relative flex items-center mt-3">
                    {
                      targetLesson.attempts[0] && targetLesson.completed_students && targetLesson.attempts[0].score
                      &&
                      <Link
                        className={`relative text-white transition-all duration-500 ml-3 py-2 px-4 rounded-md bg-blue-500`}
                        to={`attemp-page/${targetLesson.attempts[0] && targetLesson.attempts[0].id && targetLesson.attempts[0].id}`}
                      >
                          <i className="fa-solid fa-eye ml-1"></i>
                          عرض الاجابات
                      </Link>
                    }
                    {
                      (viewCount < studentWatchCount || studentWatchCount === false)
                      &&
                      <Link
                        className={`relative text-white transition-all duration-500 ml-3 py-2 px-4 rounded-md bg-orange-500`}
                        to={`lesson-quize-page/${targetLesson && targetLesson.id}`}
                      >
                          <i className="fa-solid fa-rotate-right ml-1"></i>
                          اعادة الاختبار
                      </Link>
                    }
                  {
                    targetLesson.completed_students && targetLesson.attempts[0].score
                    &&
                    // <p>تم اجتياز الاختبار بنجاح!</p>
                    <p>الدرجة : <span className="text-blue-500">{targetLesson.completed_students && targetLesson.attempts[0].score}</span></p>
                  }
                </div>
                );
              break;
              default:
                el.current = (
                  <Link
                    className={`relative bg-primary text-white transition-all duration-500 ml-3 py-2 px-4 rounded-md ${stDate > dateNow ? "pointer-events-none opacity-50" : ""}`}
                    to={`lesson-quize-page/${targetLesson.id}`}
                  >
                      {
                        stDate > dateNow
                        ?
                        (
                          <p className="mr-2 flex items-center mb-0 text-sm">
                            <i className="fa-solid fa-ban ml-1 text-red-500 opacity-80"></i>
                          متاح في {
                            targetLesson && targetLesson.lesson_schadules && targetLesson.lesson_schadules
                            &&
                            targetLesson.lesson_schadules[0].pivot.start_date
                          }
                          </p>
                        )
                        :
                        "اختبر نفسك"
                      }
                  </Link>
                )
          }
        }
        else {
          el.current = (
            <Link
              className={`relative bg-primary text-white transition-all duration-500 ml-3 py-2 px-4 rounded-md ${stDate > dateNow ? "pointer-events-none opacity-50" : ""}`}
              to={`lesson-quize-page/${targetLesson.id}`}
            >
                {
                  stDate > dateNow
                  ?
                  (
                    <p className="mr-2 flex items-center mb-0 text-sm">
                      <i className="fa-solid fa-ban ml-1 text-red-500 opacity-80"></i>
                    متاح في {
                      targetLesson && targetLesson.lesson_schadules && targetLesson.lesson_schadules
                      &&
                      targetLesson.lesson_schadules[0].pivot.start_date
                    }
                    </p>
                  )
                  :
                  "اختبر نفسك"
                }
            </Link>
          )
        }
      }
    },[thereIsLessonQuize , targetLesson]);

  const [show , setShow] = useState();
  useEffect(() => {
    if(targetLesson) {
      // const freeStatus = targetLesson.can_view_without_pay;
      const viewCount = targetLesson.view_count;
      const studentWatchCount = targetLesson.completed_students 
      && targetLesson.attempts[0] 
      && targetLesson.attempts[0].completed_students
      && targetLesson.attempts[0].completed_students.pivot
      && targetLesson.attempts[0].completed_students.pivot.completion_count
      let stDate;
      if(targetLesson.lesson_schadules && targetLesson.lesson_schadules.length >= 1) {
        const startDate = new Date(targetLesson.lesson_schadules[0].pivot.start_date);
        stDate = startDate;
      }
      switch (targetLesson.type) {
        case "quiz" :
          setShow (
              <>
                <div className="no-lesson-overlay bg-slate-50 top-0 left-0 w-full flex flex-col justify-center items-center h-[350px] relative p-3">
                  <img src={quizIcon}  alt="audio img" className="relative mb-2 w-[100px]"/>
                  <p className="m-0 text-blue-500">اختبار</p>
                </div>
                {
                  thereIsLessonQuize
                  ?
                  el.current
                  :
                  <p>لا يوجد اختبار لهذا الدرس</p>
                }
              </>
          )
          break;

        case "video" :
          setShow (
            <div className="single-course-sidebar relative">
              <div className="course-single-thumbnail">
                  <div className="tutor-video-player"
                    onContextMenu={handleContext}
                  >
                    <div className="tutor-ratio tutor-ratio-16x9">
                      {
                        targetLesson.id === singleLesson.id
                        &&
                        singleLesson.file_url
                        ?
                        // <ReactPlayer
                        //     onProgress={(proggresData) => {
                        //     const videoFullTime = proggresData.loadedSeconds;
                        //     const timeWhichPlayed = proggresData.playedSeconds;
                        //     const halfTime = parseInt(videoFullTime) / 2;
                        //     if(parseInt(timeWhichPlayed) === parseInt(halfTime)) {
                        //       axios.post(`${globalLessonsApi}/${targetLesson.id}/complete` , {} , {withCredentials : true});
                        //     }
                        //   }}
                        //   url={singleLesson.file_url}
                        //   controls
                        //   config={{
                        //     file : {attributes : {controlsList: "nodownload"}}
                        //   }}
                        //   onContextMenu={handleContext}
                        // />
                        <Player 
                          autoPlay 
                          src={singleLesson.file_url} 
                          onTimeUpdate= {(e) => {
                          const timeWhichPlayed = e.target.currentTime;
                          const lessonDuration = e.target.duration;
                          const halfTime = lessonDuration / 2;
                          const tolerance = 1;
                          if(timeWhichPlayed >= halfTime - tolerance && timeWhichPlayed <= halfTime + tolerance) {
                            // axios.post(postCompletingLessonApi , {} , {withCredentials : true});
                            axios.post(`${globalLessonsApi}/${targetLesson.id}/complete` , {} , {withCredentials : true});
                          }
                        }}
                        >
                          <ControlBar>
                            <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                          </ControlBar>
                        </Player>
                        :
                        <div className="no-lesson-overlay bg-slate-50 top-0 left-0 w-full flex flex-col justify-center items-center h-[350px] relative p-3">
                          <img src={audioImg}  alt="audio img" className="relative mb-2 w-[100px]"/>
                          <p className="m-0 text-blue-500">
                            {
                              showLessonsLoading
                              ?
                              "جاري التحميل.."
                              :
                              "قم بعرض الفيديو الخاص بالدرس.."
                            }
                          </p>
                        </div>
                      }

                      {/* <Player 
                          autoPlay 
                          src={singleLesson.file_url} 
                          onTimeUpdate= {(e) => {
                          const timeWhichPlayed = e.target.currentTime;
                          const lessonDuration = e.target.duration;
                          const halfTime = lessonDuration / 2;
                          const tolerance = 1;
                          if(timeWhichPlayed >= halfTime - tolerance && timeWhichPlayed <= halfTime + tolerance) {
                            axios.post(postCompletingLessonApi , {} , {withCredentials : true});
                          }
                        }}
                      >
                        <ControlBar>
                          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                        </ControlBar>
                      </Player> */}

                    </div>
                  </div>
              </div>
              <div className="relative flex items-center">
                <button className={`relative flex justify-center items-center bg-[#303F9F] text-white py-2 px-3 rounded-sm text-[16px] my-3 ${showLessonsLoading ? "pointer-events-none opacity-70" : ""} ${stDate > dateNow ? "pointer-events-none opacity-50" : ""}`}
                  onClick={() => getLessonDataFromServer(targetLesson.id)}
                >
                  {
                    showLessonsLoading
                    ?
                    "جاري التحميل.."
                    :
                    <>
                      <i className="fa-brands fa-youtube ml-1"></i>
                      عرض الفيديو
                    </>
                  }
                </button>
                {
                  stDate > dateNow
                  &&
                  <p className="mr-2 flex items-center mb-0 text-sm">
                    <i className="fa-solid fa-ban ml-1 text-red-500 opacity-80"></i>
                    {/* غير متاح في الوقت الحالي */}
                    متاح في {
                      targetLesson && targetLesson.lesson_schadules && targetLesson.lesson_schadules
                      &&
                      targetLesson.lesson_schadules[0].pivot.start_date
                    }
                  </p>
                }
              </div>
            </div>
          )
          break;

        case "file" :
          setShow (
            <>
              <div className="no-lesson-overlay bg-slate-50 top-0 left-0 w-full flex flex-col justify-center items-center h-[350px] relative p-3">
                <img src={fileIcon}  alt="audio img" className="relative mb-2 w-[100px]"/>
                <p className="m-0 text-blue-500">قم بتحميل الملف</p>
              </div>
              <div className="course-single-action items-center tutor-my-40">
                {
                  limitationDownlad.current
                }
                {
                  stDate > dateNow
                  &&
                  <p className="mr-2 flex items-center mb-0 text-sm">
                    <i className="fa-solid fa-ban ml-1 text-red-500 opacity-80"></i>
                    متاح في {
                      targetLesson && targetLesson.lesson_schadules && targetLesson.lesson_schadules
                      &&
                      targetLesson.lesson_schadules[0].pivot.start_date
                    }
                  </p>
                }
              </div>
            </>
          )
          break;

        case "pdf" :
          setShow (
            <>
              <div className="no-lesson-overlay bg-slate-50 top-0 left-0 w-full flex flex-col justify-center items-center h-[350px] relative p-3">
                <img src={pdfIcon}  alt="audio img" className="relative mb-2 w-[100px]"/>
                <p className="m-0 text-blue-500">قم بتحميل الملف</p>
              </div>
              <div className="course-single-action items-center tutor-my-40">
                {
                  limitationDownlad.current
                }
                {
                  stDate > dateNow
                  &&
                  <p className="mr-2 flex items-center mb-0 text-sm">
                    <i className="fa-solid fa-ban ml-1 text-red-500 opacity-80"></i>
                    {/* غير متاح في الوقت الحالي */}
                    متاح في {
                      targetLesson && targetLesson.lesson_schadules && targetLesson.lesson_schadules
                      &&
                      targetLesson.lesson_schadules[0].pivot.start_date
                    }
                  </p>
                }
              </div>
            </>
            
          )
          break;

        case "rich_text":
          setShow (
            <>
              <div className="no-lesson-overlay bg-slate-50 top-0 left-0 w-full flex flex-col justify-center items-center h-[350px] relative p-3">
                <img src={rich_textIcon}  alt="audio img" className="relative mb-2 w-[100px]"/>
                <p className="m-0 text-blue-500">text</p>
              </div>
              <div className="course-single-action items-center tutor-my-40">
                <div className="relative flex">
                  {
                      viewCount > studentWatchCount || viewCount === 0
                      ?
                      <>
                        <Button
                          className={`bg-primary ${singleLesson.contents ? "pointer-events-none opacity-50" : ""} ${stDate > dateNow ? "pointer-events-none opacity-50" : ""}`}
                          onClick={() => {
                            getLessonDataFromServer(targetLesson.id)
                          }}
                        >
                          {
                            "تحميل الدرس"
                          }
                        </Button>
                        <Button
                          className={`bg-primary mr-2 ${!singleLesson.contents ? "pointer-events-none opacity-50 disabled-class" : ""}}`}
                          onClick={() => {
                            setShowLessonContents(!showLessonContents);
                            
                          }}
                        >
                          {
                            showLessonContents === true ? "اخفاء المحتوي الكتابي" : "عرض المحتوي الكتابي"
                          }
                        </Button>
                      </>
                      :
                      (
                        viewCount > 0
                        &&
                        <p className="relative flex items-center text-red-500">
                          <img src={limitationIcon} alt="limit icon" className="ml-1 w-[30px]" />
                          تم الوصول للحد الاقصي من التحميل
                        </p>
                      )
                  }
                  {
                    stDate > dateNow
                    &&
                    <p className="mr-2 flex items-center mb-0 text-sm">
                      <i className="fa-solid fa-ban ml-1 text-red-500 opacity-80"></i>
                      {/* غير متاح في الوقت الحالي */}
                      متاح في {
                          targetLesson && targetLesson.lesson_schadules && targetLesson.lesson_schadules
                          &&
                          targetLesson.lesson_schadules[0].pivot.start_date
                      }
                    </p>
                  }
                </div>
              </div>
            </>
          )
          break;
        
        default :
        setShow (
          <div className="single-course-sidebar relative">
            <div className="no-lesson-overlay bg-slate-50 top-0 left-0 w-full flex flex-col justify-center items-center h-[350px] relative p-3">
              <img src={audioImg}  alt="audio img" className="relative mb-2 w-[100px]"/>
              <p className="m-0 text-blue-500">قم باختيار درس للاستماع..</p>
            </div>
          </div>
        )
      }
    }
  },[targetLesson , singleLesson , thereIsLessonQuize , showLessonContents , showLessonsLoading])

  const handleContext = (e)=>{
    e.preventDefault()
  }
  const checkLessonType = (lessonType) => {
    if(lessonType) {
        switch(lessonType) {
            case "video" :
            return <img src={videoIcon} alt="icon" className="relative ml-1 w-[30px]" />
            case "quiz" :
            return <img src={quizIcon} alt="icon" className="relative ml-1 w-[30px]" />
            case "file" :
            return <img src={fileIcon} alt="icon" className="relative ml-1 w-[30px]" />
            case "pdf" :
            return <img src={pdfIcon} alt="icon" className="relative ml-1 w-[30px]" />
            case "rich_text" :
            return <img src={rich_textIcon} alt="icon" className="relative ml-1 w-[30px]" />
            default :
            return <img src={videoIcon} alt="icon" className="relative ml-1 w-[30px]" />
        }
    }
  }
  const checkLockLesson = (requirments) => {
    let lockStatus;
    if(requirments && requirments.length >= 1) {
      const lessonsWhichRequiredToPass = 
      requirments.flatMap(requirment => requirment.required_lesson);
      lessonsWhichRequiredToPass.forEach((lesson) => {
        if(lesson.completed_students.length >= 1) {
          lockStatus = false
        }else {
          lockStatus = true
        }
      })
    }
    if(lockStatus) {
      return <img src={lockIcon} alt="lock icon" className="relative w-[25px]" />
    }
  }
  const checkThereIsRequirments = (requirments) => {
    let showQuestionMark;
    if(requirments && requirments.length >= 1) {
      const lessonsWhichRequiredToPass = 
      requirments.flatMap(requirment => requirment.required_lesson);

      lessonsWhichRequiredToPass.forEach((lesson) => {
        if(lesson.completed_students.length >= 1) {
          showQuestionMark = false
        }else {
          showQuestionMark = true
        }
      })
    }
    if(showQuestionMark) {
      return  <div className="relative cursor-pointer"
                onClick={() => {
                  setShowLessonRequirments(true)
                  setRequirmentsList(requirments)
                }}
              >
                <img src={questionIcon} alt="icon" className="relative w-[20px]"/>
              </div>
    }
  }
  const checkLessonViewCount = (viewCount , watchingCount) => {
    return (
      <div className="relatie flex items-center mr-2">
        <i className={`fa-solid fa-eye text-sm text-primary ml-1 ${watchingCount >= 1 ? "opacity-100" : "opacity-50"}`}></i>
        <div className="counter relative flex items-center text-sm">{viewCount} / {watchingCount}</div>
      </div>
    )
  }

  return (
    <>
      <PageHeader headerImg={"https://images.pexels.com/photos/6373308/pexels-photo-6373308.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} title={courseData && courseData.name}/>
      <div className="container-lessons relative">
        <div className="main-page-content row">
          {/* Main content */}
          <div className="relative w-full course-info">
            <div className="info-container w-[90%] py-3 mx-auto flex flex-col md:flex-row justify-between items-center">
              {
                subscriptionsCoursesLoading
                ?
                <>
                  <div className="row d-flex justify-content-center gap-2 my-5">
                    <CustomLoaders />
                    <CustomLoaders />
                    <CustomLoaders />
                    <CustomLoaders />
                  </div>
                </>
                :
                (
                  courseData
                  ?
                  <div className="info-content col-sm-12 col-md-6 relative flex justify-start flex-col py-3">
                    <div className="box course-name relative flex mb-2 items-center">
                      <h5 className="text-sm font-bold text-slate-600">اسم الكورس : </h5>
                      <p className="mr-2 text-sm">{courseData && courseData.name}</p>
                    </div>
                    <div className="box course-name relative flex mb-2 items-center">
                      <h5 className="text-sm font-bold text-slate-600">المادة : </h5>
                    <p className="mr-2 text-sm">{ courseData && courseData.category && courseData.category.name && courseData.category.name}</p>
                    </div>
                    <div className="relative flex items-center mb-2">
                      <div className="teacher-div flex items-center">
                        <h5 className="text-sm font-bold text-slate-600">معلم الكورس : </h5>
                        <div className="relative flex items-center">
                          <div className="relative w-[30px] h-[30px] mr-1 rounded-[50%] overflow-hidden">
                            <img src={courseData && courseData.user && courseData.user.image_url !== null ? courseData.user.image_url : defaultTeacherImg} alt="teacher img" className="absolute top-0 left-0 w-full h-full cursor-pointer" 
                              onClick={() => {
                                if(courseData && courseData.user && courseData.user.image_url) {
                                  setShowInstructoImg(true)
                                  setInstructorImage(courseData.user.image_url)
                                }else {
                                  setShowInstructoImg(true)
                                  setInstructorImage(defaultTeacherImg)
                                }
                              }}
                            />
                          </div>
                          <p className="mr-2 text-sm">{courseData && courseData.user && courseData.user.name}</p>
                        </div>
                      </div>
                      <div className="relative flex items-center mr-[20px]">
                        <h5 className="text-sm font-bold text-slate-600">عدد الدروس : </h5>
                        <div className="relative flex items-center">
                          <div className="relative w-[30px] h-[30px] rounded-[50%] mr-2 overflow-hidden">
                            <img src={audioImg} alt="teacher img" className="absolute top-0 left-0 w-full h-full" />
                          </div>
                          <p className="flex mr-3 text-sm">( {lessonCourse && lessonCourse.length} )</p>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex items-center mb-2">
                      <h5 className="text-sm font-bold text-slate-600">تم الاشتراك بتاريخ : </h5>
                      <p className="mr-2 text-sm">
                        {
                        // singleExecution 
                        // && 
                        executionCourse.start_date_student
                        &&
                        executionCourse.start_date_student
                        }
                      </p>
                    </div>
                    <div className="relative flex items-center mb-2">
                      <h5 className="text-sm font-bold text-slate-600">نهاية الاشتراك في : </h5>
                      <p className="mr-2 text-sm">
                        {
                        // singleExecution 
                        // && 
                        executionCourse.end_date_student
                        &&
                        executionCourse.end_date_student
                        }
                      </p>
                    </div>
                    <div className="relative flex items-center mb-2">
                      <h5 className="text-sm font-bold text-slate-600"> حالة الكورس : </h5>
                      <p className="mr-2 text-sm">
                        {
                        executionCourse && executionCourse.date_student_status
                        && executionCourse.date_student_status === "active"
                        ?
                        <span className="relative flex justify-center items-center py-2 px-3 rounded-md text-white bg-green-500">نشط</span>
                        :
                        <span className="relative flex justify-center items-center py-2 px-3 rounded-md text-white bg-red-500">غير نشط</span>
                        }
                      </p>
                    </div>
                    {
                      courseData && courseData.type && courseData.type === "offline"
                      &&
                      <Button 
                        className="relative btn btn-primary w-fit bg-primary transition-all duration-500 hover:translate-y-[-4px] mt-2 shadow-md hover:shadow-slate-400"
                        onClick={()=>{
                          courseSessions.length >= 1
                          ?
                          setShowCourseSessions(true)
                          :
                          Swal.fire({
                            title: "عذرا",
                            text: "لا يوجد حصص حضور اوفلاين لهذا الكورس",
                            icon: "warning"
                          });
                        }
                        }
                      >حصص الحضور</Button>
                    }
                  </div>
                  :
                  <div className="relative p-2 my-3 flex flex-col  justify-center items-center">
                    <img src={endIcon} alt="end icon" className="relative my-3 w-[40px]" />
                    <h2 className="relative fles items-center">تم انتهاء فتره الاشتراك للتجديد <Link className="relative text-primary ">اتصل بنا</Link></h2>
                  </div>
                )
              }
              <div className="note flex flex-col col-sm-12 col-md-6 bg-slate-50 shadow-md shadow-slate-300 rounded-md p-3">
                <p className="relative text-sm mb-1"> <span className="text-red-500">ملحوظه : </span> لا يمكنك اعاده الاختبار الا في حاله الرسوب</p>
                <p className="relative text-sm mb-1"> <span className="text-red-500">ملحوظه : </span> لا يمكنك دخول الامتحان الشامل الا في حاله النجاح في جميع الدروس السابقه</p>
                <p className="relative text-sm mb-1"> <span className="text-red-500">ملحوظه : </span> في حاله النجاح في الامتحان الشامل لا يمكنك الامتحان مره اخري </p>
              </div>
            </div>
          </div>
          <div className="content-page col-12">
            <div id="wp-content" className="wp-content">
              <div className="tutor-wrap sc-course tutor-page-wrap sc-layout-4 post-880 courses type-courses status-publish has-post-thumbnail hentry course-category-business post-single-content tutor-my-40">
                <div className="container-content mx-auto w-[90%] relative">
                  {subscriptionsCoursesLoading && showLessonsLoading ? (
                    <>
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12">
                          {
                            targetLesson && Object.keys(targetLesson).length >= 1
                            ?
                            <>
                              <h3 className="mb-2">{targetLesson.name}</h3>
                              {
                                // <div className="single-course-sidebar relative">
                                //   {checkViewLesson(targetLesson.type)}
                                // </div>
                                show
                              }
                          
                              <div className="relative my-2 flex flex-col">
                                <div className="relative">
                                  الوصف : 
                                  <div className="my-2" dangerouslySetInnerHTML={{__html : targetLesson.description}} />
                                </div>
                                <div className="relative flex items-centermb-1">
                                  نوع الدرس : 
                                  {checkLessonType(targetLesson.type)}
                                  <p className="mr-1">{targetLesson.type}</p>
                                </div>
                                <div className="relative flex items-centermb-1">
                                  مرات المشاهدة : 
                                  {
                                    targetLesson.view_count > 0 
                                    // &&
                                    // targetLesson.can_view_without_pay !== 1                                    
                                    ?
                                    <p className="mr-1 mb-0  flex items-center">
                                      {
                                      targetLesson.completed_students.length >= 1
                                      ?
                                      checkLessonViewCount(targetLesson.view_count , targetLesson.completed_students[0]["pivot"]["completion_count"])
                                      :
                                      checkLessonViewCount(targetLesson.view_count , 0)
                                      }
                                    </p>
                                    :
                                    <p className="mr-1 mb-0  flex items-center text-[#03a1ed]">
                                      {/* درس مجاني */}
                                      <img src={infinitImg} alt="infint icon" className="relative w-[20px] mr-1"/>
                                    </p>
                                  }
                                </div>
                                {
                                  showLessonsLoading
                                  ?
                                  <p>جاري التحميل..</p>
                                  :
                                  (
                                    targetLesson.type === "rich_text" && showLessonsData && Object.keys(singleLesson).length >= 1
                                    &&
                                    (
                                      singleLesson.contents
                                      ?
                                      <div className={`rich-text-content relative my-2 transition-all duration-500 overflow-y-auto ${showLessonContents ? "max-h-[500px]" : "max-h-0"}`}>
                                        محتوي الدرس : 
                                        <div className="" dangerouslySetInnerHTML={{__html : singleLesson.contents}} />
                                      </div>
                                      :
                                      <p>لا يوجد محتوي لهذا الدرس</p>
                                    )
                                  )
                                }
                              </div>
                              {
                              targetLesson && targetLesson.name 
                              && 
                              (
                                <>
                                  {/* <div className="course-single-action items-center tutor-my-40">
                                    {checkLessonResponse(targetLesson.type)}
                                  </div> */}
                                </>
                              )
                              }
                            </>
                            :
                            <div className="no-lesson-overlay h-[350px] bg-slate-50 top-0 left-0 w-full flex flex-col justify-center items-center relative p-3">
                            <img src={audioImg}  alt="audio img" className="relative mb-2 w-[100px]"/>
                            <p className="m-0 text-blue-500">قم باختيار درس للاستماع..</p>
                            </div>
                          }
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                          <div className="">
                            {
                              subscriptionsCoursesLoading
                              ?
                              <>
                                <div className="text-center py-1">
                                  <CustomLoaders />
                                  <CustomLoaders />
                                  <CustomLoaders />
                                </div>
                              </>
                              :
                              <div
                                className="accordion tutor-accordion tutor-mt-24"
                                id="accordionPanelsStayOpenExample"
                              >
                                {lessonCourse && lessonCourse.length > 0 && (
                                  <>
                                    <div className="custome-accordion relative w-full">
                                      <div className={`relative transition-all duration-300 flex justify-between items-center cursor-pointer px-3 py-3 rounded-[2px] bg-blue-500 shadow-md shadow-slate-400}`}
                                        onClick={()=>{
                                          setShowLessons(!showLessons)
                                        }}
                                      >
                                      
                                        <h4 className="m-0 text-white">محتوي الكورس
                                          ({lessonCourse && (lessonCourse.length)})
                                        </h4>
                                        <div className={`relative flex justify-center items-center rounded-[50%] w-[30px] h-[30px] ${showLessons ? "bg-[#ff6a59]" : "bg-[#ff6a59ad]"} transition-all duration-500`}>
                                          <img src={top} className={`relative w-[30px] transition-all duration-500 rounded-[50%] ${showLessons ? "[180deg]" : "rotate-[-180deg]"}`} alt="img" />
                                        </div>
                                      </div>
                                      <div className={`course-lessons-side relative transition-all duration-500 flex flex-col justify-start mt-4 overflow-y-auto ${showLessons ? "h-[550px]" : "h-0"}`}>
                                      {
                                        subscriptionsCoursesData &&lessonCourse.length > 0&&
                                        lessonCourse.map((lesson , index) => {
                                          // const {completion_count} = lesson.completed_students.pivot
                                            return (
                                              <div key={lesson.id} className={`lesson-box my-2 relative flex items-center justify-between py-3 px-2 rounded-[2px] border border-slate-400 hover:bg-blue-200 hover:border-blue-200 transition-all duration-300 overflow-hidden`}>
                                                <div className="name-and-play w-full relative flex items-center justify-between cursor-pointer"
                                                onClick={()=>{
                                                  // getLessonDataFromServer(index , lesson.id);
                                                  setTargetLesson(lesson)
                                                }}
                                                >
                                                    <div className="relative flex items-center">
                                                      {checkLessonType(lesson.type)}
                                                      <h5>{lesson.name}</h5>
                                                      {
                                                        lesson.view_count === 0
                                                        ?
                                                        <p className="relative mb-0 mr-2 text-sm text-blue-600">
                                                          <img src={infinitImg} alt="infint icon" className="relative w-[20px]"/>
                                                        </p>
                                                        :
                                                        (
                                                          lesson.view_count >= 1 &&
                                                          lesson.completed_students.length >= 1
                                                          ?
                                                          checkLessonViewCount(lesson.view_count , lesson.completed_students[0]["pivot"]["completion_count"])
                                                          :
                                                          checkLessonViewCount(lesson.view_count , 0)
                                                        )
                                                      }
                                                    </div>
                                                    <div className="relative flex items-center">
                                                    {
                                                      checkThereIsRequirments(lesson.requirements)
                                                    }
                                                    {
                                                      checkLockLesson(lesson.requirements)
                                                    }
                                                    {
                                                      lesson.type === "quiz"
                                                      &&
                                                      lesson.attempts && lesson.attempts.length >=1
                                                      &&
                                                      lesson.attempts[0] && lesson.attempts[0].status_passed !== "review"
                                                      &&
                                                      lesson.attempts[0] && lesson.attempts[0].status_passed !== "started"
                                                      &&
                                                      <Link to={`attemp-page/${lesson.attempts[0].id}`}>
                                                        <i className="fa-solid fa-lightbulb text-[#283593]"></i>
                                                      </Link>
                                                    }
                                                    </div>
                                                </div>
                                              </div>
                                            )
                                          })
                                      }
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              {
                                lessonCourse && lessonCourse.length < 1 &&
                                <h2>لا يوجد دروس لهذا الكورس!</h2>
                              }
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        courseData && courseData.type && courseData.type === "offline"
        &&
        <SessionsDropDown 
          show = {showCourseSessions}
          onHide = {() => setShowCourseSessions(false)}
          sessions = {courseSessions}
          coursename = {courseData && course.name}
        />
      }
      <ShowImageModal
        show = {showInstructoImg}
        onHide = {() => setShowInstructoImg(false)}
        image = {instructorImage}
      />
      <ShowLessonRequirments 
        show = {showLessonRequirments}
        onHide = {() => setShowLessonRequirments(false)}
        lessonRequirments = {requirmentsList}
      />
      <ShowRichTextContents 
        show = {showRichTextContents}
        onHide = {() => setShowRichTextContents(false)}
        title = {"عرض المحتوي الكتابي"}
        text = {richTextContent}
      />
      {/* {
        parseInt(downloadingFilePercent) >= 1 && parseInt(downloadingFilePercent) !== 100
        &&
        <DownloadingProgress
        progressCunt={downloadingFilePercent}
        // compleatingFunc={() => postCompletingLesson(targetLesson.id)}
        />
      } */}
      {
        parseInt(downloadingFilePercent) >= 1 && parseInt(downloadingFilePercent) !== 100
        &&
        <div className="fixed transition-all duration-300 top-0 h-[100vh] left-0 w-full z-[99999999] bg-[#263676] flex flex-col justify-center items-center">
          <div className="relative w-[350px] overflow-hidden h-[40px] rounded-md bg-[#cbd5e182]">
              <span className={`absolute flex justify-center items-center bg-[#0f60a3] text-stone-50 transition-all duration-500 top-0 left-0 h-full`} style={{ width: `${downloadingFilePercent}%` }}>
                  {
                      parseInt(downloadingFilePercent) >= 1
                      &&
                      `${parseInt(downloadingFilePercent)}%`
                  }
              </span>
          </div>
          <p className="text-stone-50 mt-2">جاري التنزيل..</p>
      </div>
      }
      {/* <OverLayLoading loading={downloadFileLoading} loadingtext={"جاري التنزيل.."} /> */}
    </>
  );
}
