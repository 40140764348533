import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function ScrollUp() {
  
  const getUp = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const isScroll = () => {
    return window.scrollY;
  };
  const [windowScrollY, setWindowScrollY] = useState(isScroll());
  var style = {}
  useEffect(() => {
    const resizeFun = () => {
      setWindowScrollY(isScroll());
    };
    window.addEventListener("scroll", resizeFun);
    return () => window.removeEventListener("scroll", resizeFun);
  }, [windowScrollY]);

  return (
    <>
      <Link className="scroll-to-target scroll-to-top" to={"#"} 
      style={{display : windowScrollY > 100 ? "block" : "none"}}

       onClick={getUp}>
        <i className="fa fa-angle-up" />
      </Link>
    </>
  );
}
