import { useEffect, useState } from "react";
import timerImg from "../../../../images/timerImg.png"
import "./quize-timer.css";
function QuizeTimer({timeWithMunites , getQuizeLoading , exitQuize , manuallySubmition}){
  const endTimeFromLocalStorage = localStorage.getItem('quizEndTime');
  const [endTime, setEndTime] = useState(endTimeFromLocalStorage || null);
  const [mounted, setMounted] = useState();
  const [quizeEnd, setQuizeEnd] = useState();
  const [endQuizeText, setEndQuizeText] = useState("");
  const [minutes, setMinutes] = useState();
  const [seconds, setSeconds] = useState();
  const exit = () => {
    exitQuize()
    console.log("done quize")
  }

  // old timer code 
  // useEffect(() => {
  //   if (!endTime) {
  //     const currentTimestamp = Date.now();
  //     const quizEndTime = currentTimestamp + timeWithMunites * 60 * 1000; // Convert minutes to milliseconds
  //     setEndTime(quizEndTime);

  //     // Save quiz end time to localStorage
  //     timeWithMunites && localStorage.setItem('quizEndTime', quizEndTime.toString());
  //   }
  //       const intervalId = setInterval(() => {
  //         const currentTime = Date.now();
  //         const remainingTime = endTime - currentTime;
  //         if (remainingTime <= 30000) {
  //           clearInterval(intervalId);
  //           // Handle quiz completion
  //           setQuizeEnd(true);
  //           exit();
  //           window.localStorage.removeItem("quizEndTime");
  //           window.localStorage.removeItem("quizeTimer");
  //           clearInterval(intervalId);
  //         }
  //         // You can calculate and display minutes, seconds, etc.
  //         const minutes = Math.floor(remainingTime / (60 * 1000));
  //         const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
  //         setMinutes(minutes)
  //         setSeconds(seconds)
  //         window.localStorage.removeItem("endTimeFromLocalStorage")
        
  //       // manuallySubmition && clearInterval(intervalId)
  //       return () => clearInterval(intervalId);
  //   },2000)
  // }, [endTime, timeWithMunites]);


  useEffect(() => {
    if (!endTime) {
      const currentTimestamp = Date.now();
      const quizEndTime = currentTimestamp + timeWithMunites * 60 * 1000; // Convert minutes to milliseconds
      setEndTime(quizEndTime);
      // Save quiz end time to localStorage
      timeWithMunites && localStorage.setItem('quizEndTime', quizEndTime.toString());
    }
    
    const intervalId = setInterval(() => {
      const currentTime = Date.now();
      const remainingTime = endTime - currentTime;
      if (remainingTime <= 40000) {
        // Handle quiz completion
        setQuizeEnd(true);
        exit();
        window.localStorage.removeItem("quizEndTime");
        window.localStorage.removeItem("quizeTimer");
        clearInterval(intervalId); // Clear the interval here, not inside the if block
      }
      
      // You can calculate and display minutes, seconds, etc.
      const minutes = Math.floor(remainingTime / (60 * 1000));
      const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);
      setMinutes(minutes)
      setSeconds(seconds)
      window.localStorage.removeItem("endTimeFromLocalStorage")
    }, 2000);

    return () => clearInterval(intervalId); // Clear the interval on component unmount
}, [endTime, timeWithMunites]);







    useEffect(() => {
        !getQuizeLoading ? setTimeout(()=>setMounted(true) , 200) : setMounted(false)
    },[getQuizeLoading]);

    return (
        <> 
        {
            endTime && timeWithMunites
            &&
            <div className={`quize-timer-div fixed top-[50px] right-0 p-3 bg-[#066e9a] z-[99999999] text-white flex justify-center items-center ${mounted ? "active" : ""} rounded-bl-md rounded-tl-md`}>
                {
                    quizeEnd
                    ?
                    <h6 className="m-0">انتهي وقت الاختبار</h6>
                    :
                    <>
                        <img loading="lazy" src={timerImg} alt="timer img" className="relative w-[30px] mr-2" />
                        <h6 className={`m-0 flex mr-2 ${minutes < 1 ? "text-red-500" : ""}`}>
                            {minutes} : {seconds < 10 ? `0 ${seconds}` : seconds}
                        </h6>
                        <h6 className="ml-2">ينتهي الاختبار بعد </h6>
                    </>
                }
            </div>
        }

        </>
    )
}

export default QuizeTimer;