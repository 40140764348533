import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const postStudentAnswersFunc = createAsyncThunk(
    "post student answers func", 
    async ({url , answers}) => {
    try {
        const response = await axios.post(url,answers , {
        withCredentials: true,
        });
        // if(response.data.success === false && response.data.message) {
        //   Swal.fire({
        //     title: "عذرا",
        //     text: response.data.message,
        //     icon: "warning",
        //     showCancelButton: true,
        //     confirmButtonText: "تم"
        //   })
        // }
        // else {
        // }
        checkErrorsFunctions(response , response.data.errors);
        return response.data;
    } catch (error) {
        return error;
    }
});

const postStudentAnswersSlicer = createSlice({
  name: "post student answers slicer",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(postStudentAnswersFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(postStudentAnswersFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(postStudentAnswersFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default postStudentAnswersSlicer.reducer;
