import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { resetPassFunc } from "../../store/slices/users/ResetPasswordSlicer";
import { Link, useNavigate } from "react-router-dom";
import img from "../../images/graphic3.svg"
import ContextApi from "../../store/slices/Context";
import logo from "../../images/academy-logo.png"
import "./reset-pass-page.css"

export default function ResetPassPage() {
    const {register , handleSubmit , reset , formState : {errors}} = useForm();
    const [showPass , setShowPass] = useState(false)
    const [showConfirmPass , setShowConfirmPass] = useState(false);
    const dispatch = useDispatch();
    const resetPassApi = `${process.env.REACT_APP_PUBLIC_API}/api/reset-password`;
    const [resetPassToken , setResetPassToken] = useState("");
    const {loading:resetPassLoading} = useSelector(
        state => state.resetPassSlicer
    )
    const contextApi = useContext(ContextApi)
    const platformLogo = contextApi && contextApi.generalSetting && contextApi.generalSetting.platform_logo_url

    const navigate = useNavigate();
    const handleSubmitData = async (data) => {
        try {
            dispatch(resetPassFunc({api : resetPassApi , data : {...data , token : resetPassToken}}))
            .then((result) => {
                if(result.payload.success === true) {
                    reset();
                    navigate("/login");
                }
            })
        }
        catch (error) {
            Swal.fire({
                title: "عذرا!",
                text: error,
                icon: "warning"
            });
        }
    }

    useEffect(() => {
        const maniURL = window.location.href;
        const regResetPassToken = /token=([^&]+)/;
        // const regResetPassToken = /token=.*/;
        const match = maniURL.match(regResetPassToken);
        if (match) {
            setResetPassToken(match[1]);
        }
        if(maniURL.includes("token")) {
          console.log("has reset token");
        }else {
          console.log("no token");
        }
      },[])
    return (
        <>
            <div className="page-content relative w-full h-[100vh] bg-slate-950"
                
            >
                <img src={img} alt="img" className="absolute top-0 left-0 w-full h-full" />
                <div className="page-container row relative w-[90%] flex justify-center items-center h-full mx-auto py-4 ">
                    <div className="form-div relative flex flex-col justify-center items-center w-full md:w-[400px] rounded-lg py-3 bg-slate-900">
                        <i className="fa-solid fa-arrow-left absolute top-[15px] left-[15px] text-stone-50 cursor-pointer transition-all duration-500 hover:translate-x-[-4px]"
                            onClick={() => window.history.back()}
                        ></i>
                        <Link to="/">
                            <img src={platformLogo ? platformLogo : logo} alt="logo" className="w-[150px]" />
                        </Link>
                        <form 
                            className="relative col-sm-12 reset-form flex flex-col justify-center items-center"
                            id="reset-pass-form"
                            onSubmit={handleSubmit((data) => {
                                handleSubmitData(data)
                            })}
                        >
                            <div className="form-groub relative mb-1 col-sm-12">
                                <label className="mb-2">البريد الالكتروني</label>
                                {errors.email?.message &&
                                <p className="text-red-600">{errors.email?.message}</p>
                                }
                                <input
                                    {...register("email" , {required : "البريد الالكتروني مطلوب!"})}
                                    className="w-full col-sm-12 bg-transparent border"
                                    type="email"
                                />
                            </div>
                            <div className="form-groub relative mb-1 w-full">
                                <label className="mb-2">كلمة المرور</label>
                                {errors.name?.message &&
                                <p className="text-red-600">{errors.name?.message}</p>
                                }
                                <div className="relative flex flex-col col-sm-12">
                                    <input
                                        {...register("password" , {required : "كلمه المرور مطلوبة"})}
                                        className="w-full col-sm-12 bg-transparent border placeholder:opacity-40"
                                        type={`${showPass ? "text" : "password"}`}
                                        placeholder="**********"
                                    />
                                    <i class="fa-solid fa-eye cursor-pointer absolute top-[50%] translate-y-[-50%] left-[10px]"
                                    onClick={() => setShowPass(!showPass)}
                                    ></i>
                                </div>
                            </div>
                            <div className="form-groub relative mb-1 w-full">
                                <label className="mb-2">تاكيد كلمة المرور</label>
                                {errors.password_confirmation?.message &&
                                <p className="text-red-600">{errors.password_confirmation?.message}</p>
                                }
                                <div className="relative flex flex-col col-sm-12">
                                    <input
                                        {...register("password_confirmation" , {required : "يجب تاكيد كلمة المرور"})}
                                        className="w-full col-sm-12 bg-transparent border  placeholder:opacity-40"
                                        type={`${showConfirmPass ? "text" : "password"}`}
                                        placeholder="**********"
                                    />
                                    <i class="fa-solid fa-eye cursor-pointer absolute top-[50%] translate-y-[-50%] left-[10px]"
                                    onClick={() => setShowConfirmPass(!showConfirmPass)}
                                    ></i>
                                </div>
                            </div>
                            <button
                                className={`relative sub-btn mt-4 bg-blue-900 w-full md:w-fit py-2 px-3 flex justify-center items-center text-white transition-all duration-500 hover:rounded-md hover:translate-y-[-4px] ${resetPassLoading ? "pointer-events-none opacity-70" : ""}`}
                            >
                                {
                                    resetPassLoading
                                    ?
                                    "جاري التحديث.."
                                    :
                                    <>
                                    تحديث
                                    <i className="fa-solid fa-pen-to-square mr-1"></i>
                                    </>
                                }
                            </button>

                        </form>
                    </div>
                </div>
            </div>
        
        </>
    )
}