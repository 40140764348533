import { Link, useNavigate } from "react-router-dom";
import  teacherImg from "../../../images/presentation.png";
import  onlineIcon from "../../../images/audiobook.png";
import  offlineIcon from "../../../images/offline.png";
import  discountIcon from "../../../images/discounts.png";
import "./course-card.css"
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import OverLayLoading from "../../global/overlay-loading/OverLayLoading";
import { addToCartFunc } from "../../../store/slices/cart-actions/AddToCartSlicer";
import ActivationCodeModal from "../../global/global-modals/ActivationCodeModal";
import { getCartFunc } from "../../../store/slices/cart-actions/GetCartSlicer";

export default function CardCourses({executionsArray , execution ,  courseDiscount ,teacher,title,excutionId,price,lessons,level , courseImg , courseInstructor , typeCourse , courseStatus , categoryName}) {
  const [onHover , setOnHover] = useState(false);
  const navigate = useNavigate()
  const {loading:addTocartLoading} = useSelector(
    state => state.addToCartSlicer
  )
  const [showActivationCourse , setShowActivationCourse] = useState();
  const dispatch = useDispatch();
  const cartApi = `${process.env.REACT_APP_PUBLIC_API}/api/carts`;
    const [haveThisCourse , setHaveThisCourse] = useState();
    const addTocart = () => {
      try {
          dispatch(addToCartFunc({api : cartApi , data : {course_execution_id : excutionId}}))
          .then(result => {
            if(result.payload.success === true) {
              dispatch(getCartFunc(cartApi));
              navigate("/cart")
            }
          })
      }
      catch(error) {
          Swal.fire({
              title: "عذرا!",
              text: error,
              icon: "warning"
          });
      }
    }
    const additionFunction = () => {
      Swal.fire({
        title: "تاكيد",
        text: "تاكيد تضمين الكورس الي عربة التسوق!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "الغاء",
        confirmButtonText: "اضافة!"
      }).then((result) => {
        if (result.isConfirmed) {
          addTocart();
        }
      });
      
    }

    useEffect(() => {
      if(executionsArray) {
        const find = executionsArray.find(ex => ex.course_id === execution.course_id);
        find ? setHaveThisCourse(true) : setHaveThisCourse(false);
      }
    },[executionsArray , execution])

  return (
    <>
      <div className={`course-card hover:shadow-lg hover:shadow-slate-400 hover:translate-y-[-5px] transition-all duration-500 bg-white relative w-full shadow-md shadow-slate-300 sm:w-[270px] flex flex-col justify-center rounded-md overflow-hidden p-0`}
      
      onMouseOver={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      
      >
        {
          courseDiscount
          ?
          <div className="absolute top-[10px] left-[10px] z-[99]">
            <img loading="lazy" src={discountIcon} alt="icon" className="w-[30px] rotate-[-50deg]" />
          </div>
          :
          ""
        }
        <div className="course-image relative w-full h-[200px] overflow-hidden rounded-md">
          <img loading="lazy"
            src={`${courseImg !== null ? courseImg : "https://img.freepik.com/free-vector/students-watching-webinar-computer-studying-online_74855-15522.jpg?w=826&t=st=1702899442~exp=1702900042~hmac=f3c89975f0344fd66d9bfba0158a777d5e50d509a0b753d71c809ad690ab30f3"}`}
            alt="course img"
            className="absolute top-0 left-0 w-full h-full transition-all duration-500"
          />
          <div className={`absolute overlay-div top-0 left-0 w-full h-full bg-[#0000003d] flex flex-col justify-center p-2 ${onHover ? "opacity-100" :"opacity-0"} transition-all duration-500`}>
            <button className={`relative mb-2 cursor-pointer transition-all duration-200 text-white rounded-[50%] p-2 w-fit ${onHover ? "translate-x-0 opacity-100" : "translate-x-[20px] opacity-0" } text-[15px] bg-[#3948abd7] hover:bg-[#3948ab]`}
              onClick={() => {
                additionFunction()
              }}
            >
              <i className="fa-solid fa-cart-arrow-down"></i>
            </button>
            <button className={`relative mb-2 cursor-pointer transition-all duration-400 text-white rounded-[50%] p-2 w-fit ${onHover ? "translate-x-0 opacity-100" : "translate-x-[20px] opacity-0" } text-[15px] bg-[#3948abd7] hover:bg-[#3948ab]`}
              onClick={() => {
                setShowActivationCourse(true);
              }}
            >
              <i className="fa-solid fa-code-compare"></i>
            </button>
            
          </div>
        </div>
        <div className="courses-one__single-content m-0">
          <div className="courses-one__single-content-overlay-img ">
            <img loading="lazy" src={courseInstructor && courseInstructor.image_url !== null ? courseInstructor.image_url : teacherImg} alt="teacher img" className="w-[40px]" />
          </div>
          <h6 className="courses-one__single-content-name">{teacher}</h6>
          <div className="relative flex justify-between items-start pt-1">
            <div className="relative flex flex-col">
              <h4 className="courses-one__single-content-title leading-none text-slate-600">
                {title}
              </h4>
              <p className="m-0 text-sm my-1">
                المادة : {categoryName}
              </p>
            </div>
            {
              typeCourse === "online"
              ?
              <div className="relative flex items-center text-sm">
                اونلاين
                <img loading="lazy" src={onlineIcon} alt="online img" className="w-[20px] mr-2" />
              </div>
              :
              <div className="relative flex items-center text-sm">
                اوفلاين
                <img loading="lazy" src={offlineIcon} alt="online img" className="w-[20px] mr-2" />
              </div>
            }
          </div>
          {
            courseDiscount
            ?
            <div className="relative flex flex-col">
              <p className="relative text-sm text-red-500">خصم <span className="">{courseDiscount} جنيه</span></p>
              <p className="relative text-sm">
                السعر : 
                <span className="font-bold">{parseInt(price) - parseInt(courseDiscount)} جنيه</span>
                <del className="text-red-500 mr-2">{price} جنيه</del>
              </p>
            </div>
            :
            <p className="courses-one__single-content-price py-2 text-slate-600">
              السعر : {price} جنيها
            </p>
          }
          {
            haveThisCourse
            ?
            <Link className="absolute top-0 left-0 w-full h-full"
              to = {`/lessons/${excutionId}`}
            ></Link>
            :
            <Link className="absolute top-0 left-0 w-full h-full"
              to={`/courses/${excutionId}`}
            ></Link>
          }
        </div>
      </div>
      <OverLayLoading loading={addTocartLoading} loadingtext={"جاري الاضافة للعربة"}/>
      <ActivationCodeModal
        show = {showActivationCourse}
        onHide = {() => setShowActivationCourse(false)}
        course_execution_id = {excutionId}
      />
    </>
  );
}





