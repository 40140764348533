import { Link } from "react-router-dom"
import defaultImg from "../../../../images/presentation.png"
export default function CardTeachers({name,title,description,imgurl , coursesCount , email , phone}) {
    // return <>
    // <div>
    //      <div className="p-0">
    //         <img loading="lazy" src={imgurl !== null ? imgurl : defaultImg} alt={title} />
    //       </div>
    //       <div className="meet-teachers-one__single-content">
    //         <div className="meet-teachers-one__single-middle-content">
    //           <div className="title">
    //             <div className="relative flex flex-col">
    //             <h4>{name}</h4>
    //             <span className="text-blue-500 transition-all duration-300 hover:tracking-[3px]">{email}</span>
    //             </div>
    //             <div className="contat-div relative flex justify-center my-2">
    //               <div className="contact-box flex items-center justify-end">
    //                 <Link className=" mx-1" to={`https://wa.me/+2${phone}`} target="_blanck">
    //                   whatsapp
    //                   <i class="fa-brands fa-whatsapp mr-1"></i>
    //                 </Link>
    //               </div>
    //             </div>
    //           </div>
    //           <p className="meet-teachers-one__single-content-text">{description}</p>
    //         </div>
    //         <div className="meet-teachers-one__single-bottom-content">
    //           <div className="flex justify-between items-center bg-slate-100 rounded-md p-2">
    //             <div className="text">
    //               <p>({coursesCount}) عدد الكورسات </p>
    //             </div>
    //             <div className="social-icon">
    //               <ul className="list-unstyled flex">
    //                 <li className="relative mr-1 transition-all duration-300 hover:translate-y-[-3px]"><Link target="_blanck" to="https://www.twitter.com"><i className="fab fa-twitter" /></Link></li>
    //                 <li className="relative mr-1 transition-all duration-300 hover:translate-y-[-3px]"><Link target="_blanck" to="https://www.facebook.com"><i className="fab fa-facebook" /></Link></li>
    //                 <li className="relative mr-1 transition-all duration-300 hover:translate-y-[-3px]"><Link target="_blanck" to="https://www.instagram.com"><i className="fab fa-instagram" /></Link></li>
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    // </div>
    // </>
    return (
      <>
      
        <div className=" relative w-full  md:w-[280px] flex flex-col mx-2 shadow-md shadow-slate-200 px-2 py-3 rounded-md transition-all duration-500 hover:translate-y-[-5px]">
          <div className="card-content relative">
            <div className="teacher-img relative w-full h-[200px] overflow-hidden">
              <img loading="lazy" 
                src={imgurl !== null ? imgurl : defaultImg}
                className="absolute top-0 left-0 w-full h-full" 
                alt="teacher-img"
                />
            </div>
            <div className="info mt-3">
              <div className="name flex items-center text-sm mb-1">
                <i class="fa-solid fa-user ml-2"></i>
                <p>{name}</p>
              </div>
              <div className="courses-count flex items-center text-sm mb-1">
                <i class="fa-brands fa-discourse ml-1"></i>
                <p>عدد الكورسات</p>
                <span className="mx-1">:</span>
                <p>({coursesCount})</p>
              </div>
              <div className="phone flex items-center text-sm mb-1">
                <Link className="flex items-center" to={`https://wa.me/+2${phone}`} target="_blanck">
                  <i class="fa-brands fa-whatsapp ml-1"></i>
                  <p>{phone}</p>
                </Link>
              </div>
              <div className="email flex items-center text-sm mb-1">
                <i class="fa-solid fa-envelope ml-2"></i>
                <p>{email}</p>
              </div>
            </div>
          </div>
        </div>
      
      </>
    )
};
