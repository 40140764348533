import CardFeatures from "./ui/CardFeatures";

export default function Features() {
  return (
    <>
      <section className="features-one ">
        <div className="container">
          <div className="row">
            <CardFeatures
              delay={"0ms"}
              duration={"1500ms"}
              icon={<i class="fa fa-umbrella p-3 text-[30px] border border-blue-400 rounded-md text-blue-600"></i>}
              title={"محتوى تعليمي شامل"}
              description={"استكشاف مواد تعليمية عالية الجودة تشمل الدروس النصية والفيديوهات والتمارين التفاعلية"}
            />
            <CardFeatures
              delay={"200ms"}
              duration={"1500ms"}
              icon={<i class="fa-solid fa-mug-hot p-3 text-[30px] border border-red-400 rounded-md  text-emerald-600"></i>}
              title={" تجربة تعلم متميزة "}
              description={"الاستفادة من بيئة تعلم داعمة تشجع على التفاعل والتعاون بين الطلاب والمدرسين"}
            />
            <CardFeatures
              delay={"400ms"}
              duration={"1500ms"}
              icon={<i class="fa-solid fa-headphones p-3 text-[30px] border border-blue-400 rounded-md text-blue-800"></i>}
              title={"مسارات تعلم متنوعة"}
              description={"إمكانية إعداد خطط دراسية شخصية تتناسب مع احتياجات كل طالب وتسهم في تحقيق أهدافه التعليمية."}
            />
          </div>
        </div>
      </section>
    </>
  );
}
