import goldMedal from "../../../../images/medal.png";
import silverMedal from "../../../../images/silver-medal.png";
export default function StudentOpinionCard({studenImg , studentName , studentCourse , studentSay , studentRateCount , studentRank , studentScore}) {
    function rate(star , emtyStar , rating) {
        let rateCount = 5-rating;
        let stars = [];
        let emptyStars = []
        for(let i=0; i<rating; i++) {
            stars.push(<div key={`${i}`}>{star}</div>)
        }
        for(let i=0; i<rateCount; i++) {
            emptyStars.push(<div key={`${i}`}>{emtyStar}</div>)
        }
        return(
            <div className="flex">
                {stars}{emptyStars}
            </div>
        )
    }
    const checkRank = (rank) => {
        let medal;
        switch (rank) {
            case 1:
                medal = <img loading="lazy" src={goldMedal} alt="rank medal" className="w-[30px]"/>;
                break;
            case 2:
                medal = <img loading="lazy" src={silverMedal} alt="rank medal" className="w-[30px]"/>;
                break;
            default:
                console.log("no rank")
        }
        return medal
    }
    return (
        <>
            <div className="student-card flex flex-col w-[340px] m-2 rounded-md shadow-md shadow-slate-300 p-3 transition-all duration-500 hover:translate-y-[-5px]">
                <div className="card-header flex my-2">
                    <div className="student-img relative rounded-full-overflow-hidden w-[100px] h-[100px]">
                        <img loading="lazy" src={studenImg} alt="student-img" />
                    </div>
                    <div className="name-and-course flex flex-col mx-2">
                        <h2 className="mb-2 font-bold tracking-wide text-neutral-600">{studentName}</h2>
                        <h2>{studentCourse}</h2>
                    </div>
                    <img loading="lazy" src="https://asset.uibucket.net/html/ilearning/assets/images/testimonial/quote.svg" alt="icon-img" className="w-[80px]" />
                </div>
                <div className="relative flex justify-between">
                    <div className="student-opinion flex py-3 flex-col">
                        <div className="flex items-center mb-1">
                            <i class="fa-solid fa-envelope ml-1 text-[15px]"></i>
                            <p className="text-xl text-slate-600 m-0 text-[15px]">{studentSay}</p>
                        </div>
                        {/* <div className="flex items-center mb-1">
                            <i class="fa-solid fa-ranking-star ml-1 text-orange-400"></i>
                            <p className="text-xl text-slate-600 m-0">{studentScore} %</p>
                        </div> */}
                    </div>
                    <div className="relative rank-medal">
                        {
                            studentScore > 0
                            &&
                            checkRank(studentRank)
                        }
                    </div>
                </div>
                <div className="rating-div flex justify-between items-center p-2 border-t border-slate-400">
                  الترتيب : 
                  {studentRank}
                </div>
            </div>
        
        </>
    )
}