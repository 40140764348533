import whyUsImage from "../../../images/close-up-hands-holding-diplomas-caps.webp"
export default function Whyus() {
  const whyUsList = [
    {content : "مرحبًا بك في منصتنا التعليمية المميزة! نقدم لك تجربة تعلم فريدة ومثيرة تضمن لك الوصول إلى محتوى تعليمي متميز وشامل في مختلف المجالات الأكاديمية. من خلال منصتنا، ستجد كل ما تحتاجه لتطوير مهاراتك ومعرفتك بسهولة ويسر."},
    {content : "ميزاتنا تشمل مجموعة واسعة من الدروس والدورات التعليمية المصممة بعناية لتلبية احتياجاتك التعليمية المختلفة. سواء كنت تبحث عن دورات في التطوير الشخصي، أو اللغات، أو التكنولوجيا، فإننا نوفر لك الموارد التعليمية التي تحتاجها."},
    {content : "توفير مسارات تعلم متنوعة تتيح للطلاب اختيار الدروس والمواد التي تناسب اهتماماتهم وأهدافهم التعليمية."},
    {content : "توفير موارد تعليمية إضافية مثل المقالات العلمية والكتب الإلكترونية والمواد التعليمية التفاعلية لتعزيز تجربة التعلم للطلاب."}
  ]
    return <>
        <section className="why-choose-one">
        <div className="container-why row relative mx-auto w-[90%]">
          <div className="why-us-text col-sm-12 col-md-6">
            <div className="text-content col-sm-12 flex flex-col">
              <h2 className="mb-3 font-bold sm:text-[20px] md:text-[40px] text-slate-700">لماذا نحن؟ اكتشف ميزات منصتنا التعليمية</h2>
              {
                whyUsList
                &&
                whyUsList.map((content , index) => {
                  return (
                    <p key={index} className="mb-1 transition-all duration-500 hover:text-cyan-900 hover:translate-x-[-5px]">{content.content}</p>
                  )
                })
              }
            </div>
          </div>
          <div className="why-us-images col-sm-12 col-md-6">
              <div className="images-div relative flex col-sm-10 h-[500px] overflow-hidden">
                <img src={whyUsImage} alt="img" className="absolute top-0 left-0 w-full h-full transition-all duration-500 hover:scale-110" />
              </div>
          </div>
        </div>
      </section>
    </>
};
